.payment-title {
    color: white;
    font-size: 20px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 500;
}

.widthIssue {
    width: 70%;
}

.payment-form {
    border-radius: 10px;
    background: #535151 !important;
    color: white !important;
    outline: none;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    height: 3rem;

}

.payment-form::placeholder {
    color: #C8C8C8;
}

.accountBox {
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%);
    backdrop-filter: blur(2px);
    width: 100%;
    height: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    margin-bottom: 20px;
}



@media only screen and (max-width: 650px) {
    .widthIssue {
        width: 100%;
    }

    .payment-title {
        font-size: 16px;
    }

    .accountBox p {
        font-size: x-small;
    }

}
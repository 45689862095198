.box-profile {
    width: 100%;
    height: 150px;

    background: #535151;
    border-radius: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-profile {
    background: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%);
    border-radius: 10px;
    height: 44px;
    width: 150px;
    border-radius: 10px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

    color: white;

}

.btn-profile:hover {
    background: linear-gradient(93.19deg, #0cc093 0.77%, #374ddd 100%);
}
.btn-profile:disabled {
    background: linear-gradient(93.19deg, #07b589 0.77%, #2237c1 100%);
    cursor: not-allowed;
}

@media only screen and (max-width: 840px) {
    .box-profile div img {
        width: 40px;
    }

    .box-profile div div h5 {
        font-size: small;
    }

    .box-profile div div p {
        font-size: small;
    }

    .box-profile div p {
        font-size: xx-small;
    }

    .btn-profile {

        height: auto;
        width: auto;
        padding: 10px;

        font-size: 14px;

    }
}
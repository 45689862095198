.google-box {
    width: 100%;
    height: auto;
    background: #3A3A3C;
}

.handleTextScan {
    color: #C8C8C8;
    text-align: center;
    font-size: small;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 600;
}

.or-text {
    color: #AFFF46;
    font-size: 18px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 600;
}

.btn-google {
    border-radius: 12px;
    background: linear-gradient(127deg, #15E3BB 0%, #4377EE 100%);
    height: auto;
    width: auto;
    border-radius: 10px;
    border: none;
    color: #FFF;
    /* font-size: 20px; */
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 600;

}

.btn-google:hover {
    background: linear-gradient(93.19deg, #0cc093 0.77%, #374ddd 100%);
}

.otp-google-box {
    box-sizing: border-box;
    border-radius: 20px;
    width: 450px;
    height: auto;
    padding: 15px 25px;
}

@media only screen and (max-width: 500px) {
    .otp-google-box {
        width: fit-content;
    }
}
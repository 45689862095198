body {
  margin: 0 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
  /* background-color: #282525 !important; */
}

body::-webkit-scrollbar {
  display: none !important;
}


/* .css-kqt3k2-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.css-1glnoeq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid white !important;
}

.css-193abh0-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid white !important;
} */


/* .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.css-4e40em-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid white !important;
} */

/* .css-4e40em-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid transparent !important;
  border-image-slice: 1 !important;
  border-image-source: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%) !important;
  -webkit-border-image-source: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%) !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
}

/* .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
} */

.css-19kzrtu {
  padding: 20px 0px !important;
}

.bg-default {
  background: #282525;
}

.bg-particular {
  background-image: url(/public/assets/homeLayer.png);
  background-repeat: no-repeat no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  /* background-blend-mode: color-dodge; */
  /* background-blend-mode: exclusion; */
  background-color: #282525;
  height: auto;
}

p {
  margin-bottom: 0 !important;
}

.text-red {
  color: #FF0141;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
}

.text-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 70px;
  text-align: center;
  color: #FFFFFF;
  /* letter-spacing: 2px; */
}
.cursor-pointer {
  cursor: pointer !important;
}
@media only screen and (max-width: 550px) {
  .text-title {
    font-size: 25px;
    letter-spacing: 0px;
  }
}
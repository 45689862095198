.light-new {
  --mainTextColor: black;
}

.dark-new {
  --mainTextColor: white;
}

.capitalizeText {
  text-transform: capitalize;
}

.footer-section a {
  color: white;
}

.curPointer {
  cursor: pointer;
}

.error {
  color: rgb(255, 75, 94) !important;
  font-size: 15px !important;
}

.colorActive {
  color: #02b3b4 !important;
}
.color-green-futures {
  color: #10EDB6 !important;
}

.color-red-futures {
  color:  rgb(255, 75, 94) !important;
}
.color-green {
  color: #02b3b4 !important;
}

.color-red {
  color: rgb(255, 75, 94) !important;
}

.color-darkpink {
  color: rgb(252, 62, 81) !important;
}

.color-yellow {
  color: rgb(201, 148, 0);
}

img.smallSize {
  height: 25px;
  width: 25px;
}

.bid_bar_imp {
  background-color: #3491072e !important;
}

.ready-text-1 {
  color: #fff;
}

.ready-text-2 {
  color: #02b3b4;
}

.useUpdImg {
  width: 150px;
}

button {
  color: var(--mainTextColor);
}

.color-blue {
  color: rgb(76 101 229);
}

.custom-letterAvatar {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Colors */
  background-color: #02b3b4;
  /* color: #fff; */

  /* Rounded border */
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
}

.custom-letterAvatar-1 {
  position: relative;
  /* display: flex; */
  /* -webkit-box-align: center; */
  /* align-items: center; */
  /* -webkit-box-pack: center; */
  /* justify-content: center; */
  /* flex-shrink: 0; */
  width: 40px;
  height: 40px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
  font-size: 1.25rem;
  line-height: 2;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  /* color: rgb(20, 22, 26); */
  background-color: #02b3b4;
}
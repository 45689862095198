.head-bg {
    background: #ACA6A6 !important;
}

.gapIssue {
    gap: 5px;
}

.nav-link {
    color: #DADADA !important;
    font-size: 0.875rem !important;
    transition: color 0.02s ease-in;
}

.nav-link:hover {
    color: white !important;
}

.nav-link:active {
    color: white !important;
}

.nav-link:focus {
    color: white !important;
}

.nav-link.disabled {
    color: #6c757d !important;
    pointer-events: all !important;
    cursor: not-allowed !important;
}

.darkNavCss .nav-link {
    color: #DADADA !important;
    font-size: 0.938rem !important;
    font-weight: 600;
    transition: color 0.02s ease-in;
}

.darkNavCss .nav-link:hover {
    color: white !important;
}

.darkNavCss .nav-link:active {
    color: white !important;
}

.darkNavCss .nav-link:focus {
    color: white !important;
}

.darkNavCss .nav-link.disabled {
    color: #6c757d !important;
    pointer-events: all !important;
    cursor: not-allowed !important;
}

.homeNavCss .nav-link {
    color: black !important;
    font-size: 0.938rem !important;
    font-weight: 600;
    transition: color 0.02s ease-in;
}

.homeNavCss .nav-link:hover {
    color: #21CA50 !important;
}

.homeNavCss .nav-link:active {
    color: black !important;
}

.homeNavCss .nav-link:focus {
    color: black !important;
}

.homeNavCss .nav-link.disabled {
    color: #6c757d !important;
    pointer-events: all !important;
    cursor: not-allowed !important;
}



.navbar-toggler {
    background-color: white !important;
}


.dropdown-item.disabled,
.dropdown-item:disabled {
    color: rgb(170, 170, 170) !important
}

.bootstrap-custom-dropdown-header div {
    background: #535151 !important;
    border-radius: 6px;
    color: white !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 0px;
    width: fit-content;
}

.bootstrap-custom-dropdown-header div a {

    /* background-image: url(/public/assets/spot.png); */
    background-position: left center;
    background-repeat: no-repeat;
    color: white;
    padding-left: 25px;
    font-size: 14px !important;
    font-weight:600;
    width: fit-content;
}

.bootstrap-custom-dropdown-header div a:hover {
    /* background-image: url(/public/assets/spot.png); */
    background-position: left center;
    background-repeat: no-repeat;
    background-color: transparent !important;
    color: #7eb6ff !important;
}

.bootstrap-custom-dropdown-header div a:active {

    background-color: transparent !important;
    color: #7eb6ff !important;
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
}

.bootstrap-custom-dropdown-header div a:first-child {

    background-image: url(/public/assets/spot.png);
}

.bootstrap-custom-dropdown-header div a:hover:first-child {
    background-image: url(/public/assets/spot.png);
}

.bootstrap-custom-dropdown-header div a:nth-child(2) {

    background-image: url(/public/assets/insta.png);
}

.bootstrap-custom-dropdown-header div a:hover:nth-child(2) {
    background-image: url(/public/assets/insta.png);
}

.bootstrap-custom-dropdown-header div a:nth-child(3) {

    background-image: url(/public/assets/insta.png);
}

.bootstrap-custom-dropdown-header div a:hover:nth-child(3) {
    background-image: url(/public/assets/insta.png);
}

.bootstrap-custom-dropdown-header-home div {
    background: white !important;
    border-radius: 6px;
    color: black !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 0px;
    width: fit-content;
}

.bootstrap-custom-dropdown-header-home div a {

    /* background-image: url(/public/assets/spot.png); */
    background-position: left center;
    background-repeat: no-repeat;
    color: black;
    padding-left: 25px;
    font-size: 0.938rem !important;
    font-weight:600;
    width: fit-content;
}

.bootstrap-custom-dropdown-header-home div a:hover {
    /* background-image: url(/public/assets/spot.png); */
    background-position: left center;
    background-repeat: no-repeat;
    background-color: transparent !important;
    color: #21CA50 !important;
}

.bootstrap-custom-dropdown-header-home div a:active {

    background-color: transparent !important;
    color: #21CA50 !important;
    font-style: normal;
    font-size: 0.938rem;
    font-weight: 300;
    line-height: normal;
}

.bootstrap-custom-dropdown-header-home div a:first-child {

    background-image: url(/public/assets/spotHome.svg);
}

.bootstrap-custom-dropdown-header-home div a:hover:first-child {
    background-image: url(/public/assets/spotHome.svg);
}

.bootstrap-custom-dropdown-header-home div a:nth-child(2) {

    background-image: url(/public/assets/instaHome.svg);
}

.bootstrap-custom-dropdown-header-home div a:hover:nth-child(2) {
    background-image: url(/public/assets/instaHome.svg);
}

.bootstrap-custom-dropdown-header-home div a:nth-child(3) {

    background-image: url(/public/assets/instaHome.svg);
}

.bootstrap-custom-dropdown-header-home div a:hover:nth-child(3) {
    background-image: url(/public/assets/instaHome.svg);
}

.btn-user {
    padding: 0px 20px;
    height: 32px;
    min-width: 150px !important;
    background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
    border-radius: 8px;
}

.btn-user-home {
    padding: 0px 20px;
    height: 32px;
    min-width: 150px !important;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #38C558 !important;
    box-shadow: -3px -4px 7px 0px rgba(255, 255, 255, 0.10) inset, 4px 38px 62px 0px rgba(0, 0, 0, 0.10) !important;
    border-radius: 8px;
}

.btn-font {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 600;
    font-size: 0.938rem;
    line-height: 28px;
    color: #FFFFFF;
}

.bg-navbar {
    background-color: transparent;
    /* background-image: url("/public/assets/banner/bg.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom right; */
}

@media only screen and (max-width: 995px) {
    .btn-user {
        padding: 0px 20px;
        height: 32px;
        min-width: none !important;
        width: auto !important;
        background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
        border-radius: 8px;
    }

    .btn-user-home {
        padding: 0px 20px;
        height: 32px;
        min-width: none !important;
        width: auto !important;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #38C558 !important;
        box-shadow: -3px -4px 7px 0px rgba(255, 255, 255, 0.10) inset, 4px 38px 62px 0px rgba(0, 0, 0, 0.10) !important;
        border-radius: 8px;
    }

    .gapIssue {
        gap: 0px;
    }
}
.label-text {
    color: white;
    /* font-size:medium; */
    font-family: Raleway;
    font-weight: 500;
}

.PhoneInputInput {
    color: white !important;
}

.form-popup {
    border-radius: 10px;
    background-color: #7B7B7B !important;
    color: white !important;
    outline: none;
    border: none !important;
}

.form-popup:active {
    color: white !important;
}

.handle-btn-popup {
    border-radius: 4px !important;
    border: none !important;
    background-color: #10EDB6 !important;
}
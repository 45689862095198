.bg-login {
    background-color: #282525;
    color: white;
    overflow: hidden;
}

.btn-register {
    background: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%);
    border-radius: 10px;
    width: 220px;
    height: 60px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 44px;
    /* identical to box height */
    color: white;
    border: none;
}

.btn-register:hover {
    background: linear-gradient(93.19deg, #0dc496 0.77%, #3e53db 100%);
}

.login-intro h1 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 70px
}
.login-intro-h1 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 44px !important;
    line-height: 70px !important;
}

.login-intro span {
    background: linear-gradient(90deg, #10EDB6 50%, #4C62F7 97.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.login-intro p {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 400;
    /* font-size: 24px; */
    line-height: 33px;

    color: #DADADA;
}
.login-intro-p {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    /* font-size: 24px; */
    line-height: 33px !important;

    color: #DADADA !important;
}

.bg-login-card {
    background-image: url(/public/assets/loginbg.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    height: 500px;
}

.bg-login-card-2 {
    background-image: url(/public/assets/loginbg2.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 500px;
}

.make-login-box {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
    box-sizing: border-box;
    /* box-shadow: 0px 0px 25px 25px linear-gradient(180deg, rgba(16, 237, 182, 0.35) 0%, rgba(76, 98, 247, 0.35) 100%); */
    /* background-color: #fff; */
    /* Set the desired background color for the box */

    /* Apply the box shadow with gradient */
    /* box-shadow: 0 0 25px 25px rgba(16, 237, 182, 0.35), 0 0 10px 0px rgba(76, 98, 247, 0.35); */
    border-radius: 20px;
    width: 450px;
    height: auto;
    padding: 15px;
}

.handleModalBody {
    background-color: transparent !important;
}

@media only screen and (max-width: 500px) {
    .make-login-box {
        width: fit-content;
    }

    .bg-login {

        padding-top: 20px;
        padding-bottom: 80px;

    }
}
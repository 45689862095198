.searchGlass {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0 16px 16px 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px;
    font-size: 0.938rem;
}

.text-gray {
    /* hover color  */
    color: rgb(197, 197, 197);
}

.searchBtn {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px 0 0 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    padding: 3px;
    font-size: 12px;
}

.searchDropdown {
    line-height: 1.5rem;
    position: absolute;
    top: 120%;
    left: 15%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    border-radius: 10px;
    background-color: transparent;
    display: none !important;
    color: rgb(243, 243, 243);
    font-style: normal;
    z-index: 999;
    padding: 10px;
    font-size: 0.875rem;
    font-weight: bold;
}

.onFocMode {
    min-height: 30px;
    width: 70%;
    background-color: rgb(66, 65, 65);
    display: block !important;
}

.hoverMode:hover {
    color: #7eb6ff !important;
    font-style: normal;
}
.text-default{
    color: white;
}
/* Home  */
.searchGlassHome {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0 16px 16px 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px;
    font-size: 0.938rem;
}
.text-defaultHome{
    color: black;
}
.text-grayHome {
    color: black;
}

.searchBtnHome {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px 0 0 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: black;
    padding: 3px;
    font-size: 12px;
}

.searchDropdownHome {
    line-height: 1.5rem;
    position: absolute;
    top: 120%;
    left: 15%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    max-height: 500px;
    border: 1px solid black;
    background-color: transparent;
    display: none !important;
    color: rgb(243, 243, 243);
    font-style: normal;
    z-index: 999;
    padding: 10px;
    font-size: 0.875rem;
    font-weight: bold;
}

.onFocModeHome {
    min-height: 30px;
    width: 70%;
    background-color: white;
    color: black;
    display: block !important;
}

.hoverModeHome:hover {
    color: #7eb6ff !important;
    font-style: normal;
}
.borderPart{
    border: 1px solid gray;
    border-radius: 10px;
}
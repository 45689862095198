.guZdik {
    border: none !important;
    border-radius: 10px;
    background-color: #7B7B7B !important;
    color: white !important;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
    height: 2.1rem !important;
    min-width: auto !important;
}

.guZdik>input {
    color: white !important;
}

.jWkLDY>span {

    color: #C8C8C8 !important;
}


.form-identificaiton {

    border-radius: 10px;
    background-color: #7B7B7B !important;
    color: white !important;
    outline: none !important;
    border: none !important;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
    height: 2.1rem;

}

.form-identificaiton:active {
    color: white !important;
}

.form-identificaiton::placeholder {
    color: #C8C8C8 !important;
}

.label-text-identification {
    color: white;
    /* font-size:medium; */
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei" !important;
    font-weight: 500;
}

.defaultCheckboxCSS .form-check-input{
    outline: none !important;
    background-color: white !important;
    border: none !important;
}
.defaultCheckboxCSS .form-check-input:checked{
    /* outline: none !important;
    background-color: white !important;
    border: none !important; */
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}
@media only screen and (max-width: 650px) {
    .label-text-identification {
        color: white;
        /* font-size: small; */
        font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
        font-weight: 500;

    }
}
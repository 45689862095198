/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap"); */

.inputcontainer {
  position: relative;
}

.controlPos p {
  font-size: 0.875rem;
}

.place-order-green {
  background-color: #14B269;
}

.place-order-red {
  background-color: #FF4B5E;
}

.place-order-green:disabled {
  background-color: rgb(16, 150, 88);
  cursor: not-allowed;
}

.place-order-red:disabled {
  background-color: #cf2233;
  cursor: not-allowed;
}

.controlPos span {
  color: #C8C8C8;
}

.scrolling-part {
  border: 1px solid red;
  overflow: auto;

}

#tradingviewwidgetFutures {
  width: 100% !important;
  height: 500px !important;
}

.icon-container {
  position: absolute;
  left: 60px;
  top: calc(50% - 10px);
}

.icon-containerTab {
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
}

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.loader::after,
.loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.btn-register {
  background: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%);
  border-radius: 10px;
  width: 220px;
  height: 60px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 44px;
  /* identical to box height */
  color: #000000;
  border: none;
}

.btn-register:hover {
  background: linear-gradient(93.19deg, #0dc496 0.77%, #3e53db 100%);
}

.accordion-button:not(.collapsed)::after {
  filter: invert(1) grayscale(100%) brightness(200%) !important;
}

.light-new {
  --mainTextColor: black;
}

.dark-new {
  --mainTextColor: white;
}

body {
  /* background: #ffffff;
  color: #000; */
  min-height: 100vh;
  outline: none !important;
  position: relative;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";

  /* background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%); */
}

.dark-new {
  background-color: #07080a;
  color: #ffffff;
}

.light-new {
  background-color: #ffffff;
  color: #252547;
}

.blue-whale {
  background-color: #0c3040;
  color: rgb(255, 255, 255);
}

.blue-dark {
  background-color: #13212e;
  color: #ffffff;
}

.brown {
  background-color: #141414;
  color: #ffffff;
}

.dark-black {
  background-color: #000000;
  color: #ffffff;
}

/* width */
/* ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #fff;
} */

::-webkit-scrollbar-track
/* {
  width: 5px;
  height: 5px;
  border-radius: 5px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #02b3b4 ;
  border-radius: 5px;
} */

.text-css {
  color: #02b3b4;
}

/* Typography css begin */
h5 {
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.text-justify {
  text-align: justify !important;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

/* Color css begin */

.navbar-toggler-icon {
  color: rgb(0, 0, 0) !important;
}

.nav-link:focus,
.nav-link:hover {
  color: rgb(255, 255, 255);
}

input {
  box-shadow: none;
}

/* img {
  width: 100%;
  height: 100%;
} */

#navbar-img {
  width: 130px;
  height: 40px;
}

.fs-1em {
  font-size: 1em;
  font-weight: 500;
}

.mainbanner-gif-size {
  width: 50%;
  height: 50%;
}

.uppercase-font-css {
  text-transform: uppercase;
}

.mainbanner-form-control-css .form-control {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.bannerHeading {
  font-size: 50px;
}

.img-title-banner {
  font-size: 30px;
  font-weight: 600;
}

.img-banner {
  height: 450px;
}

.playstore {
  width: 160px;
  height: auto;
}

@media only screen and (min-width: 900px) and (max-width:1535px) {
  .bannerHeading {
    font-size: 45px;
  }

  .img-title-banner {
    font-size: 25px;
  }

  .img-banner {
    height: 400px;
  }
}

@media only screen and (max-width: 899px) {
  .bannerHeading {
    font-size: 40px;
  }

  .playstore {
    width: 180px;
    height: auto;
  }

  .img-title-banner {
    font-size: 20px;
  }

  .img-banner {
    height: 400px;
  }
}

.display-none {
  display: none !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-bottom: 40px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.offcanvas {
  background-color: #02b3b4;
}

.btn-close {
  /* background-image: url("../assets/images/cross.white.svg"); */
  opacity: 1;
  box-shadow: none !important;
}

.bg-light-green {
  background-color: rgb(209, 255, 209);
  border-radius: 0.5em;
  font-size: 11px;
}

.f-16 {
  font-size: 16px !important;
}

.f-12 {
  font-size: 12px !important;
}

.fc-r {
  color: rgb(234, 0, 112);
}

.fc-g {
  color: #02b3b4;
}

/* .container-height-css {
  height: 500px;
} */

.chart-page .chart-container-border {
  background-color: red !important;
  border: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}

.navbar-shrink {
  background-color: red;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.nav-tabs-buy-sell-section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .banner-second-section-table-css .table {
    background-color: #bdf1f110 !important;
    border: 1px solid #000000 !important;
  }

  /* .banner-second-section-table-css .table tbody tr td {
    background-color: #8fd3d355 !important;
  } */

  .banner-second-section-table-css .table tr {
    background-color: #bdf1f110 !important;
  }

  .banner-second-section-table-css .table th {
    border-bottom: 1px solid #000000 !important;
  }

  .banner-second-section-table-css .table> :not(caption)>*>* {
    background-color: transparent !important;
    border: none;
    padding: 0.6em !important;
  }

  .card-styling-mainbanner-2 {
    background-color: #8fd3d355;
    background-image: url("./images/glow-effects-p2p.png");
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 100% 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    border-radius: 1em;
    padding: 30px;
  }

  .market-table-data-logo {
    width: 28px;
    height: 28px;
  }

  .nav-link-login-button {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #ffffff !important;
    padding: 5px 20px !important;
    border: 1px solid #02b3b4 !important;
  }

  .scrolltext {
    font-size: 18px;
  }

  .faq-button {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    font-size: 18px !important;
    border: none !important;
    margin-top: 2em;
  }

  .nav-link-register-button {
    background: #02b4b400 0% 0% no-repeat padding-box;
    border: 1px solid #02b3b4 !important;
    border-radius: 4px;
    opacity: 1;
    color: #02b3b4 !important;
    padding: 5px 20px !important;
  }

  .top-para-text-1 {
    margin-top: 20px;
    font-size: 18px;
    width: 60%;
  }

  .dashboard-navbar-styling-css .navbar {
    margin-left: 200px;
  }

  /* ================== NEW-CSS-DEC-20-START ============================================ */

  .greenbottom-sample {
    color: #25ca9d !important;

    border-bottom: 2px solid #25ca9d;
  }

  .violet-color-text {
    color: #6101b5 !important;
  }

  .mobile-apps-store-icons {
    font-size: 25px;
    margin-right: 3px;
  }

  .banner-top-button-3-app-stores {
    text-align: center;
    text-decoration: none;
    padding: 13px 13px;
    margin: 3px;
    margin-top: 10px;
    border-radius: 8px;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
    color: #ffffff;
  }

  .our-benefits-cards-icon-bg {
    background: #6101b5;
    border-radius: 5px 5px 0px 0px;
    padding: 1em;
  }

  .our-benefits-card-paragraph-padding {
    padding: 2em 1.2em;
  }

  /* ================== NEW-CSS-DEC-20-END ============================================== */

  .card-width-style {
    width: 50%;
  }


  .paired-trade-dropdown-table tbody {
    display: block;
    height: 350px;
    overflow: auto;
    width: 100%;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table thead,
  .paired-trade-dropdown-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }


  /* .paired-trade-dropdown-table .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  } */

  .paired-trade-dropdown-table .table> :not(caption)>*>* {
    border: 0px !important;
    padding: 0.4rem 0.5rem;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table tbody tr {
    border: 0px !important;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table tbody tr:hover {
    /* background-color: #ced4da; */
    background-color: rgba(221, 221, 221, 0.04) !important;
    cursor: pointer;
  }

  .paired-trade-dropdown-table .table tbody tr td:first-child {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table tbody tr td {
    padding-left: 20px;
    font-size: 13px;
    font-weight: 400;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table th {
    padding-left: 20px !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
    letter-spacing: 00.045em;
  }

  .paired-trade-dropdown-tabs-1 .form-control {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color: transparent !important;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 .input-group-text {
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 {
    border: 1px solid #dbdde6;
    padding: 0.775rem 0.75rem;
  }

  .paired-trade-dropdown-tabs .nav-pills .nav-link.active,
  .paired-trade-dropdown-tabs .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-trade-dropdown-tabs .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: white !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    border-radius: 0px;
    margin-right: 40px;
  }

  .spot-page-pair-select-dropdown .dropdown-item {
    min-width: 36rem !important;
  }

  .spot-page-pair-select-dropdown .dropdown-item:hover {
    background-color: transparent !important;
  }

  .spot-page-pair-select-dropdown .dropdown-toggle {
    padding-bottom: 30px;
  }

  .spot-table-search-button {
    color: #000;
    border: 0px;
    width: 100%;
    padding: 0.2rem 1rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid rgb(238, 240, 242);
  }

  .paired-refresh-button {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 0px;
    letter-spacing: 0.2px;
    border: 0px;
    padding: 0px;
    background: transparent;
  }

  .trading-page-order-tabs-section {
    height: 120px !important;
  }

  .p2p-trade-buy-sell-collapse-container {
    border: none;
    background-color: rgb(251, 251, 251);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .p2p-trade-buy-sell-collapse-container .form-select:focus {
    border: 1px solid rgb(114, 114, 114);
    outline: 0;
    box-shadow: none !important;
  }

  .p2p-trade-buy-sell-collapse-container .form-control {
    background-color: transparent;
  }

  .p2p-trade-buy-sell-collapse-container .form-select {
    background-color: transparent;
  }

  .buynow-btn {
    background-color: #02b3b4;
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .sellnow-btn {
    background-color: rgba(241, 55, 55);
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .add-payment-method-heading-styling {
    font-size: 24px;
    padding: 20px 0px;
    font-weight: 550;
  }

  .add-payment-method-text-1 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .add-payment-method-text-2 {
    font-size: 11px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    color: rgb(177, 177, 177);
  }

  .add-payment-method-top-banner .form-control-payment-method {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .add-payment-method-cancel-button {
    background-color: #eaecef;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .add-payment-method-confirm-button {
    background-color: #02b3b4;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .payment-qrcode-optional-image {
    width: 70px;
  }

  .payment-qrcode-optional-button {
    border: 1px solid rgb(177, 177, 177);
    font-size: 13px;
    padding: 2px 30px;
    color: gray;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .p2p-trade-hero-section-two-nav-bg .dropdown-toggle::after {
    display: none;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  .noresponse-image-styling {
    width: 90px;
  }

  .profile-images-letter-styling {
    background-color: #000000 !important;
    border-radius: 1em !important;
    color: #ffffff;
    padding: 3px;
    font-size: 1.3em !important;
  }

  .scrollspy-payments-row-bg-styling {
    /* background: rgb(250, 250, 250); */
  }

  .font-size-style {
    font-size: 17px;
    font-weight: 600;
  }

  .p2puser-center-button {
    background: #02b3b4;
    color: #fff !important;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2puser-center-scrollspy-styling-active {
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollsp-border {
    border-bottom: 1px solid #dee2e6;
  }

  .p2puser-center-scrollspy-button {
    border: none;
    box-shadow: none !important;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    letter-spacing: 0.08em;
    padding: 20px 20px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .p2puser-center-scrollspy-styling .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: inherit;
    font-size: 16px !important;
    font-weight: 500;
  }

  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-item.show .nav-link,
  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-link.active {
    color: #495057 !important;
    background-color: #fff;
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollspy-styling .dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .p2p-user-scrollspy-button {
    color: rgb(30, 35, 41);
    background-color: rgb(234, 236, 239);
    font-size: 14px;
  }

  .p2p-user-center-scrollspy-tops-heading {
    min-width: 0px;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .border-line-styling {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    width: 4px;
    height: 14px;
    border-radius: 4px;
    background-color: rgb(95, 37, 159) !important;
  }

  .scrollspy-tops-heading-paragraph {
    min-width: 0px;
    max-width: 100%;
    font-size: 14px !important;
  }

  #scrollspy-section-1-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    /* background: rgb(255, 255, 255); */
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-2-para {
    margin: 0px 0px 32px;
    padding: 24px;
    border-radius: 4px;
  }

  #scrollspy-section-3-para {
    margin: 0px 0px 32px;
    padding: 24px;
    border-radius: 4px;
  }

  .p2p-usercenter-progressbar {
    position: relative !important;
    right: 0px;
    z-index: 1;
    height: 9px;
    width: 100px;
    background-color: #02b3b4;
  }

  .p2p-usercenter-progressbar-top {
    height: 9px;
    width: 100px;
  }

  .datepicker-css-styling {
    border: none !important;
    width: 100px !important;
    outline: none !important;
    font-size: 0.9rem;
    background-color: transparent;
  }

  .datepicker-css-styling input {
    border: none !important;
    box-shadow: none !important;
  }

  .order-p2p-trade-dropdowns.select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    background-color: transparent;
  }

  .order-p2p-reset-content-title {
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    color: #02b3b4;
  }

  .order-p2p-trade-download-icon {
    font-size: 35px;
    background-color: rgb(214, 214, 214) !important;
  }

  .faq .card {
    border: none;
    background: none;
  }

  .faq .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .faq .card .card-header:hover {
    background: rgba(14, 203, 131, 0.205);
    padding-left: 10px;
  }

  .faq .card .card-header .faq-title {
    width: 100%;
    text-align: left;
    padding: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    color: inherit;
    text-decoration: none !important;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .faq .card .card-header .faq-title .badge {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 14px;
    float: left;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: #02b3b4;
    color: #fff;
    font-size: 12px;
    margin-right: 20px;
  }

  .faq .card .card-body {
    padding: 30px;
    padding-left: 35px;
    font-weight: 400;
    font-size: 16px;
    color: inherit;
    letter-spacing: 1px;
  }

  .hero-top-section {
    background-image: url("../assets/images/p2p/p2p-trade-background.png");
    background-size: 100% 100%;
  }

  .p2p-bottom-buy-sell-tabs-content-image {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 80px;
    max-height: 80px;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  .p2p-bottom-buy-sell-tabs-content-title-paragrah {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: inherit;
    font-size: 14px;
  }

  .p2p-bottom-buy-sell-tabs-content-title {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  .p2p-second-nav-content-title {
    font-size: 13px;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: #000000;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 500;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .p2p-bottom-buy-sell-tabs {
    margin-top: 40px !important;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    width: 100%;
  }

  .p2p-bottom-section-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .p2p-bottom-section-heading {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 40px;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -ms-flexbox;
    display: flex;
    /* margin-left: auto;
    margin-right: auto; */
    max-width: 1200px;
    /* color: #1E2329; */
    font-weight: 550;
  }

  .enter-amount-section .btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: #02b3b4;

    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section .btn:hover {
    color: #e0ad06;
  }

  .enter-amount-text-section {
    border-right: 1px solid #eaecef;
  }

  .enter-amount-text-1 {
    font-size: 15px;
    color: #aeb4bc;
  }

  .enter-amount-section .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  /* .enter-amount-section {
    border: 1px solid #f0bb0b50;
    padding: 0.175rem 0rem;
    border-radius: 6px;
  } */

  /* input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
} */
  /* ::placeholder {
  color: #cccdcf !important;
  font-size: 14px;
}
.btn:focus {
  box-shadow: none !important;
}
input:focus {
  box-shadow: none !important;
} */
  .enter-amount-heading {
    font-size: 11px;
    color: #ffffff;
  }

  .enter-amount-text-2 {
    font-size: 11px;
    color: #76808f;
    margin-left: -10px;
  }

  .f-dropdown {
    --max-scroll: 3;
    position: relative;
    z-index: 10;
  }

  .f-dropdown select {
    display: none;
  }

  .f-dropdown>span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #f0bb0b50 !important;
    background: #f5f5f5;
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .f-dropdown>span>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .f-dropdown>span img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown>span:before,
  .f-dropdown>span:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 1px;
    top: 50%;
    right: 12px;
    background: #000;
    transition: all 0.3s ease;
  }

  .f-dropdown>span:before {
    margin-right: 4px;
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown>span:after {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);
  }

  .f-dropdown ul li {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .f-dropdown ul li a {
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }

  .f-dropdown ul li a img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown ul li a:hover {
    color: #000 !important;
  }

  .f-dropdown ul li.active a {
    color: #000;
    background: gray;
  }

  .f-dropdown ul li.active a:before,
  .f-dropdown ul li.active a:after {
    --scale: 0.6;
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    position: absolute;
    right: 12px;
    top: 50%;
    opacity: 0;
    background: #fff;
    transition: all 0.2s ease;
  }

  .f-dropdown ul li.active a:before {
    transform: rotate(45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:after {
    transform: rotate(-45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:hover:before,
  .f-dropdown ul li.active a:hover:after {
    --scale: 0.9;
    opacity: 1;
  }

  .f-dropdown ul li:first-child a {
    border-radius: 3px 3px 0 0;
  }

  .f-dropdown ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  .f-dropdown.disabled {
    opacity: 0.7;
  }

  .f-dropdown.disabled>span {
    cursor: not-allowed;
  }

  .f-dropdown.filled>span {
    color: #000;
  }

  .f-dropdown.open {
    z-index: 15;
  }

  .f-dropdown.open>span {
    border-color: #aaa;
  }

  .f-dropdown.open>span:before,
  .f-dropdown.open>span:after {
    background: #000;
  }

  .f-dropdown.open>span:before {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown.open>span:after {
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown.open ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 12px);
    transition: opacity 0.3s ease, visibility 0.3s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  }

  .f-group {
    max-width: 250px;
    margin: 0 auto;
    text-align: left;
  }

  .f-group select {
    width: 100%;
  }

  .f-control {
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    border: #ccc 1px solid;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  .nav-tabs-buy-sell-section {
    border: 1px solid #e6e8ea;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell-section .nav-tabs .nav-link:hover {
    border-color: #e9ecef00 #e9ecef00 #dee2e600;
    isolation: isolate;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .green-tab-active {
    background-color: #02b3b4 !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .red-tab-active {
    background-color: rgb(203, 13, 13) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs-buy-sell .nav-tabs {
    border: 0px solid #0582ff !important;
  }

  .nav-tabs-buy-sell .nav-link {
    display: block;
    padding: 0.3rem 0rem !important;
    color: inherit;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-pills-buy-sell .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
  }

  .nav-pills-buy-sell .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: #f0b90b !important;
    border-bottom: 2px solid #f0b90b;
  }

  .nav-pills-buy-sell .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: inherit !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .hero-top-section-text-1 {
    font-size: 32px;
  }

  .advantages-p2p-section-text-1 {
    font-size: 24px;
    font-weight: 500;
  }

  .hero-top-section-text-2 {
    font-size: 14px;
    width: 50%;
    text-align: center;
    margin: auto;
    font-weight: 200;
    color: rgb(214, 214, 214) !important;
  }

  .advantages-p2p-section-text-2 {
    font-size: 14px;
    font-weight: 200;
    color: rgb(37, 37, 37) !important;
  }

  .advantage-p2p-images {
    width: 300px;
    height: 150px;
    border-radius: 0.5em;
    margin-bottom: 1em;
  }

  .modal-2-overflow-scroll {
    overflow: hidden auto !important;
    height: 432px;
  }

  .deposit-modal-list-sizing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0%;
    -webkit-box-pack: justify;

    -webkit-box-align: center;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  .deposit-modal-row-sizing {
    margin: 13px 9px 13px 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-coin-heading {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: white;
  }

  .deposit-modal-coin-muted-text {
    margin: 0px;
    min-width: 0px;
    color: white;

    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
  }

  .deposit-modal-coin-images {
    width: 30px !important;
    height: 30px !important;
  }

  #myUL2 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #myUL2 li a {
    margin-bottom: 1px;
    background-color: transparent;
    padding: 4px;
    padding-left: 10px;
    text-decoration: none;
    color: black;
    display: block;
  }

  #myUL2 li a:hover:not(.header) {
    background-color: rgba(128, 128, 128, 0.794);

  }

  #myInput2 {
    margin: 0 auto;
    width: 100%;
    height: 45px;
    padding: 2px 20px;
    font-size: 1rem;
    border-radius: 15px;
    outline: none;
    margin-bottom: 15px;
    color: white;
  }

  #myInput2::placeholder {
    color: white;
  }

  .deposit-crypto-modal .modal-content {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
    box-shadow: rgb(20 21 26 / 16%) 0px 8px 16px,
      rgb(71 77 87 / 16%) 0px 16px 32px, rgb(20 21 26 / 10%) 0px 0px 1px;
    border-radius: 6px;
    background-color: #535151;
    animation: 0.3s ease-in-out 0s 1 normal none running animation-1wqz9z0;
  }

  .deposit-crypto-modal .modal-header {
    border-bottom: none;
  }

  .no-record-image {
    width: 120px;
  }

  .no-records-found td {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
  }

  .table-view-all-deposit-button-1,
  .table-view-all-deposit-button-2 {
    background-color: transparent;
    color: #02b3b4;
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .dest-addres-link {
    margin-left: 4px;
    font-size: 18px;
  }

  .dest-addres-ellipsis {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .deposit-fourth-section .table> :not(caption)>*>* {
    padding: 0.85rem 0.85rem;
  }

  /* .deposit-fourth-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  } */

  .deposit-fourth-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
  }

  .deposit-fourth-section th:first-child {
    border-top-left-radius: 4px;
  }

  .deposit-fourth-section th:last-child {
    border-top-right-radius: 4px;
  }

  .deposit-fourth-section tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    padding: 30px;
  }

  .deposit-text-11 {
    font-size: 24px;
  }

  .deposit-text-9 {
    font-weight: 800;
  }

  .deposit-text-8 {
    text-decoration: underline;
  }

  .deposit-text-7 {
    color: #02b3b4;
  }

  .deposit-icon-1 {
    margin-right: 10px;
    font-size: 24px;
    color: rgb(112, 122, 138);
  }

  .deposit-text-6 {
    font-size: 14px;
  }

  .custom-dropdown-wallet {
    background-color: transparent;
    color: #fff;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-color: rgb(234, 236, 239) !important;
  }

  .custom-dropdown-wallet:focus {
    outline: none;
    box-shadow: none;
  }

  .custom-dropdown-wallet option {
    background-color: #343a40;
    color: #fff;
  }

  .custom-dropdown-wallet:hover {
    border-color: #02b3b4;
  }

  .custom-dropdown-wallet option:hover {
    background-color: #495057;
  }
  
  .deposit-select-coin-section {
    width: 100%;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(234, 236, 239);
    cursor: pointer;
  }

  .deposit-select-coin-section:hover {
    border-color: #02b3b4;
  }

  .deposit-text-5 {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-text-4 {
    font-size: 15px;
    font-weight: 500;
    margin-right: 6px;
  }

  .deposit-logo-image {
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  .deposit-third-right-section {
    width: 100%;
  }

  .deposit-third-left-section {
    width: 250px !important;
  }

  .deposit-third-section {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 700px;
  }

  .deposit-text-3 {
    font-weight: 300;
    font-size: 15px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-text-33 {
    font-weight: 300;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-crupto-explain-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .deposit-text-2 {
    font-weight: 300;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .crypto-workflow-section-close-button {
    font-size: 30px;
    cursor: pointer;
  }

  .deposit-second-section {
    padding: 15px 20px 30px 20px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 100%;
  }

  .base-timeline::before {
    content: "";
    width: 100%;
    border-top: 2px solid #02b3b4;
    display: inline-block;
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 75%;
  }

  .base-timeline__item {
    position: relative;
    display: inline-block;
    width: calc(100% / 4 - 5px);
  }

  .base-timeline__item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    content: counter(number) "";
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #02b3b4;
    color: white;
    font-weight: bold;
    transition: all 0.6s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .base-timeline__item--data::before {
    width: 60px;
    height: 60px;
  }

  .deposit-fiat-button {
    background-color: rgb(242, 243, 245);
    padding: 6px 16px;
    border: none;
    font-size: 14px;
  }

  .deposit-fiat-button:hover {
    background-color: transparent;
  }

  .deposit-text-1 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .deposit-back-button-icon {
    font-size: 34px;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
    position: relative;
    top: -3px;
  }

  .deposit-back-button-icon:hover {
    background-color: rgb(242, 243, 245);
  }

  .deposit-hero-section {
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* 
  .deposit-page-top-banner {
    margin-top: 65px;
  } */

  /* / ===============================================Harish-css-start================================================ / */
  .terms-text-3 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 4px;
  }

  .terms-text-2 {
    font-size: 15px;
    font-weight: 600;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .terms-text-1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .accordian-dot-icons {
    font-size: 20px;
    margin-right: 8px;
  }

  .faq-second-section .accordion-body {
    font-size: 15px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    background-color: transparent;
    color: white;
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }

  .faq-second-section .accordion-button {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    box-shadow: none !important;
    width: 100%;
    padding: 1rem 1.25rem;
    color: inherit !important;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    background-color: transparent;
  }

  .faq-second-section .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
    color: white;
  }

  .market-trade-button {
    border-radius: 2px;
    border: 0.4px solid #10EDB6;
    background-color: transparent;
    color: white;
    font-size: 12px;
    padding: 4px 30px;
    border-radius: 4px;
  }

  .market-trade-button:hover {
    border: 1px solid #02b3b4;
    background-color: #02b3b4;
    color: #fff;
    font-size: 12px;
    padding: 4px 30px;
  }

  .markets-second-section tbody {
    border: none !important;
  }

  .markets-second-section table {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
  }

  .markets-second-section .table> :not(caption)>*>* {
    padding: 1rem 0.5rem;
  }

  .markets-second-section table tr {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    border-bottom: 1px solid #dee2e654 !important;
  }

  .markets-second-section table tbody tr:last-child {
    border-bottom: 0 solid #dee2e6 !important;
  }

  .markets-second-section table tbody tr:hover {
    background-color: #f5f8fb;
  }

  .markets-second-section table th {
    background-color: #02b3b4 !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
  }

  .markets-second-section table th:first-child {
    border-top-left-radius: 12px;
  }

  .markets-second-section table th:last-child {
    border-top-right-radius: 12px;
  }

  .markets-second-section table td {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .transaction-history-select-section .transaction-form-control {
    display: block;
    width: 100%;
    height: 35px;
    padding-left: 8px;
    font-size: 12px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(112, 122, 138, 0.336);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .custom-dropdown-trans-section .select-dropdown-btn {
    padding-left: 7px;
    padding-right: 7px;
    font-size: 0.9rem;
    color: inherit !important;
    background-color: transparent;
    width: 130px !important;
    height: 35px;
    border: 1px solid rgba(112, 122, 138, 0.336) !important;
    border-radius: 4px;
  }

  .custom-dropdown-trans-section .select-dropdown-btn:hover {
    font-size: 0.8rem;
    color: inherit !important;
    background-color: transparent;
    border: 1px solid rgb(112, 122, 138) !important;
  }

  .transaction-text-1 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 12px;
  }

  .transaction-history-tabs-section .nav-pills .nav-link.active,
  .transaction-history-tabs-section .nav-pills .show>.nav-link {
    color: #fff !important;
    background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
  }

  .transaction-history-tabs-section .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: rgb(112, 122, 138);
    text-decoration: none;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trading-page-order-table-details-section tbody {
    border: none !important;
  }

  .trading-page-order-table-details-section tbody td {
    font-weight: 900;
    align-items: center;
    font-size: 10px !important;
    letter-spacing: 1px;
    color: white;
    background-color: transparent;
  }

  .trading-page-order-table-details-section .table> :not(caption)>*>* {
    border: 0px;
    padding: 0.4rem 0.3rem !important;
  }

  .trading-page-order-table-details-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: white !important;
    background-color: transparent !important;
    border: 0px;
    padding: 4px 4px;
    letter-spacing: 0.28px;
    vertical-align: middle;
    border-bottom: 1px solid #dbdde6;
  }

  .trading-page-order-table-details-section .select-dropdown-btn {
    font-size: 13px;
  }

  .trading-page-order-table-details-section .dropdown-item {
    font-size: 13px !important;
  }

  .withdraw-crypto-network-modal .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }

  .withdraw-crypto-network-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-network-modal .modal-header {
    border: none;
  }

  .withdraw-modal-coin-muted-text-1 {
    color: white;
  }

  .withdraw-modal-coin-muted-text {
    font-weight: 300;
    font-size: 14px;
    color: white;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .withdraw-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 20px 10px !important;
    padding: 5px;
    border-radius: 4px;
  }

  .withdraw-modal-network-sizing:hover {
    background-color: #eee;
    color: rgb(112, 122, 138);
  }

  .withdraw-nav-tabs ::placeholder {
    font-size: 15px;
    font-weight: 300;
    color: white;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .withdraw-nav-tabs .form-control {
    width: 100%;
    padding: 0.375rem 1.2rem !important;
    font-size: 0.9rem;
    height: 48px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 1px solid #dee2e6;
  }

  .withdraw-crypto-modal-text-4 {
    font-size: 14px;
    font-weight: 200;
    margin-left: 15px;
  }

  .withdraw-crypto-modal-text-3 {
    font-size: 14px;
    font-weight: 200;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .withdraw-crypto-list-items {
    padding: 6px 10px;
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .withdraw-crypto-list-items:hover {
    background-color: #eee;
    color: rgb(112, 122, 138);
  }

  .withdraw-crypto-modal-text-2 {
    font-size: 14px;
    font-weight: 200;
    position: relative;
    top: -4px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .withdraw-crypto-modal-text-1 {
    font-size: 18px;
    position: relative;
    top: 4px;
  }

  .withdraw-crypto-modal-images {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .withdraw-crypto-modal-list {
    list-style: none;
    padding: 0px;
  }

  .withdraw-crypto-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-modal .modal-header {
    border: none;
  }

  .select-network-text-1 {
    font-size: 14px;
  }

  .modal-2-overflow-scroll {
    overflow: hidden auto !important;
    height: 432px;
  }

  .deposit-modal-list-sizing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  .deposit-modal-row-sizing {
    margin: 3px 9px 3px 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-coin-heading {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    color: white;
  }

  .deposit-modal-coin-muted-text {
    color: white;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .deposit-modal-coin-images {
    width: 25px !important;
    height: 25px !important;
  }

  #myUL2 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #myUL2 li a {
    margin-bottom: 1px;
    background-color: transparent;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    text-decoration: none;
    color: black;
    display: block;
  }

  #myUL2 li a:hover:not(.header) {
    background-color: rgba(128, 128, 128, 0.794);
  }

  .deposit-crypto-modal .modal-header {
    border-bottom: none;
  }

  .wallet-info-icon-1 {
    font-size: 12px;
    margin-left: 4px;
  }

  .qr-code-tooltip-image {
    width: 100%;
  }

  .tooltip-text-3 {
    font-size: 13px;
    font-weight: 700;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .tooltip-text-2 {
    font-size: 12px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .tooltip-text-1 {
    font-size: 11px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .tooltip-text-Theme {
    padding-bottom: 10px !important;
    width: 250px;
    color: #fff !important;
    background-color: rgb(0, 0, 0) !important;
  }

  .tooltip-text-Theme::after {
    background-color: rgb(0, 0, 0) !important;
  }

  .no-record-image {
    width: 120px;
  }

  .no-records-found td {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
  }

  .table-view-all-deposit-button-1,
  .table-view-all-deposit-button-2 {
    background-color: transparent;
    color: #02b3b4;
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .dest-addres-link {
    margin-left: 4px;
    font-size: 18px;
  }

  .dest-addres-ellipsis {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .deposit-fourth-section .table> :not(caption)>*>* {
    padding: 0.85rem 0.85rem;
  }

  /* .deposit-fourth-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  } */

  .deposit-fourth-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
  }

  .deposit-fourth-section th:first-child {
    border-top-left-radius: 8px;
  }

  .deposit-fourth-section th:last-child {
    border-top-right-radius: 8px;
  }

  .deposit-fourth-section tr {
    font-weight: 500;
    align-items: center !important;
    vertical-align: middle;
    font-size: 12px;
    padding: 30px;
  }

  .deposit-text-11 {
    font-size: 24px;
  }

  .deposit-text-9 {
    font-weight: 800;
  }

  .deposit-text-8 {
    text-decoration: underline;
  }

  .deposit-text-7 {
    color: #02b3b4;
  }

  .deposit-icon-1 {
    margin-right: 10px;
    font-size: 24px;
    color: rgb(112, 122, 138);
  }

  .deposit-text-6 {
    font-size: 14px;
  }

  .deposit-text-66 {
    font-size: 13px;
  }

  .deposit-select-coin-section {
    width: 100%;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(234, 236, 239);
    cursor: pointer;
  }

  .deposit-select-coin-section:hover {
    border-color: #02b3b4;
  }

  .deposit-text-5 {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-text-4 {
    font-size: 15px;
    font-weight: 500;
    margin-right: 6px;
  }

  .deposit-logo-image {
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  .deposit-third-right-section {
    width: 100%;
  }

  .deposit-third-left-section {
    width: 250px !important;
  }

  .deposit-third-section {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 700px;
  }

  .deposit-text-3 {
    font-weight: 300;
    font-size: 15px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-text-33 {
    font-weight: 300;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-crupto-explain-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .crypto-workflow-section-close-button {
    font-size: 30px;
    cursor: pointer;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 100%;
  }

  .base-timeline::before {
    content: "";
    width: 100%;
    border-top: 2px solid #02b3b4;
    display: inline-block;
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 75%;
  }

  .base-timeline__item {
    position: relative;
    display: inline-block;
    width: calc(100% / 4 - 5px);
  }

  .base-timeline__item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    content: counter(number) "";
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #02b3b4;
    color: white;
    font-weight: bold;
    transition: all 0.6s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .base-timeline__item--data::before {
    width: 60px;
    height: 60px;
  }

  .deposit-fiat-button {
    background-color: rgb(242, 243, 245);
    padding: 6px 16px;
    border: none;
    font-size: 14px;
  }

  .deposit-fiat-button:hover {
    background-color: transparent;
  }

  .deposit-text-1 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .deposit-back-button-icon {
    font-size: 34px;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
    position: relative;
    top: -3px;
  }

  .deposit-back-button-icon:hover {
    background-color: rgb(242, 243, 245);
  }

  .deposit-hero-section {
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .deposit-page-top-banner {
    margin-top: 65px;
  } */

  .trading-page-order-tabs-section {
    height: 120px;
    margin: auto;
    text-align: center;
  }

  .trade-bottom-login-text-1 {
    color: gray;
    font-size: 14px;
  }

  .trading-page-order-details-section {
    border-top: 5px solid #f5f8fb;
    /* width: 1200px;
    margin: auto; */
  }

  .activities-button-1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 13px;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    border: none !important;
    color: #fff;
    width: 40px;
  }

  .activities-text-4 {
    position: relative;
    top: -4px;
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
    margin: 0px;
  }

  .activities-text-3 {
    color: rgb(112, 122, 138);
  }

  .activities-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .activities-text-1 {
    font-size: 14px;
  }

  .trading-table-right-activities-section {
    margin-top: 5px;
    border-top: 1px solid rgb(238, 240, 242);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .trading-table-right-bottom-section {
    height: 290px !important;
    overflow-y: scroll;
  }

  .paired-trade-table-section {
    /* margin-left: 10px !important;
    margin-right: 10px !important; */
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-bottom: 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    padding-top: 10px;
    /* margin-bottom: 10px; */
  }

  .paired-trade-table-section .nav-pills .nav-link.active,
  .paired-trade-table-section .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid #02b3b4;
  }

  .paired-trade-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 15px !important;
    color: #727581 !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 30px;
    border-radius: 0px;
    padding-bottom: 8px;
  }

  .trading-table-right-inner-section {
    height: 310px !important;
    overflow-y: scroll;
  }

  .paired-text-3 {
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    margin-left: 4px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(254, 246, 216);
  }

  .pared-fav-button {
    color: rgb(112, 122, 138);
    margin-bottom: 4px;
    margin-right: 1px;
  }

  .trading-table-right-section .flex-row {
    height: 22px;
  }

  .trading-table-right-section {
    /* width: 95%; */
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin: auto;
  }

  .paired-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .paired-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .paired-table-1 {
    width: 110px;
  }

  .paired-table-2 {
    width: 80px;
    text-align: end;
  }

  .paired-table-3 {
    width: 90px;
    text-align: end;
  }

  .paired-price-table-section {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .paired-price-table-section .nav-pills .nav-link.active,
  .paired-price-table-section .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-price-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: rgb(112, 122, 138) !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 6px;
  }

  /* .trading-page-right-section {
    width: 290px !important;
  } */

  .trade-search-section {
    width: 90%;
    margin: auto;
    border-radius: 2px;
    line-height: 1.5;
    background-color: rgb(242, 243, 245);
  }

  .trade-search-btn {
    border: none;
    color: rgb(112, 122, 138);
    background: transparent;
  }

  .trade-search-section .form-control {
    padding: 0.215rem 0.15rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .trade-search-section .form-control:focus {
    background-color: transparent;
  }

  .progress-bar {
    position: absolute;
    right: 0px;
    z-index: 1;
    height: 23px;
  }

  .ask-bar {
    background-color: rgba(255, 0, 0, 0.158) !important;
  }

  .progress-bar-bg-color {
    position: relative;
  }

  .progress-bar-bg-color .flex-row {
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center !important;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 12px !important;
    font-weight: 600;
    letter-spacing: 0.26px;
  }

  .progress-table-1 {
    width: 33%;
  }

  .progress-table-2 {
    width: 33%;
    text-align: center;
    color: white !important;
  }

  .progress-table-3 {
    width: 33%;
    text-align: end;
    color: white;
  }

  .orderbook-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: #727581;
    letter-spacing: 0.015em;
    padding-bottom: 0rem !important;
  }

  .orderbook-text-4 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.26px;
  }

  .trailing-delta-btn {
    background-color: transparent;
    font-size: 0.8rem;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
  }

  .custom-dropdown,
  .dropdown-menu {
    box-shadow: none !important;
  }

  .custom-dropdown .dropdown-item {
    color: inherit;
    font-size: 14px;
  }

  .select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(112, 122, 138);
    background-color: transparent;
  }

  .select-dropdown-btn:hover {
    border: none !important;
  }

  .limit-market-section-bottom .select-amount-button {
    background-color: transparent;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
    font-size: 0.9rem;
  }

  .limit-market-section-bottom .dropdown-item {
    padding: 0.5rem 1rem;
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-item:hover {
    background-color: rgb(234, 236, 239);
  }

  .limit-market-section-bottom .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-menu {
    border-radius: 0rem !important;
    padding: 0px !important;
    left: -20px !important;
  }

  .trade-text-15 {
    color: #02b3b4;
  }

  .trade-buy-sell-button-1 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(230, 232, 234);
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .trade-buy-sell-button-11 {
    padding: 0.665rem 0.465rem;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .trade-buy-sell-button-111 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .total-value-price-section {
    /* position: relative;
    top: -30px; */
    font-size: 11px;
    color: #000;
  }

  .percentage-line-section {
    height: 5px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }

  .percentage-display-section {
    background-color: #02b3b4 !important;
    height: 32px;
    width: 32px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #aaa;
    outline: none !important;
  }

  .trade-text-14 {
    font-size: 0.8rem;
  }

  .trade-text-13 {
    font-size: 0.8rem;
    color: rgb(112, 122, 138);
  }

  /* ::placeholder {
    color: #212529 !important;
  } */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .limit-market-section-bottom .form-control {
    display: block;
    width: 100%;
    padding: 0rem 0.75rem;
    font-size: 0.8rem;
    float: right;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    text-align: right;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .limit-button-width {
    width: 40px !important;
    text-align: center;

    margin-left: 6px;
  }

  .limit-price-form {
    align-items: center;
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 0.365rem 0.465rem;
    background-color: rgba(230, 232, 234, 0.6);
  }

  .limit-price-form:hover {
    border: 1px solid #02b3b4;
  }

  .limit-price-form:focus {
    border: 1px solid #02b3b4 !important;
  }

  .trade-text-12 {
    font-size: 12px;
    color: rgb(0, 0, 0);
  }

  .trade-text-11 {
    font-size: 12px;
    color: rgb(112, 122, 138);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active {
    border-top: 2px solid #02b3b4 !important;
    border-bottom: 0px solid transparent;
    color: rgb(30, 35, 41);
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active:hover {
    border-top: 2px solid #02b3b4;
    border-bottom: 0px solid transparent;
    border-left: 1px solid rgb(238, 240, 242);
    border-right: 1px solid rgb(238, 240, 242);
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: rgba(112, 122, 138, 0.815);
    letter-spacing: 0.4px;
    width: 130px;
    /* height: 50px; */
    padding: 10px 20px;
    font-weight: 500;
  }

  .limit-market-section-bottom .nav-link:hover {
    border-color: transparent;
    outline: none;
  }

  .trading-table-left-section {
    height: fit-content !important;
    overflow: hidden;
  }

  .trading-table-left-recent-trade-height {
    height: 340px !important;
    overflow-y: scroll;
  }

  .place-order-section-top {
    border-left: 5px solid #f5f8fb !important;
  }

  .trading-table-total-height {
    max-height: 585px !important;
    border-bottom: 5px solid #f5f8fb;
    overflow-y: hidden;
  }

  /* .list-hover-tooltip {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(255, 0, 0, 0.205);
  } */

  .trading-table-total-height {
    max-height: 585px !important;
    border-bottom: 5px solid #f5f8fb;
    overflow-y: hidden;
  }

  /* .list-hover-tooltip {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(255, 0, 0, 0.205);
  } */

  #tradingviewwidget {
    width: 100% !important;
    height: 500px !important;
  }

  /* .trading-inner-right-section {
    width: 650px;
  } */

  .trading-inner-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 260px !important; */
  }

  .trading-page-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* background-color: red; */
    width: 920px !important;
    overflow: hidden;
  }

  .trade-text-10 {
    font-size: 12px;
    font-weight: 100;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trade-icon-2 {
    font-size: 14px;
    position: relative;
    top: -1px;
  }

  .trade-text-9 {
    font-size: 16px;
    margin-right: 4px;
  }

  .hide-content-only {
    visibility: hidden;
  }

  .trading-page-top-section-3 .table> :not(caption)>*>* {
    padding: 0rem 0rem;
  }

  .trading-page-top-section-3 th {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .trading-page-top-section-3 tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
  }

  .trading-page-top-section-3 th:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 th:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-2 .form-check {
    min-height: 0rem;
    margin-bottom: 0rem;
  }

  .trading-page-top-section-2 .dropdown-item {
    padding: 0rem 1rem;
  }

  .trading-page-top-section-2 .dropdown-menu {
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 8%) 0px 7px 14px,
      rgb(24 26 32 / 8%) 0px 3px 6px;
    border-radius: 4px;
    transform: translate(-10rem, 28px) !important;
    font-size: 11px;
  }

  .trading-page-top-section-2 .dropdown-toggle::after {
    margin-left: 0em;
    content: none;
  }

  .trading-page-top-section-2 {
    padding: 0px;
  }

  .average-icon-display-1 {
    background-color: transparent;
    color: rgb(198, 202, 208);
    border: none;
  }

  .trading-page-top-section-2 .form-select {
    display: block;
    width: 100%;
    padding: 0.075rem 1.05rem 0.075rem 0.25rem;
    background-position: right 0.25rem center;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .trading-page-top-section-2 .form-select option {
    font-size: 0.875rem;
  }

  .trading-page-top-section-2 .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.675rem;
    border-radius: 0.2rem;
    text-align: center;
  }

  .trading-page-top-section-1 {
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    height: 80px;
    /* overflow-y: hidden !important; */
    /* width: 920px; */
  }

  .order-icon-3 {
    width: 16px;
    height: 16px;
  }

  .order-icon-2 {
    width: 16px;
    height: 16px;
  }

  .order-icon-1 {
    width: 16px;
    height: 16px;
  }

  .trading-page-section-5 {
    margin-right: 20px;
  }

  .trading-page-section-4 {
    padding-right: 15px;
  }

  .trade-text-8 {
    font-size: 11px;
    color: rgb(112, 122, 138);
    margin-bottom: 0px;
  }

  .trading-page-section-3 {
    padding-right: 15px;
    margin-right: 10px;
  }

  .trade-text-7 {
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    white-space: nowrap;
    letter-spacing: 0.24px;
    margin-bottom: 0px;
  }

  .trade-text-6 {
    color: rgba(243, 243, 243, 0.4);
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 6px !important;
    letter-spacing: 0.2px;
  }

  .trading-page-section-2 {
    border-right: 1px solid rgb(238, 240, 242);
    padding-right: 15px;
    margin-right: 10px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    text-align: start;
  }

  .trade-text-5 {
    font-size: 15px;
    font-weight: 1000;
    margin-bottom: 0px;
    letter-spacing: 0.045em;
  }

  .trade-text-4 {
    font-size: 16px;
    font-weight: 1000;
    margin-bottom: 2px;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: 1px;
  }

  .trading-page-section-1 {
    border-right: 1px solid #dbdde6;
    padding-right: 15px;
    margin-right: 15px;
  }

  .trading-page-top-section-1 {
    /* margin: 10px 0px; */
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
  }

  .trade-text-3 {
    margin-left: 6px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 9px;
    border-radius: 2px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(249, 244, 226);
    position: relative;
    top: -2px;
  }

  .trade-icon-1 {
    font-size: 11px;
    margin-right: 2px;
  }

  .trade-text-2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);
    text-decoration: underline;
    font-size: 9px;
  }

  .trade-text-1 {
    font-size: 0.85rem;
    font-weight: 1000;
    margin-bottom: 3px;
    margin-bottom: 3px;
  }

  .trading-page-box {
    /* border: 5px solid #f5f8fb; */
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trading-page-box-left-inner-section {
    border-right: 5px solid #f5f8fb;

    width: 63% !important;
  }

  .trading-page-box-right-inner-section {
    border-right: 5px solid #f5f8fb;

    width: 37% !important;
  }

  .orderbook-table-heading-section {
    border-bottom: 1px solid #dbdde6;
    border-top: 1px solid #dbdde6;
    height: 30px !important;
    vertical-align: middle !important;
    align-items: center !important;
  }

  .spot-page-top-banner {
    padding-top: 70px;
    padding-bottom: 100px;
  }

  .img-fluid-size {
    height: 35px;
    width: 35px;
  }

  .text-spot {
    font-size: 11px !important;
    color: white !important;
  }

  .place-order-buy-button {
    color: #fff;
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .place-order-login-button {
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid #dbdde6;
    background-color: transparent;
  }

  .place-order-buy-sell-percentage-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-percentage-tabs .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #02b3b4 !important;
    border: 1px solid #02b3b4 !important;
  }

  .place-order-buy-sell-percentage-tabs .nav-link {
    display: block;
    padding: 0.375rem 0.75rem;
    color: #727581;
    text-decoration: none;
    font-size: 13px !important;
    border: 1px solid #dbdde6 !important;
    width: 90% !important;
  }

  .place-order-section-top .form-control {
    /* padding: 1rem 0.75rem; */
    font-size: 0.9rem;
    font-weight: 400;
    background-color: rgba(243, 243, 243, 0.04) !important;
    color: rgb(243, 243, 243) !important;
    border: 0px !important;
    /* border-right: 0px; */
  }

  .place-order-section-top .input-group-text {
    color: #FFF;
    font-size: 10px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 400;
    background: #535151 !important;
    border: 0px !important;
  }

  .select-dropdown-btn-buysell {
    border: 1px solid #dbdde6;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 35px;
    display: flex;
    padding: 0px 16px;
    font-size: 14px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    align-items: center;
    font-weight: 500;
    line-height: 21px;
    border-radius: 2px;
    justify-content: space-between;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #14b269;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link-sell.active,
  .place-order-buy-sell-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #ff4b5e;
  }

  .place-order-buy-sell-tabs .nav-pills {
    border: 1px solid #dbdde6;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.055rem;
    border-radius: 2px;
    display: block;
    padding: 0.5rem 1rem;
    color: #727581;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .top-heading-total-height {
    height: fit-content;
    align-items: center;
    border-bottom: 1px solid #dbdde6;
  }

  .orderbook-grid-tabs .nav-link {
    display: block;
    padding: 0.2rem 0.3rem;
    padding-top: 0px !important;
    margin-left: 2px;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    border: 1px solid transparent;
  }

  .orderbook-grid-tabs .nav-pills .nav-link.active,
  .orderbook-grid-tabs .nav-pills .show>.nav-link {
    border: 1px solid rgb(2, 179, 180);
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    background-color: transparent;
  }

  .fixed-top-padding-top {
    padding-top: 150px;
  }


  .footer-bottom-section {
    background: #1f014e;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .footer-social-icons a {
    margin-right: 10px;
    color: #fff;
    padding: 12px;
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
    background-color: #3a3150;
    padding-bottom: 11px;
    padding-top: 8px;
    position: relative;
    font-size: 20px !important;
    background: rgb(37, 23, 74);
  }

  .footer-section a {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .footer-section h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    color: rgb(114, 117, 129);
    letter-spacing: 0.5px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .footer-section {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #3e1084;
    background-image: url("../assets/images/footer-bg-wave.png");
  }

  .ready-text-2 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .ready-text-1 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-2 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    color: inherit;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-1 {
    color: #242b3a;
    font-size: 22px;
    font-weight: 700;
  }

  .benefits-icons-1 {
    margin-right: 20px;
    border-radius: 3px;
    padding: 8px;
    width: 100px;
    color: #ffffff;
  }

  .top-banner-eight-section .card {
    height: 100%;

    background-color: transparent;
    transition: 1.5s;
    border: 1px solid #6101b5;
    border-radius: 5px;
  }

  .staking-image-banner {
    width: 60%;
    float: right;
  }

  .app-play-button {
    width: 167px;
    height: 60px;
  }

  .top-inner-fifth-bg-image {
    background-image: url("./images/glow1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .banner-image-bg {
    background-image: url("./images/glow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .top-inner-fifth-section {
    background: linear-gradient(180deg, #7022c8 0%, #7022c8 100%);
    color: #fff;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 80px 40px;
    border-radius: 20px;
  }

  .top-banner-seventh-section {
    background: linear-gradient(180deg, #7022c8 4.26%, #7022c8 84.1%);
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    clip-path: polygon(0 12%, 100% 0, 100% 89%, 0% 100%);
  }

  .top-banner-fourth-section {
    background: linear-gradient(180deg, #7022c8 0%, #7022c8 100%);
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .inner-third-section {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 40px;
  }

  .view-more-icon {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  .table-view-all-button-1,
  .table-view-all-button-2 {
    background-color: transparent;
    color: #02b3b4;
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .top-banner-third-section table tbody tr:nth-child(-n + 6) {
    display: table-row;
    width: 100%;
  }

  .top-banner-third-section table tbody tr {
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr:hover {
    box-shadow: 0px 4px 8px rgb(220 226 238 / 40%);
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr {
    display: none;
  }

  .table-view-all-button-2 {
    display: none;
  }

  .top-banner-third-section .table {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .top-banner-third-section .table thead tr th {
    color: #727581;
    padding: 4px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
  }

  .top-banner-third-section .table tbody td {
    color: #242b3a;
    padding: 14px 8px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.32px;
  }

  .top-banner-third-section .table tbody img {
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }

  .top-banner-third-section .table thead {
    border-bottom: 1px solid #edeef2;
  }

  .top-banner-third-section .table thead th:last-child {
    text-align: end;
  }

  .top-banner-third-section .table tbody td:last-child {
    text-align: end;
    padding-right: 10px !important;
  }

  .top-banner-third-section .table thead th {
    width: 25%;
  }

  .top-banner-third-section .table tbody {
    border: 2px solid #edeef2;
  }

  .top-banner-third-section .table> :not(:first-child) {
    border-top: 0px;
  }

  .top-banner-third-section .nav-link {
    color: #727581;
    opacity: 1;
    padding: 0px 16px;
    font-size: 14px;
    min-width: 90px;
    min-height: 35px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .top-banner-third-section .nav-pills .nav-link.active,
  .top-banner-third-section .nav-pills .show>.nav-link {
    color: rgb(255, 255, 255) !important;
    background-color: #02b3b4;
    /* background: linear-gradient(90deg, #27c69eda 0%, #8d60dbe5 100%); */
  }

  .market-trade-tabs {
    border-radius: 2px;
    background-color: rgb(200 205 223 / 16%);
    width: fit-content;
  }

  .banner-image-1 {
    width: 6px;
    position: relative;
    top: -4px;
    left: -12px;
  }

  .banner-text-7 {
    font-size: 1rem;
    font-weight: 100;
    color: #727581;
    letter-spacing: 1px;
  }

  .banner-text-6 {
    font-size: 1.4rem;
    font-weight: 100;
    letter-spacing: 2px;
  }

  .banner-text-5 {
    font-size: 1.1rem;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .banner-text-4 {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .banner-text-3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  /* .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px !important;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet-active {
    background: #02b3b4  !important;
  }

  .swiper-pagination-bullet {
    width: 25px !important;
    border-radius: 12px !important;
    height: 6px !important;
  } */

  .main-banner-background-styling {
    background: linear-gradient(160deg, #4323a0, #7722cf);
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
    padding-bottom: 20vh;
    margin-bottom: 5vh;
  }

  .bg-poligon {
    background: #f7f7ff;
    clip-path: polygon(0 11%, 100% 32%, 100% 100%, 0 100%);
    padding-top: 50vh;
    margin-top: 20vh;
    z-index: 1;
  }

  .dark-new .bg-poligon {
    background: rgb(0, 0, 0) !important;
  }

  .relative-ajustment {
    position: relative;
    margin-bottom: -55vh;
    z-index: 99;
  }

  .banner-top-icons-1 {
    margin-left: 6px;
    font-size: 20px;
    margin-bottom: 2px;
  }

  .banner-top-button-4 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 15px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    background: transparent;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #93e0ce, #8e5fdc);
    border-image-slice: 1;
    color: #6101b5 !important;
  }

  .banner-top-button-3 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 15px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .banner-top-button-2 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 20px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;

    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .banner-top-button-1 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 20px 40px;
    position: relative;
    border-radius: 52px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .banner-text-2 {
    font-size: 0.95rem;
    font-weight: 100;
    width: 75%;
    letter-spacing: 2px;
  }

  .banner-text-1 {
    font-size: 3.6rem;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .top-banner-hero-section {
    padding-top: 140px;
    padding-bottom: 80px;
  }

  #classy-navbar-mobile {
    height: 65px;

    background-color: #11003b;
  }

  .nav-register-button {
    color: #ffffff !important;
    width: 150px;

    border-radius: 2px;
    padding: 10px !important;
    margin-top: 10px;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
  }

  /* #classy-navbar-mobile .nav-link {
    box-shadow: none !important;
    font-size: 14px;
    font-weight: 500;
    color: rgb(255, 255, 255) !important;
    letter-spacing: 0.08em;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

  #classy-navbar-mobile .navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .navbar-nav li:hover>ul.dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    top: 100%;
    left: 0;
    margin-top: 0rem;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0px;
  }

  #classy-navbar-mobile .dropdown-menu {
    min-width: 22rem;
    padding: 10px 10px;
  }

  #classy-navbar-mobile .transaction-history-dropdown {
    min-width: 22rem;
    margin-left: -16rem;
    padding: 10px 10px;
  }

  .top-nav-dropdown-item-icons-1 {
    font-size: 18px;
    margin-right: 20px;
  }

  .top-nav-dropdown-item-icons-2 {
    color: transparent;
  }

  .top-nav-dropdown-item-text-1 {
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .top-nav-dropdown-item-text-2 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    position: relative;
    top: -3px;
    color: #707a8a;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .dropdown-item-hover {
    padding: 10px 20px;
  }

  .navbar-top-modes-dropdown {
    width: 40rem;
    /* margin-left: -30rem; */
    padding: 20px 13px !important;
    /* background-color: red; */
  }

  .navbar-top-hover-dropdown .dropdown-item-hover:hover {
    border-radius: 4px;
  }

  .themes-dropdown-text-1 {
    font-size: 22px;
  }

  .mode-image-dropdown-1,
  .mode-image-dropdown-2,
  .mode-image-dropdown-3,
  .mode-image-dropdown-4,
  .mode-image-dropdown-5,
  .mode-image-dropdown-6 {
    width: 90px;
    margin-right: 8px;
  }

  .switch-modes-section {
    padding: 20px 20px;
  }

  .switch-modes-section label {
    font-size: 13px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .form-check-input:checked[type="radio"] {
    background-image: none;
  }
}

/* =========================================== MOBILE-VIEW =================================== */

@media (max-width: 991px) {
  .banner-second-section-table-css .table tr {
    background-color: #8fd3d355 !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  }

  .card-styling-mainbanner-2 {
    /* background-color: #8fd3d355; */
    /* background-image: url("./images/glow-effects-p2p.png");
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 100% 100%; */
    border-radius: 2px !important;
    padding: 5px !important;
  }

  .market-table-data-logo {
    width: 25px;
    height: 25px;
  }

  .nav-link-login-button {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #ffffff !important;
    padding: 5px 20px !important;
    border: 1px solid #02b3b4 !important;
  }

  .scrolltext {
    font-size: 18px;
  }

  .nav-link-register-button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #02b3b4 !important;
    padding: 5px 20px !important;
    border: 1px solid #02b3b4 !important;
    width: fit-content;
    margin-top: 10px;
  }

  .top-para-text-1 {
    margin-top: 20px;
    font-size: 18px;
  }

  #navbar-img-dashboard {
    width: 60px !important;
    height: 30px !important;
    margin-bottom: 3em;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #005c5c !important;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: #005c5c !important;
  }

  .dashboard-navbar-styling-css .navbar {
    background-color: var(--background-dashboard) !important;
    margin-left: 0px;
  }

  /* =============================================Unitic-Exchange======================================= */
  .referYourFriendsTable {
    /* width: 900px; */
    overflow-x: scroll;
  }

  .referYourFriendsTable table {
    width: 900px;
  }

  .add-payment-method-heading-styling {
    font-size: 24px;
    padding: 20px 0px;
    font-weight: 550;
  }

  .add-payment-method-text-1 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .add-payment-method-text-2 {
    font-size: 11px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    color: rgb(177, 177, 177);
  }

  .add-payment-method-top-banner .form-control-payment-method {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .add-payment-method-cancel-button {
    background-color: #eaecef;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .add-payment-method-confirm-button {
    background-color: #02b3b4;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .paired-trade-dropdown-table tbody {
    display: block;
    height: 350px;
    overflow: auto;
    width: 100%;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table thead,
  .paired-trade-dropdown-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table> :not(:first-child) {
    border-top: 0px solid currentColor;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table> :not(caption)>*>* {
    border: 0px !important;
    padding: 0.4rem 0.5rem;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table tbody tr {
    /* border-bottom: 1px solid #ced4da; */
    border: 0px !important;
    color: white;
  }

  .paired-trade-dropdown-table .table tbody tr:hover {
    /* background-color: #ced4da; */
    background-color: rgba(221, 221, 221, 0.04) !important;
    cursor: pointer;
  }

  .paired-trade-dropdown-table .table tbody tr td:first-child {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table .table tbody tr td {
    padding-left: 20px;
    font-size: 13px;
    font-weight: 400;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-table th {
    padding-left: 20px !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 00.045em;
    background-color: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
  }

  .paired-trade-dropdown-tabs-1 .form-control {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color: transparent !important;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 .input-group-text {
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 {
    border: 1px solid #dbdde6;
    padding: 0.775rem 0.75rem;
  }

  .paired-trade-dropdown-tabs .nav-pills .nav-link.active,
  .paired-trade-dropdown-tabs .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-trade-dropdown-tabs .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: white !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    border-radius: 0px;
    margin-right: 10px;
  }

  .spot-page-pair-select-dropdown .dropdown-item {
    min-width: 100vw !important;
  }

  .spot-page-pair-select-dropdown .dropdown-item:hover {
    background-color: transparent !important;
  }

  .spot-page-pair-select-dropdown .dropdown-toggle {
    padding-bottom: 30px;
  }

  .spot-table-search-button {
    color: #000;
    border: 0px;
    width: 100%;
    padding: 0.2rem 1rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid rgb(238, 240, 242);
  }

  .paired-refresh-button {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 0px;
    letter-spacing: 0.2px;
    border: 0px;
    padding: 0px;
    background: transparent;
  }

  .trading-page-order-tabs-section {
    height: 120px !important;
  }

  .p2p-trade-buy-sell-collapse-container {
    border: none;
    background-color: rgb(251, 251, 251);
  }

  .p2p-trade-buy-sell-collapse-container .form-select:focus {
    border: 1px solid rgb(114, 114, 114);
    outline: 0;
    box-shadow: none !important;
  }

  .p2p-trade-buy-sell-collapse-container .form-control {
    background-color: transparent;
  }

  .p2p-trade-buy-sell-collapse-container .form-select {
    background-color: transparent;
  }

  .buynow-btn {
    background-color: #02b3b4;
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .sellnow-btn {
    background-color: rgba(241, 55, 55);
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2p-trade-hero-section-two-nav-bg .dropdown-toggle::after {
    display: none;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  .noresponse-image-styling {
    width: 90px;
  }

  .profile-images-letter-styling {
    background-color: #000000 !important;
    border-radius: 1em !important;
    color: #ffffff;
    padding: 3px;
    font-size: 1.3em !important;
  }

  .scrollspy-payments-row-bg-styling {
    /* background: rgb(250, 250, 250); */
  }

  .font-size-style {
    font-size: 17px;
    font-weight: 600;
  }

  .p2puser-center-button {
    background: #02b3b4;
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2puser-center-scrollspy-styling-active {
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollsp-border {
    border-bottom: 1px solid #dee2e6;
  }

  .p2puser-center-scrollspy-button {
    border: none;
    box-shadow: none !important;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    letter-spacing: 0.08em;
    padding: 20px 20px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .p2puser-center-scrollspy-styling .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: inherit;
    font-weight: 500;
  }

  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-item.show .nav-link,
  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-link.active {
    color: #495057 !important;
    background-color: #fff;
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollspy-styling .dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .p2p-user-scrollspy-button {
    color: rgb(30, 35, 41);
    background-color: rgb(234, 236, 239);
    font-size: 14px;
  }

  .p2p-user-center-scrollspy-tops-heading {
    min-width: 0px;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .border-line-styling {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    width: 4px;
    height: 14px;
    border-radius: 4px;
    background-color: rgb(95, 37, 159) !important;
  }

  .scrollspy-tops-heading-paragraph {
    min-width: 0px;
    max-width: 100%;
    font-size: 14px !important;
  }

  #scrollspy-section-1-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    /* background: rgb(255, 255, 255); */
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-2-para {
    margin: 0px 0px 32px;
    padding: 24px;
    border-radius: 4px;
  }

  #scrollspy-section-3-para {
    margin: 0px 0px 32px;
    padding: 24px;
    border-radius: 4px;
  }

  .p2p-usercenter-progressbar {
    position: relative !important;
    right: 0px;
    z-index: 1;
    height: 9px;
    width: 100px;
    background-color: #02b3b4;
  }

  .p2p-usercenter-progressbar-top {
    height: 9px;
    width: 100px;
  }

  .faq .card {
    border: none;
    background: none;
  }

  .faq .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .faq .card .card-header:hover {
    background: rgba(14, 203, 131, 0.205);
    padding-left: 10px;
  }

  .faq .card .card-header .faq-title {
    width: 100%;
    text-align: left;
    padding: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    color: inherit;
    text-decoration: none !important;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .faq .card .card-header .faq-title .badge {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 14px;
    float: left;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: #02b3b4;
    color: #fff;
    font-size: 12px;
    margin-right: 20px;
  }

  .faq .card .card-body {
    padding: 30px;
    padding-left: 35px;
    font-weight: 400;
    font-size: 16px;
    color: inherit;
    letter-spacing: 1px;
  }

  .datepicker-css-styling {
    border: none !important;
    width: 100px !important;
    outline: none !important;
    font-size: 0.9rem;
    background-color: transparent;
  }

  .datepicker-css-styling input {
    border: none !important;
    box-shadow: none !important;
  }

  .order-p2p-trade-dropdowns.select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    background-color: transparent;
  }

  .order-p2p-reset-content-title {
    font-size: 12px;
    font-weight: 500;
    color: #02b3b4;
  }

  .order-p2p-trade-download-icon {
    font-size: 35px;
    background-color: rgb(214, 214, 214) !important;
  }

  .advantage-p2p-images {
    width: 200px;
    height: 150px;
    border-radius: 0.5em;
    margin-bottom: 1em;
  }

  .p2p-second-nav-content-title {
    font-size: 10px;
  }

  .hero-top-section {
    background-image: url("../assets/images/p2p/p2p-trade-background.png");
    background-size: 400% 100%;
  }

  .p2p-bottom-buy-sell-tabs-content-image {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 80px;
    max-height: 80px;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  .p2p-bottom-buy-sell-tabs-content-title-paragrah {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: inherit;
    font-size: 14px;
  }

  .p2p-bottom-buy-sell-tabs-content-title {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: #000000;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 500;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .p2p-bottom-buy-sell-tabs {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    width: 100%;
  }

  .p2p-bottom-section-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .p2p-bottom-section-heading {
    padding-left: 0;
    padding-right: 0;
    font-size: 30px;
    line-height: 40px;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -ms-flexbox;
    display: flex;
    max-width: 1200px;
    /* color: #1e2329; */
    font-weight: 550;
  }

  .enter-amount-section .btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: #02b3b4;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section .btn:hover {
    color: #e0ad06;
  }

  .enter-amount-text-section {
    border-right: 1px solid #eaecef;
  }

  .enter-amount-text-1 {
    font-size: 15px;
    color: #aeb4bc;
  }

  .enter-amount-section .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section {
    border: 1px solid #f0bb0b50;
    padding: 0.175rem 0rem;
    border-radius: 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #cccdcf !important;
    font-size: 14px;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  input:focus {
    box-shadow: none !important;
  }

  .enter-amount-heading {
    font-size: 11px;
    color: #ffffff;
  }

  .enter-amount-text-2 {
    font-size: 11px;
    color: #76808f;
    margin-left: -10px;
  }

  .f-dropdown {
    --max-scroll: 3;
    position: relative;
    z-index: 10;
  }

  .f-dropdown select {
    display: none;
  }

  .f-dropdown>span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #f0bb0b50 !important;
    background: #f5f5f5;
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .f-dropdown>span>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .f-dropdown>span img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown>span:before,
  .f-dropdown>span:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 1px;
    top: 50%;
    right: 12px;
    background: #000;
    transition: all 0.3s ease;
  }

  .f-dropdown>span:before {
    margin-right: 4px;
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown>span:after {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);
  }

  .f-dropdown ul li {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .f-dropdown ul li a {
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }

  .f-dropdown ul li a img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown ul li a:hover {
    color: #000 !important;
  }

  .f-dropdown ul li.active a {
    color: #000;
    background: gray;
  }

  .f-dropdown ul li.active a:before,
  .f-dropdown ul li.active a:after {
    --scale: 0.6;
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    position: absolute;
    right: 12px;
    top: 50%;
    opacity: 0;
    background: #fff;
    transition: all 0.2s ease;
  }

  .f-dropdown ul li.active a:before {
    transform: rotate(45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:after {
    transform: rotate(-45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:hover:before,
  .f-dropdown ul li.active a:hover:after {
    --scale: 0.9;
    opacity: 1;
  }

  .f-dropdown ul li:first-child a {
    border-radius: 3px 3px 0 0;
  }

  .f-dropdown ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  .f-dropdown.disabled {
    opacity: 0.7;
  }

  .f-dropdown.disabled>span {
    cursor: not-allowed;
  }

  .f-dropdown.filled>span {
    color: #000;
  }

  .f-dropdown.open {
    z-index: 15;
  }

  .f-dropdown.open>span {
    border-color: #aaa;
  }

  .f-dropdown.open>span:before,
  .f-dropdown.open>span:after {
    background: #000;
  }

  .f-dropdown.open>span:before {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown.open>span:after {
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown.open ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 12px);
    transition: opacity 0.3s ease, visibility 0.3s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  }

  .f-group {
    max-width: 250px;
    margin: 0 auto;
    text-align: left;
  }

  .f-group select {
    width: 100%;
  }

  .f-control {
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    border: #ccc 1px solid;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  .nav-tabs-buy-sell-section {
    border: 1px solid #e6e8ea;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell-section .nav-tabs .nav-link:hover {
    border-color: #e9ecef00 #e9ecef00 #dee2e600;
    isolation: isolate;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .green-tab-active {
    background-color: #02b3b4 !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .red-tab-active {
    background-color: rgb(203, 13, 13) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs-buy-sell .nav-tabs {
    border: 0px solid #0582ff !important;
  }

  .nav-tabs-buy-sell .nav-link {
    display: block;
    padding: 0.3rem 0rem !important;
    color: inherit !important;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-pills-buy-sell .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
  }

  .nav-pills-buy-sell .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: #f0b90b !important;
    border-bottom: 2px solid #f0b90b;
  }

  .nav-pills-buy-sell .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: inherit !important;
    text-decoration: none;
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .hero-top-section-text-1 {
    font-size: 22px;
  }

  .advantages-p2p-section-text-1 {
    font-size: 22px;
    font-weight: 400;
  }

  .hero-top-section-text-2 {
    font-size: 10px;
    width: 100%;
    text-align: center;
    margin: auto;
    font-weight: 200;
    color: rgb(214, 214, 214) !important;
  }

  .advantages-p2p-section-text-2 {
    font-size: 15px;
    font-weight: 200;
    color: rgb(37, 37, 37) !important;
  }

  /* / ==============================Harish-css-start===================================== / */
  .terms-text-3 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 4px;
  }

  .terms-text-2 {
    font-size: 15px;
    font-weight: 600;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .terms-text-1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .accordian-dot-icons {
    font-size: 20px;
    margin-right: 8px;
  }

  .faq-second-section .accordion-body {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    background: transparent;
    color: white;
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    color: white;
  }

  .faq-second-section .accordion-button {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    box-shadow: none !important;
    width: 100%;
    padding: 1rem 1.25rem;
    color: inherit !important;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    background-color: transparent;
    color: white;
  }

  .faq-second-section .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
    color: white;
  }

  .market-place-table-section {
    width: 100% !important;
    overflow: scroll;
  }

  .market-trade-button {
    border: 1px solid #02b3b4;
    background-color: transparent;
    color: #02b3b4;
    font-size: 12px;
    padding: 4px 30px;
    border-radius: 4px;
  }

  .market-trade-button:hover {
    border: 1px solid #02b3b4;
    background-color: #02b3b4;
    color: #fff;
    font-size: 12px;
    padding: 4px 30px;
  }

  .markets-second-section tbody {
    border: none !important;
  }

  .markets-second-section table {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
  }

  .markets-second-section .table> :not(caption)>*>* {
    padding: 1rem 0.5rem;
  }

  .markets-second-section table tr {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    border-bottom: 0.065rem solid #dee2e6 !important;
  }

  .markets-second-section table tbody tr:last-child {
    border-bottom: 0 solid #dee2e6 !important;
  }

  .markets-second-section table tbody tr:hover {
    background-color: #f5f8fb;
  }

  .markets-second-section table th {
    background-color: #02b3b4 !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
  }

  .markets-second-section table th:first-child {
    border-top-left-radius: 12px;
  }

  .markets-second-section table th:last-child {
    border-top-right-radius: 12px;
  }

  .markets-second-section table td {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .transaction-history-select-section .transaction-form-control {
    display: block;
    width: 100%;
    height: 35px;
    padding-left: 8px;
    font-size: 12px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(112, 122, 138, 0.336);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .custom-dropdown-trans-section .select-dropdown-btn {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.8rem;
    color: inherit !important;
    background-color: transparent;
    width: 120px !important;
    height: 35px;
    border: 1px solid rgba(112, 122, 138, 0.336) !important;
    border-radius: 4px;
  }

  .custom-dropdown-trans-section .select-dropdown-btn:hover {
    font-size: 0.8rem;
    color: inherit !important;
    background-color: transparent;
    border: 1px solid rgb(112, 122, 138) !important;
  }


  .transaction-text-1 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 12px;
  }

  .transaction-history-tabs-section .nav-pills .nav-link.active,
  .transaction-history-tabs-section .nav-pills .show>.nav-link {
    color: #fff !important;
    background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
  }

  .transaction-history-tabs-section .nav-link {
    display: block;
    padding: 0.2rem 0.5rem;
    color: rgb(112, 122, 138);
    text-decoration: none;
    font-size: 12px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trading-page-order-table-details-section tbody {
    border: none !important;
  }

  .trading-page-order-table-details-section .table> :not(caption)>*>* {
    padding: 0.15rem 0.1rem;
  }

  .trading-page-order-table-details-section tr {
    border: 0px !important;
    padding: 5px;
  }

  .trading-page-order-table-details-section tbody td {
    font-weight: 500;
    align-items: center;
    font-size: 10px !important;
    color: white;
    width: 150px;
    background-color: transparent;
  }

  .trading-page-order-table-details-section th {
    font-weight: 500;
    align-items: center;
    font-size: 10px;
    width: 150px !important;
    color: white;
    background-color: transparent !important;
    border: 0px;
    padding: 4px 8px;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .trading-page-order-table-details-section-support tr {
    border: 0px !important;
    padding: 15px !important;
  }

  .trading-page-order-table-details-section-support tbody td {
    font-weight: 500;
    align-items: center;
    font-size: 10px !important;
    color: white;
    width: 100% !important;
    background-color: transparent;
  }

  .trading-page-order-table-details-section-support th {
    font-weight: 500;
    align-items: center;
    font-size: 10px;
    width: 100% !important;
    color: white;
    background-color: transparent !important;
    border: 0px;
    padding: 4px 8px;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .trading-page-order-table-details-section .select-dropdown-btn {
    font-size: 10px;
  }

  .trading-page-order-table-details-section .dropdown-item {
    font-size: 13px !important;
  }

  .trading-page-order-table-details-section .table thead {
    display: none;
  }

  .trading-page-order-table-details-section .table,
  .trading-page-order-table-details-section .table tbody,
  .trading-page-order-table-details-section .table tr,
  .trading-page-order-table-details-section .table td {
    display: block;
    width: 100%;
  }

  .trading-page-order-table-details-section .table tr {
    margin-bottom: 15px;
  }

  .trading-page-order-table-details-section .table td {
    padding-left: 50%;
    text-align: right;
    position: relative;
  }

  .trading-page-order-table-details-section .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    text-align: left;
  }

  .withdraw-crypto-network-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-network-modal .modal-header {
    border: none;
  }

  .withdraw-modal-coin-muted-text-1 {
    color: rgb(71, 77, 87);
  }

  .withdraw-modal-coin-muted-text {
    font-weight: 300;
    font-size: 14px;
    color: rgb(112, 122, 138);
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .withdraw-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 20px 10px !important;
    padding: 5px;
    border-radius: 4px;
  }

  .withdraw-modal-network-sizing:hover {
    background-color: #eee;
  }

  .withdraw-nav-tabs ::placeholder {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .withdraw-nav-tabs .form-control {
    width: 100%;
    padding: 0.375rem 1.2rem !important;
    font-size: 0.9rem;
    height: 48px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 1px solid #dee2e6;
  }

  .withdraw-crypto-modal-text-4 {
    font-size: 14px;
    font-weight: 200;
    margin-left: 15px;
  }

  .withdraw-crypto-modal-text-3 {
    font-size: 14px;
    font-weight: 200;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .withdraw-crypto-list-items {
    padding: 10px 20px;
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .withdraw-crypto-list-items:hover {
    background-color: #eee;
  }

  .withdraw-crypto-modal-text-2 {
    font-size: 14px;
    font-weight: 200;
    position: relative;
    top: -4px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .withdraw-crypto-modal-text-1 {
    font-size: 18px;
    position: relative;
    top: 4px;
  }

  .withdraw-crypto-modal-images {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .withdraw-crypto-modal-list {
    list-style: none;
    padding: 0px;
  }

  .withdraw-crypto-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-modal .modal-header {
    border: none;
  }

  .select-network-text-1 {
    font-size: 14px;
  }

  .modal-2-overflow-scroll {
    overflow: hidden auto !important;
    height: 432px;
  }

  .deposit-modal-list-sizing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  .deposit-modal-row-sizing {
    margin: 3px 9px 3px 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-coin-heading {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    color: white;
  }

  .deposit-modal-coin-muted-text {
    color: white;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .deposit-modal-coin-images {
    width: 25px !important;
    height: 25px !important;
  }

  #myUL2 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #myUL2 li a {
    margin-bottom: 1px;
    background-color: transparent;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    text-decoration: none;
    color: black;
    display: block;
  }

  #myUL2 li a:hover:not(.header) {
    background-color: rgba(128, 128, 128, 0.794);
  }

  #myInput2 {
    margin: 0 auto;
    width: 100%;
    height: 45px;
    padding: 2px 20px;
    font-size: 1rem;
    border-radius: 15px;
    outline: none;
    margin-bottom: 15px;
    color: white;
  }

  .deposit-crypto-modal .modal-content {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
    box-shadow: rgb(20 21 26 / 16%) 0px 8px 16px,
      rgb(71 77 87 / 16%) 0px 16px 32px, rgb(20 21 26 / 10%) 0px 0px 1px;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    animation: 0.3s ease-in-out 0s 1 normal none running animation-1wqz9z0;
  }

  .deposit-crypto-modal .modal-header {
    border-bottom: none;
  }

  .wallet-info-icon-1 {
    font-size: 12px;
    margin-left: 4px;
  }

  .qr-code-tooltip-image {
    width: 100%;
  }

  .tooltip-text-3 {
    font-size: 15px;
    font-weight: 700;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .tooltip-text-2 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    margin-bottom: 0px;
  }

  .tooltip-text-1 {
    font-size: 11px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .tooltip-text-Theme {
    padding-bottom: 10px !important;
    width: 250px;
    color: #fff !important;
    background-color: rgb(0, 0, 0) !important;
  }

  .tooltip-text-Theme::after {
    background-color: rgb(0, 0, 0) !important;
  }

  .no-record-image {
    width: 120px;
  }

  .no-records-found td {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
  }

  .table-view-all-deposit-button-1,
  .table-view-all-deposit-button-2 {
    background-color: transparent;
    color: #02b3b4;
    text-decoration: underline;
    border: none;
    font-size: 12px;
  }

  .dest-addres-link {
    margin-left: 4px;
    font-size: 12px;
  }

  .dest-addres-ellipsis {
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .deposit-fourth-section .table> :not(caption)>*>* {
    padding: 0.55rem 0.55rem;
  }

  .deposit-fourth-section .table> :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .deposit-fourth-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
  }

  .deposit-fourth-section th:first-child {
    border-top-left-radius: 4px;
  }

  .deposit-fourth-section th:last-child {
    border-top-right-radius: 4px;
  }

  .deposit-fourth-section tr {
    font-weight: 500;
    align-items: center;
    font-size: 10px;
    padding: 20px 10px;
  }

  .deposit-fourth-section .table thead {
    display: none;
  }

  .deposit-fourth-section .table,
  .deposit-fourth-section .table tbody,
  .deposit-fourth-section .table tr,
  .deposit-fourth-section .table td {
    display: block;
    width: 100%;
  }

  .deposit-fourth-section .table td {
    text-align: right;
    position: relative;
    border-bottom: 1px solid rgb(234, 236, 239);
  }

  .deposit-fourth-section .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    text-align: left;
  }

  .deposit-fourth-section .table .no-records-found td {
    text-align: center;
    position: relative;
  }

  .deposit-text-11 {
    font-size: 20px;
  }

  .deposit-text-9 {
    font-weight: 800;
  }

  .deposit-text-8 {
    text-decoration: underline;
  }

  .deposit-text-7 {
    color: #02b3b4;
  }

  .deposit-icon-1 {
    margin-right: 10px;
    font-size: 22px;
    color: rgb(112, 122, 138);
  }

  .deposit-text-6 {
    font-size: 12px;
  }

  .deposit-text-66 {
    font-size: 13px;
  }

  .deposit-select-coin-section {
    width: 100%;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(234, 236, 239);
    cursor: pointer;
  }

  .deposit-select-coin-section:hover {
    border-color: #02b3b4;
  }

  .deposit-text-5 {
    font-size: 13px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-text-4 {
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }

  .deposit-logo-image {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .deposit-third-right-section {
    width: 100%;
  }

  /* .deposit-third-left-section {
    width: 250px !important;
  } */

  .deposit-third-section {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 700px;
  }

  .deposit-text-3 {
    font-weight: 300;
    font-size: 13px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-text-33 {
    font-weight: 300;
    font-size: 13px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .deposit-crupto-explain-image {
    display: none;
  }

  .deposit-text-2 {
    font-weight: 300;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .crypto-workflow-section-close-button {
    font-size: 24px;
    cursor: pointer;
  }

  .deposit-second-section {
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 100%;
  }

  .base-timeline__item {
    position: relative;
    /* display: inline-block; */
    /* width: calc(100% / 4 - 5px); */
  }

  .base-timeline__item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    content: counter(number) "";
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #02b3b4;
    color: white;
    font-weight: bold;
    transition: all 0.6s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .base-timeline__item--data::before {
    width: 30px;
    height: 30px;
  }

  .deposit-fiat-button {
    background-color: rgb(242, 243, 245);
    padding: 6px 10px;
    border: none;
    font-size: 12px;
  }

  .deposit-fiat-button:hover {
    background-color: transparent;
  }

  .deposit-text-1 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .deposit-back-button-icon {
    font-size: 28px;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }

  .deposit-back-button-icon:hover {
    background-color: rgb(242, 243, 245);
  }

  .deposit-hero-section {
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .deposit-page-top-banner {
    margin-top: 65px;
  } */

  .trading-page-order-tabs-section {
    height: 120px;
    margin: auto;
    text-align: center;
  }

  .trade-bottom-login-text-1 {
    color: gray;
    font-size: 14px;
  }

  .activities-button-1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 13px;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    border: none !important;
    color: #fff;
    width: 40px;
  }

  .activities-text-4 {
    position: relative;
    top: -4px;
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
    margin: 0px;
  }

  .activities-text-3 {
    color: rgb(112, 122, 138);
  }

  .activities-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .activities-text-1 {
    font-size: 14px;
  }

  .trading-table-right-activities-section {
    margin-top: 5px;
    border-top: 1px solid rgb(238, 240, 242);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .trading-table-right-bottom-section {
    height: 290px !important;
    overflow-y: scroll;
  }

  .paired-trade-table-section {
    /* margin-left: 10px !important;
    margin-right: 10px !important; */
    /* padding-left: 10px; */
    padding-bottom: 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .paired-trade-table-section .nav-pills .nav-link.active,
  .paired-trade-table-section .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid #02b3b4;
  }

  .paired-trade-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    /* color: #727581 !important; */
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 10px;
    border-radius: 0px;
    padding-bottom: 8px;
  }

  .trading-table-right-inner-section {
    height: 310px !important;
    overflow-y: scroll;
  }

  .paired-text-3 {
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    margin-left: 4px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(254, 246, 216);
  }

  .pared-fav-button {
    color: rgb(112, 122, 138);
    margin-bottom: 4px;
    margin-right: 1px;
  }

  .trading-table-right-section .flex-row {
    height: 22px;
  }

  .trading-table-right-section {
    /* width: 95%; */
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin: auto;
  }

  .paired-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .paired-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .mobile-justify-content {
    justify-content: space-around;
  }

  /* .paired-table-1 {
    width: 20%;
  } */

  .paired-table-2 {
    /* width: 20%; */
    text-align: end;
  }

  .paired-table-3 {
    /* width: 20%; */
    text-align: end;
  }

  .paired-price-table-section {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .paired-price-table-section .nav-pills .nav-link.active,
  .paired-price-table-section .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-price-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: rgb(112, 122, 138) !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 6px;
  }

  /* .trading-page-right-section {
    width: 260px !important;
  } */

  .trade-search-section {
    width: 90%;
    margin: auto;
    border-radius: 2px;
    line-height: 1.5;
    background-color: rgb(242, 243, 245);
  }

  .trade-search-btn {
    border: none;
    color: rgb(112, 122, 138);
    background: transparent;
  }

  .trade-search-section .form-control {
    padding: 0.215rem 0.15rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .trade-search-section .form-control:focus {
    background-color: transparent;
  }

  .progress-bar {
    position: absolute;
    right: 0px;
    z-index: 1;
    height: 20px;
  }

  .ask-bar {
    background-color: rgba(255, 0, 0, 0.158);
  }

  .bid-bar {
    background-color: rgba(255, 0, 0, 0.07);
  }

  .progress-bar-bg-color {
    position: relative;
  }

  .progress-bar-bg-color .flex-row {
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center !important;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-size: 12px !important;
    font-weight: 500;
    letter-spacing: 0.26px;
    /* color: white !important; */
  }

  .progress-table-1 {
    width: 33%;
  }

  .progress-table-2 {
    width: 33%;
    text-align: center;
    color: white !important;
  }

  .progress-table-3 {
    width: 33%;
    text-align: end;
    color: white;
  }

  .orderbook-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: white;
    padding-bottom: 0rem !important;
  }

  .orderbook-text-4 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.26px;
  }

  .trailing-delta-btn {
    background-color: transparent;
    font-size: 0.8rem;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
  }

  .custom-dropdown,
  .dropdown-menu {
    box-shadow: none !important;
  }

  .custom-dropdown .dropdown-item {
    color: inherit;
    font-size: 14px;
  }

  .select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(112, 122, 138);
    background-color: transparent;
  }

  .select-dropdown-btn:hover {
    border: none !important;
  }

  .limit-market-section-bottom .select-amount-button {
    background-color: transparent;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
    font-size: 0.9rem;
  }

  .limit-market-section-bottom .dropdown-item {
    padding: 0.5rem 1rem;
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-item:hover {
    background-color: rgb(234, 236, 239);
  }

  .limit-market-section-bottom .dropdown-menu {
    border-radius: 0rem !important;
    padding: 0px !important;
    left: -20px !important;
  }

  .trade-text-15 {
    color: #02b3b4;
  }

  .trade-buy-sell-button-1 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(230, 232, 234);
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .trade-buy-sell-button-11 {
    padding: 0.665rem 0.465rem;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);

    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .trade-buy-sell-button-111 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .total-value-price-section {
    /* position: relative;
    top: -30px; */
    font-size: 11px;
  }

  .percentage-line-section {
    height: 5px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }

  .percentage-display-section {
    background-color: #02b3b4 !important;
    height: 32px;
    width: 32px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #aaa;
    outline: none !important;
  }

  .trade-text-14 {
    font-size: 0.8rem;
  }

  .trade-text-13 {
    font-size: 0.8rem;
    color: rgb(112, 122, 138);
  }

  /* ::placeholder {
    color: #212529 !important;
  } */

  /* input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */

  .limit-market-section-bottom .form-control {
    display: block;
    width: 100%;
    padding: 0rem 0.75rem;
    font-size: 0.8rem;
    float: right;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    text-align: right;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .limit-button-width {
    width: 40px !important;
    text-align: center;

    margin-left: 6px;
  }

  .limit-price-form {
    align-items: center;
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 0.365rem 0.465rem;
    background-color: rgba(230, 232, 234, 0.6);
  }

  .limit-price-form:hover {
    border: 1px solid #02b3b4;
  }

  .limit-price-form:focus {
    border: 1px solid #02b3b4 !important;
  }

  .trade-text-12 {
    font-size: 12px;
    color: rgb(0, 0, 0);
  }

  .trade-text-11 {
    font-size: 12px;
    color: rgb(112, 122, 138);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active {
    border-top: 2px solid #02b3b4 !important;
    border-bottom: 0px solid transparent;
    color: rgb(30, 35, 41);
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active:hover {
    border-top: 2px solid #02b3b4;
    border-bottom: 0px solid transparent;
    border-left: 1px solid rgb(238, 240, 242);
    border-right: 1px solid rgb(238, 240, 242);
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: rgba(112, 122, 138, 0.815);
    letter-spacing: 0.4px;
    /* width: 130px; */
    font-size: 13px;
    padding: 10px 20px;
    font-weight: 500;
  }

  .limit-market-section-bottom .nav-link:hover {
    border-color: transparent;
    outline: none;
  }

  .trading-table-left-section {
    height: 250px !important;
    overflow: hidden;
  }

  .trading-table-left-recent-trade-height {
    height: 340px !important;
    overflow: hidden;
  }

  /* .list-hover-tooltip:hover {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(173, 173, 173, 0.205);
  } */

  /* #tradingviewwidget {
    width: 100% !important;
    height: 400px !important;
  }

  /* .trading-inner-right-section {
    width: 180px;
  } */

  .trading-inner-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 260px !important; */
  }

  .trading-page-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 940px !important; */
    overflow: hidden;
  }

  .trade-text-10 {
    font-size: 12px;
    font-weight: 100;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trade-icon-2 {
    font-size: 14px;
    position: relative;
    top: -1px;
  }

  .trade-text-9 {
    font-size: 16px;
    margin-right: 4px;
  }

  .hide-content-only {
    visibility: hidden;
  }

  .trading-page-top-section-3 .table> :not(caption)>*>* {
    padding: 0rem 0rem;
  }

  .trading-page-top-section-3 th {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .trading-page-top-section-3 tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
  }

  .trading-page-top-section-3 th:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 th:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-2 .form-check {
    min-height: 0rem;
    margin-bottom: 0rem;
  }

  .trading-page-top-section-2 .dropdown-item {
    padding: 0rem 1rem;
  }

  .trading-page-top-section-2 .dropdown-menu {
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 8%) 0px 7px 14px,
      rgb(24 26 32 / 8%) 0px 3px 6px;
    border-radius: 4px;
    transform: translate(-10rem, 28px) !important;
    font-size: 11px;
  }

  .trading-page-top-section-2 .dropdown-toggle::after {
    margin-left: 0em;
    content: none;
  }

  .trading-page-top-section-2 {
    padding: 6px 0px;
  }

  .average-icon-display-1 {
    background-color: transparent;
    color: rgb(198, 202, 208);
    border: none;
  }

  .trading-page-top-section-2 .form-select {
    display: block;
    width: 100%;
    padding: 0.075rem 1.05rem 0.075rem 0.25rem;
    background-position: right 0.25rem center;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .trading-page-top-section-2 .form-select option {
    font-size: 0.875rem;
  }

  .trading-page-top-section-2 .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.675rem;
    border-radius: 0.2rem;
    text-align: center;
  }

  .trading-page-top-section-1 {
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    /* width: 920px; */
  }

  .order-icon-3 {
    width: 16px;
    height: 16px;
  }

  .order-icon-2 {
    width: 16px;
    height: 16px;
  }

  .order-icon-1 {
    width: 16px;
    height: 16px;
  }

  .trading-page-section-5 {
    margin-right: 20px;
  }

  .trading-page-section-4 {
    padding-right: 15px;
  }

  .trade-text-8 {
    font-size: 11px;
    color: rgb(112, 122, 138);
    margin-bottom: 0px;
  }

  .trading-page-section-3 {
    padding-right: 15px;
    margin-right: 10px;
  }

  .trade-text-7 {
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    white-space: nowrap;
    letter-spacing: 0.24px;
    margin-bottom: 0px;
    color: rgb(243, 243, 243) !important;
  }

  .trade-text-6 {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 6px !important;
    letter-spacing: 0.2px;
  }

  .trading-page-section-2 {
    border-right: 1px solid rgb(238, 240, 242);
    padding-right: 15px;
    margin-right: 10px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    text-align: start;
  }

  .trade-text-5 {
    font-size: 15px;
    color: white !important;
    font-weight: 1000;
    margin-bottom: 0px;
    letter-spacing: 0.045em;
  }

  .trade-text-4 {
    font-size: 11px;
    margin-bottom: 2px;
  }

  .trading-page-section-1 {
    border-right: 1px solid #dbdde6;
    padding-right: 15px;
    margin-right: 15px;
  }

  .trading-page-top-section-1 {
    /* margin: 10px 0px; */
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
  }

  .trade-text-3 {
    margin-left: 6px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 9px;
    border-radius: 2px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(249, 244, 226);
    position: relative;
    top: -2px;
  }

  .trade-icon-1 {
    font-size: 11px;
    margin-right: 2px;
  }

  .trade-text-2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);
    text-decoration: underline;
    font-size: 9px;
  }

  .trade-text-1 {
    font-size: 0.86rem;
    font-weight: bolder;
    margin-bottom: 3px;
    margin-bottom: 3px;
  }

  .trading-page-box {
    /* border: 5px solid #f5f8fb; */
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    /* width: 1200px; */
  }

  .orderbook-grid-tabs .nav-link {
    display: block;
    padding: 0.2rem 0.3rem;
    padding-top: 0px !important;
    margin-left: 2px;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    border: 1px solid transparent;
  }

  .orderbook-grid-tabs .nav-pills .nav-link.active,
  .orderbook-grid-tabs .nav-pills .show>.nav-link {
    border: 1px solid rgb(2, 179, 180);
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    background-color: transparent;
  }

  .orderbook-table-heading-section {
    border-bottom: 1px solid #dbdde6;
    border-top: 1px solid #dbdde6;
    height: 30px !important;
    vertical-align: middle !important;
    align-items: center !important;
  }

  .spot-page-top-banner {
    padding-top: 65px;
    padding-bottom: 100px;
  }

  .img-fluid-size {
    height: 35px;
    width: 35px;
  }

  .text-spot {
    font-size: 11px !important;
    color: white !important;
  }

  .place-order-buy-button {
    color: #fff;
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .place-order-login-button {
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid #dbdde6;
    background-color: transparent;
  }

  .place-order-buy-sell-percentage-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-percentage-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #02b3b4;
    border: 1px solid #02b3b4;
  }

  .place-order-buy-sell-percentage-tabs .nav-link {
    display: block;
    padding: 0.375rem 0.75rem;
    color: #727581;
    text-decoration: none;
    font-size: 13px !important;
    border: 1px solid #dbdde6 !important;
    width: 90% !important;
  }

  .place-order-section-top .form-control {
    /* padding: 1rem 0.75rem; */
    font-size: 0.9rem;
    font-weight: 400;
    background-color: rgba(243, 243, 243, 0.04) !important;
    color: rgb(243, 243, 243) !important;
    border: 0px !important;
    /* border-right: 0px; */
  }

  .place-order-section-top .input-group-text {
    color: #FFF;
    font-size: 10px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 400;
    background: #535151 !important;
    border: 0px !important;
  }

  .select-dropdown-btn-buysell {
    border: 1px solid #dbdde6;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 35px;
    display: flex;
    padding: 0px 16px;
    font-size: 14px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    align-items: center;
    font-weight: 500;
    line-height: 21px;
    border-radius: 2px;
    justify-content: space-between;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #14b269;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link-sell.active,
  .place-order-buy-sell-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #ff4b5e;
  }

  .place-order-buy-sell-tabs .nav-pills {
    border: 1px solid #dbdde6;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.055rem;
    border-radius: 2px;
    display: block;
    padding: 0.5rem 1rem;
    color: #727581;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .fixed-top-padding-top {
    padding-top: 150px;
  }

  .footer-bottom-section {
    background: #1f014e;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .footer-social-icons a {
    margin-right: 10px;
    color: #fff;
    padding: 8px;
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
    background-color: #3a3150;
    padding-bottom: 11px;
    padding-top: 6px;
    position: relative;
    font-size: 16px !important;
    background: rgb(37, 23, 74);
  }

  .footer-section a {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .footer-section h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    color: rgb(114, 117, 129);
    letter-spacing: 0.5px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .footer-section {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #3e1084;
    background-image: url("../assets/images/footer-bg-wave.png");
  }

  .ready-text-2 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .ready-text-1 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-2 {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    color: inherit;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-1 {
    color: #242b3a;
    font-size: 22px;
    font-weight: 700;
  }

  .benefits-icons-1 {
    margin-right: 20px;
    border-radius: 3px;
    padding: 8px;
    width: 50px;
    color: #ffffff;
  }

  .top-banner-eight-section .card {
    height: 100%;

    background-color: transparent;
    border: 1px solid #6101b5;
    border-radius: 5px;
    transition: 1.5s;
  }

  .staking-image-banner {
    width: 60%;
    float: right;
  }

  .app-play-button {
    width: 167px;
    height: 60px;
  }

  .top-inner-fifth-bg-image {
    background-image: url("./images/glow1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .banner-image-bg {
    background-image: url("./images/glow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .top-inner-fifth-section {
    background: linear-gradient(180deg, #7022c8 0%, #7022c8 100%);
    color: #fff;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 40px;
    border-radius: 6px;
  }

  .top-banner-seventh-section {
    background: linear-gradient(180deg, #7022c8 4.26%, #7022c8 84.1%);
    color: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 89%);
  }

  .top-banner-fourth-section {
    background: linear-gradient(180deg, #7022c8 0%, #7022c8 100%);
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .inner-third-section {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 40px;
  }

  .view-more-icon {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  .table-view-all-button-1,
  .table-view-all-button-2 {
    background-color: transparent;
    color: #02b3b4;
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .top-banner-third-section table tbody tr:nth-child(-n + 6) {
    display: table-row;
    width: 100%;
  }

  .top-banner-third-section table tbody tr {
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr:hover {
    box-shadow: 0px 4px 8px rgb(220 226 238 / 40%);
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr {
    display: none;
  }

  .table-view-all-button-2 {
    display: none;
  }

  .top-banner-third-section .table {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  /* .table tbody tr:nth-child(1) {
    display: none;
  } */
  .top-banner-third-section .table thead tr th {
    color: #727581;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
  }

  .top-banner-third-section .table tbody td {
    color: #242b3a;
    padding: 7px 8px !important;
    font-size: 11px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.32px;
  }

  .top-banner-third-section .table tbody img {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }

  .top-banner-third-section .table thead {
    border-bottom: 1px solid #edeef2;
  }

  .top-banner-third-section .table thead th:last-child {
    text-align: end;
  }

  .top-banner-third-section .table tbody td:last-child {
    text-align: end;
    padding-right: 10px !important;
  }

  .top-banner-third-section .table thead th {
    width: 25%;
  }

  .top-banner-third-section .table tbody {
    border: 2px solid #edeef2;
  }

  .top-banner-third-section .table> :not(:first-child) {
    border-top: 0px;
  }

  .top-banner-third-section .nav-link {
    color: #727581;
    opacity: 1;
    padding: 0px 10px;
    font-size: 12px;
    min-width: 70px;
    min-height: 25px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .top-banner-third-section .nav-pills .nav-link.active,
  .top-banner-third-section .nav-pills .show>.nav-link {
    color: rgb(255, 255, 255) !important;
    background-color: #02b3b4;
    /* background: linear-gradient(90deg, #27c69eda 0%, #8d60dbe5 100%); */
  }

  .market-trade-tabs {
    border-radius: 2px;
    background-color: rgb(200 205 223 / 16%);
    width: fit-content;
  }

  .banner-image-1 {
    width: 6px;
    position: relative;
    top: -4px;
    left: -12px;
  }

  .banner-text-7 {
    font-size: 1rem;
    font-weight: 100;
    color: #727581;
    letter-spacing: 1px;
  }

  .banner-text-6 {
    font-size: 1.4rem;
    font-weight: 100;
    letter-spacing: 2px;
  }

  .banner-text-5 {
    font-size: 1.1rem;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .banner-text-4 {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .banner-text-3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px !important;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet-active {
    background: #02b3b4 !important;
  }

  .swiper-pagination-bullet {
    width: 25px !important;
    border-radius: 12px !important;
    height: 6px !important;
  }

  .main-banner-background-styling {
    background: linear-gradient(160deg, #27187b, #16014e);
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 94%);
    padding: 10vh;
    margin-bottom: 5vh;
  }

  .banner-top-icons-1 {
    margin-left: 6px;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .banner-top-button-4 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 15px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    background: transparent;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #93e0ce, #8e5fdc);
    border-image-slice: 1;
    color: #6101b5 !important;
  }

  .banner-top-button-3 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .banner-top-button-2 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .banner-top-button-1 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    border-radius: 52px;
    font-weight: 500;
    font-size: 12px;
    background: linear-gradient(89.71deg, #27c79e 3.23%, #935adf 102.4%);
  }

  .banner-text-2 {
    font-size: 0.75rem;
    font-weight: 100;
    width: 65%;
    letter-spacing: 1px;
  }

  .banner-text-1 {
    font-size: 2.6rem;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .top-banner-hero-section {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  #classy-navbar-mobile {
    background-color: #11003b;
  }

  .nav-register-button {
    color: #ffffff !important;
    width: 150px;

    border-radius: 2px;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
  }

  #classy-navbar-mobile .nav-link {
    box-shadow: none !important;
    font-size: 14px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    letter-spacing: 0.08em;
  }

  .navbar-nav li:hover>ul.dropdown-menu {
    display: block;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }

  .dropdown-submenu2>.dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -6px;
  }

  .dropdown-menu a {
    font-size: 0.9rem !important;
  }

  .footer-social-desktop {
    display: none;
  }

  #classy-navbar-mobile .dropdown-menu {
    min-width: 22rem;
    padding: 10px 10px;
  }

  .top-nav-dropdown-item-icons-1 {
    font-size: 18px;
    margin-right: 20px;
  }

  .top-nav-dropdown-item-icons-2 {
    color: transparent;
  }

  .top-nav-dropdown-item-text-1 {
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .top-nav-dropdown-item-text-2 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    position: relative;
    top: -3px;
    color: #707a8a;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .dropdown-item-hover {
    padding: 10px 20px;
  }

  .navbar-top-hover-dropdown .dropdown-item-hover:hover {
    border-radius: 4px;
  }

  .themes-dropdown-text-1 {
    font-size: 18px;
    margin-left: 20px;
  }

  .mode-image-dropdown-1,
  .mode-image-dropdown-2,
  .mode-image-dropdown-3,
  .mode-image-dropdown-4,
  .mode-image-dropdown-5,
  .mode-image-dropdown-6 {
    width: 40px;
    margin-right: 8px;
  }

  .switch-modes-section {
    padding: 10px 18px;
  }

  .switch-modes-section label {
    font-size: 13px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  #classy-navbar-mobile .form-check-input:checked[type="radio"] {
    background-image: none;
  }
}

/* ===================================COMMON-STARTS===================================================== */
.dropdown-menu a {
  display: block;
  font-size: 0.9rem;
}

/* .dropdown-item {
  color: #000000 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: transparent;
  color: #000000 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
  color: #000000 !important;
} */

.up-down {
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes up-down {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.p2p-trade-top-section {
  margin-top: 65px;
}

.no-border {
  border: none !important;
}

.f-28 {
  font-size: 28px !important;
}

.referral-page-top-banner-section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.referral-page-top-banner-section .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.referral-page-top-banner-section .nav-tabs .nav-item.show .nav-link,
.referral-page-top-banner-section .nav-tabs .nav-link.active {
  color: inherit;
  background-color: #02b3b4;
  border-color: #dee2e6 #dee2e6 #fff;
}

@media (max-width: 991px) {
  .faq {
    margin-bottom: 30px;
  }

  .faq .card .card-header .faq-title {
    line-height: 26px;
    margin-top: 10px;
  }
}

.p2p-trade-table {
  border: none !important;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.p2p-trade-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.p2p-trade-table .p2p-trade-table-tr {
  background-color: #fff;
  border-bottom: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}

.dark-new .p2p-trade-table-thead {
  background-color: #02b3b4;
}

.p2p-trade-table .p2p-trade-table-thead .p2p-trade-table-tr:first-child {
  border-bottom: 1px solid #e1e5e9;
}

.p2p-trade-table .p2p-trade-table-th,
.p2p-trade-table .p2p-trade-table-td {
  padding-top: 1em;
  padding-bottom: 1rem;
  color: inherit;
  font-size: 14px;
}

.p2p-trade-table .p2p-trade-table-td:nth-child(4) {
  font-size: 14px;
}

.p2p-trade-table .p2p-trade-table-th {
  font-size: 12px;
  color: #76808f;
  font-weight: 400;
}

.p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice {
  background: #02b3b4 !important;
  color: #ffffff !important;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
}

.p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice-sell {
  background-color: rgba(241, 55, 55);
  color: #fff;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
}

/* 
tbody tr:nth-child(even) {
	background-color: #eee;
}

tbody tr:nth-child(odd) {
	background-color: #fff;
} */

@media screen and (max-width: 600px) {
  .p2p-trade-table {
    border: 0;
  }

  .p2p-trade-table caption {
    font-size: 1.3em;
  }

  .p2p-trade-table .p2p-trade-table-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  .p2p-trade-table .p2p-trade-table-tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }

  .p2p-trade-table .p2p-trade-table-th,
  .p2p-trade-table .p2p-trade-table-td {
    padding: 0.625em;
  }

  .p2p-trade-table .p2p-trade-table-td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }

  .p2p-trade-table .p2p-trade-table-td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }

  .p2p-trade-table .p2p-trade-table-td:last-child {
    border-bottom: 0;
  }

  .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
    font-size: 0.8em;
  }
}

.table-lth-bg {
  color: rgb(201, 148, 0);
  background-color: rgb(254, 246, 216);
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 10px;
}

.table-trade-bg {
  color: rgb(3, 166, 109);
  background-color: rgb(230, 255, 241);
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 10px;
}

.table-data-1 {
  font-size: 14px;
  color: inherit;
}

.table-data-2 {
  font-size: 12px;
  color: inherit;
}

.table-data-3 {
  font-size: 20px;
  color: inherit;
}

.table-data-4 {
  font-size: 11px;
  color: inherit;
}

.table-data-5 {
  font-size: 12px;
  color: inherit;
}

.table-data-6 {
  margin: 0px 4px 0px 0px;
  color: inherit;
  font-size: 11px;
}

.table-data-7 {
  color: rgb(201, 148, 0);
  background-color: rgb(254, 246, 216);
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
}

.table-data-1 img {
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
  border-radius: 50%;
  margin-right: 4px;
}

.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:before {
  bottom: 0.5em;
}

/* / ==================================P2P-TRADE-TABLE-END======================== /
/ ==================================P2P-ORDER-TABLE-START====================== / */

.a-tag-link-styling {
  color: royalblue;
  text-decoration: underline;
  box-shadow: none;
}

.a-tag-link-styling:hover {
  color: royalblue;
  text-decoration: none;
}

.order-p2p-trade-table-coin-icon {
  width: 20px;
  height: 20px;
}

.order-p2p-trade-table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-wrap: break-word;
}

.order-p2p-trade-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.order-p2p-trade-table .order-p2p-trade-tr {
  background-color: transparent;
  padding: 0.35em;
  overflow-wrap: break-word;
}

.order-p2p-trade-table .order-p2p-trade-th,
.order-p2p-trade-table .order-p2p-trade-td {
  text-align: center;
}

.order-p2p-trade-table .order-p2p-trade-td {
  font-size: 12px;
  padding: 2em 2em 2em 0.5em;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.order-number-copy-data-css {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-p2p-trade-table .order-p2p-trade-th {
  font-size: 11px;
  color: #252525;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 1.2em;
}

.order-p2p-trade-table thead {
  background-color: #02b3b4;
}

.order-p2p-trade-table thead th:first-child {
  border-top-left-radius: 2em;
}

.order-p2p-trade-table thead th:last-child {
  border-top-right-radius: 2em;
}

@media screen and (max-width: 600px) {
  .order-p2p-trade-table {
    border: 0;
  }

  .order-p2p-trade-table caption {
    font-size: 1.3em;
  }

  .order-p2p-trade-table .order-p2p-trade-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .order-p2p-trade-table .order-p2p-trade-tr {
    padding: 2px;
  }

  .order-p2p-trade-table .order-p2p-trade-td {
    padding: 1em 1em 1em 0.1em;
  }

  .order-p2p-trade-table .order-p2p-trade-tr {
    border-bottom: 3px solid #ddd;
    display: block;
  }

  .order-p2p-trade-table .order-p2p-trade-td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .order-p2p-trade-table .order-p2p-trade-td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .order-p2p-trade-table .order-p2p-trade-td:last-child {
    border-bottom: 0;
  }
}

/* / ==================================P2P-ORDER-TABLE-END======================== /

/ ==================================P2P-USER-CENTER-BLOCKED-USER-TABLE-START=================================== / */

.p2p-user-center-blocked-user-table table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table caption {
  margin: 0.5em 0 0.75em;
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table tr {
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table th,
.p2p-user-center-blocked-user-table table td {
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table th {
  padding: 13px !important;
  font-size: 12px !important;
  color: inherit;
  font-weight: normal !important;
}

.p2p-user-center-blocked-user-table table td {
  padding: 22px 22px 22px 0 !important;
}

.p2p-user-center-blocked-user-table table th {
  align-items: center !important;
}

@media screen and (max-width: 600px) {
  .p2p-user-center-blocked-user-table table {
    border: 0;
  }

  .p2p-user-center-blocked-user-table table caption {
    font-size: 1.3em;
  }

  .p2p-user-center-blocked-user-table table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .p2p-user-center-blocked-user-table table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .p2p-user-center-blocked-user-table table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right !important;
  }

  .counterparty-text-styling {
    text-align: right !important;
    justify-content: right !important;
  }

  .p2p-user-center-blocked-user-table table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;

    text-transform: uppercase;
  }

  .p2p-user-center-blocked-user-table table td:last-child {
    border-bottom: 0;
  }
}

/* ===================================COMMON-ENDS===================================================== */

/* ===================================LIGHT-CSS-STARTS===================================================== */

/* width */




.light-new ::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

/* Track */
.light-new ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.light-new ::-webkit-scrollbar-thumb {
  background: #02b3b4;
  border-radius: 10px;
}

.light-new .form-check-input:checked {
  background-color: #02b3b4 !important;
  border: 3px solid #fff !important;
  outline: 2px solid #02b3b4 !important;
}

.light-new .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #02b3b4;
  background-color: #fff;
}


.light-new .trading-page-top-section-2 .dropdown-menu {
  background-color: rgba(230, 232, 234, 0.6);
  color: #000 !important;
}

.light-new .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.795);
}

/* ===================================LIGHT-CSS-ENDS===================================================== */

/* ===================================Dark-New-CSS-STARTS===================================================== */

.dark-new ::placeholder {
  color: #fff;
}

.dark-new .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.dark-new .trade-text-12 {
  color: #fff;
}

.dark-new .limit-price-form {
  background-color: transparent;
}

.dark-new .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.dark-new .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.dark-new .limit-market-section-bottom .nav-tabs .nav-link.active .select-dropdown-btn {
  color: #fff;
}

.dark-new .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #2c2e37;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418);
}

.dark-new .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trade-search-section {
  background-color: #2c2e37;
}

.dark-new .form-control {
  color: #fff;
  background-color: transparent;
}

.dark-new .trading-page-box {
  border: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .paired-trade-table-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.dark-new .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #ebebeb;
  color: #000;
}

.dark-new .navbar-top-hover-dropdown .dropdown-item-hover:hover .top-nav-dropdown-item-icons-2 {
  color: #02b3b4;
}

/* width */
.dark-new ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dark-new ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.dark-new ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.dark-new .form-check-input:checked {
  background-color: #02b3b4;
  border: 3px solid #14161a !important;
  outline: 2px solid #02b3b4;
}

.dark-new .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #02b3b4;
  background-color: #14161a;
}

.dark-new .dropdown-menu {
  background-color: #14161a;
  color: #fff;
}

.dark-new .trading-page-top-section-2 .dropdown-menu {
  background-color: #2c2e37;
  color: #fff !important;
}

.dark-new .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.dark-new .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.dark-new .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.26);
}

/* Depsit css */
.dark-new .deposit-hero-section {
  background-color: transparent;
}

.dark-new .deposit-back-button-icon:hover {
  background-color: rgba(255, 255, 255, 0.158);
}

.dark-new .deposit-fiat-button {
  background-color: rgba(255, 255, 255, 0.158);
  color: #fff;
}

.dark-new .tooltip-text-Theme {
  color: #000 !important;
  background-color: rgb(255, 255, 255) !important;
}

.dark-new .tooltip-text-Theme::after {
  background-color: rgb(255, 255, 255) !important;
}

.dark-new .deposit-fourth-section th {
  background-color: rgba(255, 255, 255, 0.158);
  color: #fff;
}

.dark-new .deposit-fourth-section tr {
  color: #fff;
}

.dark-new .deposit-fourth-section tbody,
.dark-new .deposit-fourth-section td,
.dark-new .deposit-fourth-section tfoot,
.dark-new .deposit-fourth-section th,
.dark-new .deposit-fourth-section thead,
.dark-new .deposit-fourth-section tr {
  border-color: rgba(255, 255, 255, 0.137);
}

.dark-new .deposit-crypto-modal .modal-content {
  background-color: #14161a;
  box-shadow: none;
}

.dark-new .deposit-modal-coin-heading {
  color: #fff;
}

.dark-new #myUL2 li a:hover:not(.header) {
  background-color: #000;
}

.dark-new .btn-close {
  background-image: url("../assets/images/cross.white.svg");
  opacity: 1;
  box-shadow: none !important;
}

.dark-new .withdraw-crypto-modal .modal-content {
  background-color: #14161a;
  box-shadow: none;
}

.dark-new .withdraw-crypto-network-modal .modal-content {
  background-color: #14161a;
  box-shadow: none;
}

.dark-new .withdraw-crypto-list-items:hover {
  background-color: #000;
}

.dark-new .withdraw-modal-network-sizing:hover {
  background-color: #000;
}

.dark-new .transaction-history-select-section .transaction-form-control {
  color: #fff;
}

.dark-new .markets-second-section table td {
  color: #fff;
}

.dark-new .markets-second-section table tbody tr:hover {
  background-color: #70707050;
}

.dark-new .faq-second-section .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  color: white;
}

.dark-new .dark-new .faq-second-section .accordion-item {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.205);
  color: white;
}

.dark-new .trading-page-box-left-inner-section {
  border-right: 5px solid #000;
}

.dark-new .place-order-section-top {
  border-left: 5px solid #000 !important;
}

.dark-new .trading-page-order-details-section {
  border-top: 5px solid #000;
}

.dark-new .trading-table-total-height {
  border-bottom: 5px solid #000;
}

.dark-new table {
  color: #fff;
}

.dark-new .place-order-buy-sell-tabs .nav-pills {
  border: 1px solid #dbdde6;
  background-color: transparent;
}

.dark-new .select-dropdown-btn-buysell {
  background-color: transparent;
  color: #fff;
}

.dark-new .divider,
.dark-new .divider~li {
  background-color: rgb(34, 36, 40);
}

.form-check-input {
  filter: brightness(90%) !important;
}

/* ===================================Dark-New-CSS-ENDS===================================================== *


/* ===================================dark-black-CSS-STARTS===================================================== */

.dark-black .ask-bar {
  background-color: rgba(255, 0, 0, 0.158);
}

.dark-black .activities-text-3 {
  color: #fff;
}

.dark-black ::placeholder {
  color: #fff;
}

.dark-black .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.dark-black .trade-text-12 {
  color: #fff;
}

.dark-black .limit-price-form {
  background-color: transparent;
}

.dark-black .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.dark-black .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.dark-black .limit-market-section-bottom .nav-tabs .nav-link.active .select-dropdown-btn {
  color: #fff;
}

.dark-black .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #464646;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418);
}

.dark-black .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-black .trade-search-section {
  background-color: #464646;
}

.dark-black .form-control {
  color: #fff;
}

.dark-black .trading-page-box {
  border: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .paired-trade-table-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.dark-black .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #ebebeb;
  color: #000;
}

.dark-black.navbar-top-hover-dropdown .dropdown-item-hover:hover .top-nav-dropdown-item-icons-2 {
  color: #02b3b4;
}

/* width */
.dark-black ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dark-black ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.dark-black ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.dark-black .form-check-input:checked {
  background-color: #fff;
  border: 3px solid #000 !important;
  outline: 2px solid #fff;
}

.dark-black .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #fff;
  background-color: #000000;
}

.dark-black .dropdown-menu {
  background-color: #000000;
  color: #fff !important;
}

.dark-black .trading-page-top-section-2 .dropdown-menu {
  background-color: #464646;
  color: #fff !important;
}

.dark-black .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.dark-black .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.dark-black .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.281);
}

.dark-black .deposit-hero-section {
  background-color: transparent;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dark-black .deposit-back-button-icon:hover {
  background-color: #70707050;
}

.dark-black .markets-second-section table td {
  color: #fff;
}

.dark-black .markets-second-section table tbody tr:hover {
  background-color: #373737;
}

/* ===================================dark-black-CSS-ENDS===================================================== */

/* ===================================MODES-CSS-ENDS===================================================== */

/* .light-new .dark-new-widget {
  display: none !important;
}

.dark-new .light-new-widget {
  display: none !important;
}

.dark-new .dark-new-widget {
  display: block !important;
} */
.btn {
  box-shadow: none !important;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: red !important;
} */
.datatablecss p {
  margin-bottom: 0px;
}

/* body, .chart-controls-bar, #footer-chart-panel {
  background-color: #d20404 !important;
} */
/* .order-book-price-hover-list-section:nth-last-child(2):hover{
  background-color: rgba(36, 43, 58, 0.067);
} */

span.order-released {
  color: green !important;
}

.divider,
.divider~li {
  background-color: rgba(36, 43, 58, 0.067);
}

.divider2 {
  background-color: rgba(36, 43, 58, 0.067);
}

.divider2:hover~li {
  background-color: transparent;
}

.spot-page-top-banner ul {
  list-style: none;
  margin: 0px;
  padding: 0px !important;
}

.terms-second-section p {
  font-size: 16px;
}

.terms-second-section {
  overflow: hidden;
}

.terms-second-section h4 {
  margin-top: 10px;
}

.terms-second-section h2 {
  margin-top: 10px;
  margin-bottom: 30px;
}

.nav-tabs-dashboard .nav-link {
  color: inherit;
  width: 100%;
}

.dashboard-spot-trade-history-section th {
  font-weight: 500;
  align-items: center;
  font-size: 12px;
  color: rgb(112, 122, 138);
  background-color: transparent;
  border: 0px;
  padding: 4px 4px;
  letter-spacing: 0.28px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdde6;
}

.dashboard-spot-trade-history-section td {
  font-weight: 400;
  align-items: center;
  font-size: 11px !important;
  background-color: transparent;
  border: 0px;
  padding: 4px 4px;
  letter-spacing: 0.28px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdde6;
}

.dashboard-spot-trade-width-section th:nth-child(6),
.dashboard-spot-trade-width-section td:nth-child(6) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(7),
.dashboard-spot-trade-width-section td:nth-child(7) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(8),
.dashboard-spot-trade-width-section td:nth-child(8) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(9),
.dashboard-spot-trade-width-section td:nth-child(9) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(10),
.dashboard-spot-trade-width-section td:nth-child(10) {
  text-align: center !important;
}

.dashboard-spot-trade-history-section .table> :not(:first-child) {
  border-top: 0px solid currentColor;
}

.dashboard-spot-trade-history-section tr {
  height: 70px;
}

/* .trading-page-order-table-details-section th:nth-child(6),
.trading-page-order-table-details-section td:nth-child(6) {
  text-align: center !important;
}
.trading-page-order-table-details-section th:nth-child(7),
.trading-page-order-table-details-section td:nth-child(7) {
  text-align: center !important;
}
.trading-page-order-table-details-section th:nth-child(8),
.trading-page-order-table-details-section td:nth-child(8) {
  text-align: center !important;
}
.trading-page-order-table-details-section th:nth-child(9),
.trading-page-order-table-details-section td:nth-child(9) {
  text-align: center !important;
}
.trading-page-order-table-details-section th:nth-child(10),
.trading-page-order-table-details-section td:nth-child(10) {
  text-align: center !important;
} */
.pagination {
  margin: auto !important;
}

.trading-page-order-table-details-section .table {
  margin-bottom: 0px !important;
}

.navbar-toggler {
  background-color: #02b3b4;
}

.offcanvas-body .nav-link {
  color: #fff;
}

.bg-transparent {
  background-color: transparent !important;
}

.popup-modal-button-my-orders {
  background-color: #02b3b4;
  color: #fff !important;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  top: -5px;
}

@media screen and (max-width:992px) {
  .popup-modal-button-my-orders {
    background-color: #02b3b4;
    color: #fff !important;
    border: none;
    padding: 3px 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    top: 0px;
  }
}

.order-bookexchange-modal .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-top: 5px solid #02b3b4;
  overflow: auto;
}

.order-bookexchange-modal-text-1 {
  font-size: 18px;
  color: rgb(255, 75, 94);
}

.color-sell {
  color: rgb(255, 75, 94) !important;
}

.color-buy {
  color: #02b3b4 !important;
}

.order-bookexchange-modal-text-2 {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.order-bookexchange-modal-text-3 {
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 10px;
  color: white;
}

.order-bookexchange-modal-text-4 {
  font-size: 12px;
  margin-bottom: 10px;
  color: white;
}

.order-bookexchange-modal table {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  /* table-layout: fixed; */
  color: white;
}

.order-bookexchange-modal table tbody tr {
  border-bottom: 1px solid #ddd;
  color: white;
}

.order-bookexchange-modal table th:last-child {
  text-align: end;
}

.order-bookexchange-modal table td:last-child {
  text-align: end;
}

.order-bookexchange-modal table th,
.order-bookexchange-modal table td {
  padding: 0.425em;
  color: white;
}

.order-bookexchange-modal table th {
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 400;
  opacity: 0.6;
  color: white;
}

.order-bookexchange-modal table td {
  font-size: 13px;
  color: white;
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .order-bookexchange-modal .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
  }
}

.css-b62m3t-container {
  width: 200px;
}

.css-xc836r-control {
  padding: 4px;
}

.description {
  width: 100%;
}

/*======================MEDIUM-SCREEN-RESPONSIVE=======================*/

@media (min-width: 991px) and (max-width: 1366px) {
  .font-size-2em-0-weight {
    font-size: 0.9em !important;
  }

  .text-overflow-ellipsis {
    width: 180px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button-1-css {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    font-size: 14px !important;
  }

  .button-3-css {
    background: #0092928c;
    border: 1px solid #ffffff;
    border-radius: 0.5em !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    color: #ffffff;
    font-size: 14px !important;
  }

  .very-small-text {
    font-size: 14px !important;
  }

  .navbar-nav .nav-item {
    margin-left: 0.4em;
  }

  .text-overflow-ellipsis {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #navbar-img-dashboard {
    width: 130px !important;
    height: 40px !important;
    margin-bottom: 3em;
  }

  #navbar-img {
    width: 130px;
    height: 40px;
  }

  .dashboard-navbar-styling-css .navbar {
    margin-left: 180px;
    font-size: 0.9em;
  }

  .navbar-nav {
    font-size: 0.8em;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 9px !important;
    font-weight: 600;
    letter-spacing: 0.26px;
  }

  .trading-page-order-table-details-section tbody td {
    font-size: 9px !important;
    background-color: transparent;
    color: white;
  }

  .dashboard-spot-trade-history-section td {
    font-weight: 400;
    align-items: center;
    font-size: 9px !important;
    background-color: transparent;
    border: 0px;
    padding: 4px 4px;
    letter-spacing: 0.28px;
    vertical-align: middle;
    border-bottom: 1px solid #dbdde6;
  }

  #content {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll;
  }

  #content::-webkit-scrollbar {
    display: none;
  }

  .bars-loading-loader {
    margin-top: 20% !important;
  }

  .p2p-user-center-blocked-user-table-sticky thead {
    display: table;
    width: 100%;
  }

  .p2p-user-center-blocked-user-table-sticky .text-muted:nth-child(2) {
    width: 20% !important;
  }

  .p2p-user-center-blocked-user-table-sticky tbody {
    display: block;
    max-height: 380px;
    overflow-y: scroll;
  }

  .p2p-user-center-blocked-user-table-sticky th,
  .p2p-user-center-blocked-user-table-sticky td {
    width: auto;
    padding: 5px;
    word-break: break-all;
  }

  .p2p-user-center-blocked-user-table-sticky tr {
    display: table;
    width: 100%;
    box-sizing: border-box;
  }
}

/*====================================================UNITIC-EXCHANGE-CSS-START===================================*/

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");

/* img {
  height: 100%;
  width: 100%;
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #20b3e0;
}

/* Typography css begin */
h5 {
  font-size: 1rem;
}

a {
  /* color: var(--color-1) !important; */
  color: white;
  text-decoration: none;
}


.fc-g {
  color: #0ecb81;
}

.head-bg {
  background: #ACA6A6 !important;
}

.nav-link {
  color: #DADADA !important;
  font-size: 1.1rem !important;
}

.nav-link:hover {
  color: white !important;
}

.nav-link:active {
  color: white !important;
}

.nav-link:focus {
  color: white !important;
}



.navbar-toggler {
  background-color: white !important;
}

.btn-user {
  padding: 0px 20px;
  height: 32px;
  min-width: 150px !important;
  background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
  border-radius: 8px;
}

.btn-font {
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  font-style: normal;
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 28px;
  color: #FFFFFF;
}

@media only screen and (max-width: 995px) {
  .btn-user {
    padding: 0px 20px;
    height: 32px;
    min-width: none !important;
    width: auto !important;
    background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
    border-radius: 8px;
  }
}

.border-grey {
  border: 1px solid #707070;
}

.border-bottom-green-css {
  border-bottom: 2px solid #02b3b4;
}

.text-green {
  color: #02b3b4 !important;
}

.font-size-2em {
  font-size: 2em !important;
  font-weight: 600;
}

.font-size-2em-0-weight {
  font-size: 2em !important;
}

.very-small-text {
  font-size: 16px;
}

.very-small-text-2 {
  font-size: 12px !important;
}

.grey-text {
  color: #707070 !important;
}

.fs-20 {
  font-size: 20px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-900 {
  font-weight: 900;
}

.text-logo-grad {
  background: linear-gradient(92deg, #546eff 0.35%, #10EDB6 98.55%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* #0CF5B2, #10EDB6, #4C62F7*/
}

.text-logo-grad-home {
  color: #21CA50;
}

.fontSizeFooter div p {
  font-size: 18px;
}

.fontSizeFooter div ul a li {
  font-size: 14px;
  padding-bottom: 5px;
  transition: color 0.2s ease-in;
}

.extraHover div ul a li:hover {
  color: #21CA50;
}

.fontSizeFooter div ul li {
  font-size: 14px;
  padding-bottom: 5px;
  transition: color 0.2s ease-in;
  cursor: pointer;
}

.extraHover div ul li:hover {
  color: #21CA50 !important;
}

.reduceSpaceFooter {
  margin-top: -25px;
}

.border-radius-1em {
  border-radius: 1.5em;
}

.border-radius-small {
  border-radius: 0.4em;
}

ul li {
  list-style: none;
}

.grid-container-styling ::placeholder {
  color: #ffffff;
}

.pointer-cursor {
  cursor: pointer;
}

input {
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border: none;
}

/* .goog-te-gadget img{
  width: 1px;
  height: 1px;
} */

/*=============================================THEME==========================================*/

/*=============================================THEME==========================================*/

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .border-green {
    border: 2px solid #02b3b4;
  }

  .button-2-css-table {
    border: 2px solid #02b3b4 !important;
    padding-left: 2em !important;
    color: var(--color-1);
    padding-right: 1em !important;
  }

  /*==============================================DEPOSIT-COLUMN-START==================================*/

  /*==============================================DEPOSIT-COLUMN-TABLE-START============================*/

  .deposit-page-table-styling table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .deposit-page-table-styling table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  .deposit-page-table-styling table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
  }

  .deposit-page-table-styling table th,
  .deposit-page-table-styling table td {
    padding: 0.625em;
    text-align: center;
  }

  .deposit-page-table-styling table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
    .deposit-page-table-styling table {
      border: 0;
    }

    .deposit-page-table-styling table caption {
      font-size: 1.3em;
    }

    .deposit-page-table-styling table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .deposit-page-table-styling table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    .deposit-page-table-styling table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    .deposit-page-table-styling table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    .deposit-page-table-styling table td:last-child {
      border-bottom: 0;
    }
  }

  /*==============================================DEPOSIT-COLUMN-TABLE-END==============================*/

  .timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem;
  }

  @media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.46rem;
      position: absolute;
      left: 7.5rem;
      top: 0.3125rem;
    }

    .timeline-steps .timeline-step:not(:first-child):before {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.8125rem;
      position: absolute;
      right: 7.5rem;
      top: 0.3125rem;
    }
  }

  .timeline-steps .timeline-content {
    width: 10rem;
    text-align: center;
  }

  .timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3b82f6;
  }

  .timeline-steps .timeline-content .inner-circle:before {
    content: "";
    background-color: #3b82f6;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: 0.5;
  }

  /*==============================================DEPOSIT-COLUMN-END====================================*/

  /* ===============================BUYCRYPTO-START================================ */
  /* 
 .top-heading {
color: #ffffff !important;
 } */

  .postnewad-page-1-styling .form-control {
    border: 1px solid #ced4da !important;
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .postnewad-page-2-styling .form-control {
    border: 1px solid #ced4da !important;
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .postnewad-page-3-styling .form-control {
    border: 1px solid #ced4da !important;
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .button-select-option-1 {
    background-color: #02b3b4 !important;
    color: #ffffff !important;
  }

  .buycrypto-page-top-section .form-select {
    border: none !important;
  }

  .buycrypto-nav-pills .buycrypto-nav-link.active,
  .buycrypto-nav-pills .show>.buycrypto-nav-link {
    background-color: #02b3b4;
    color: #ffffff !important;
    padding: 0.3em !important;
  }

  .buy-crypto-page-card-bg-styling {
    --tw-bg-opacity: 1;
    background-color: var(--background-color-1);

    border-radius: 2em;
  }

  .buycrypto-select-bg-styling {
    background-color: var(--background-color-2);
    color: var(--color-1);
    box-shadow: none;
    border: none;
    border-radius: 0.4em;
  }

  .buycrypto-select-bg-styling:focus {
    background-color: var(--background-color-2);
    color: var(--color-1);
    border-color: none;
    outline: 0;
    box-shadow: none;
    border-radius: 0.4em;
  }

  /* ===============================SELLCRYPTO-STARTS================================ */

  .bg-sellcrypto {
    --tw-bg-opacity: 1;
    background-color: var(--background-color-1);
    width: 40%;
    border-radius: 2em;
  }

  .hovorEffectSell:hover {
    background-color: #C41E3A !important;
  }

  @media only screen and (max-width: 650px) {
    .bg-sellcrypto {
      width: 100%;
    }
  }


  /* ===============================SELLCRYPTO-END================================ */

  /* ===============================BUYCRYPTO-END================================ */

  .form-check-input {
    border: 2px solid #14b269 !important;
  }

  /*==================================================SPOTEXCHANGE-STARTS===========================================*/

  .trading-page-order-tabs-section {
    height: 120px;
    margin: auto;
    text-align: center;
  }

  .trade-bottom-login-text-1 {
    color: gray;
    font-size: 14px;
  }

  .trading-page-order-details-section {
    border-top: 0px !important;
    /* width: 1200px;
    margin: auto; */
  }

  .activities-button-1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 13px;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    border: none !important;
    color: #fff;
    width: 40px;
  }

  .activities-text-4 {
    position: relative;
    top: -4px;
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
    margin: 0px;
  }

  .activities-text-3 {
    color: rgb(112, 122, 138);
  }

  .activities-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .activities-text-1 {
    font-size: 14px;
  }

  .trading-table-right-activities-section {
    margin-top: 5px;
    border-top: 1px solid rgb(238, 240, 242);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .trading-table-right-bottom-section {
    height: 290px !important;
    overflow-y: scroll;
  }

  .paired-trade-table-section {
    /* margin-left: 10px !important;
    margin-right: 10px !important; */
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-bottom: 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    padding-top: 10px;
    /* margin-bottom: 10px; */
  }

  .paired-trade-table-section .nav-pills .nav-link.active,
  .paired-trade-table-section .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid #02b3b4;
  }

  .paired-trade-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 15px !important;
    color: white !important;
    text-decoration: none;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
    margin-right: 30px;
    border-radius: 0px;
    padding-bottom: 8px;
  }

  .trading-table-right-inner-section {
    height: 310px !important;
    overflow-y: scroll;
  }

  .paired-text-3 {
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    margin-left: 4px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(254, 246, 216);
  }

  .pared-fav-button {
    color: rgb(112, 122, 138);
    margin-bottom: 4px;
    margin-right: 1px;
  }

  .trading-table-right-section .flex-row {
    height: 22px;
  }

  .trading-table-right-section {
    /* width: 95%; */
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin: auto;
  }

  .paired-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .paired-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .paired-table-1 {
    width: 110px;
  }

  .paired-table-2 {
    width: 80px;
    text-align: end;
  }

  .paired-table-3 {
    width: 90px;
    text-align: end;
  }

  .paired-price-table-section {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .paired-price-table-section .nav-pills .nav-link.active,
  .paired-price-table-section .nav-pills .show>.nav-link {
    color: #02b3b4 !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-price-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: rgb(112, 122, 138) !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 6px;
  }

  /* .trading-page-right-section {
    width: 290px !important;
  } */

  .trade-search-section {
    width: 90%;
    margin: auto;
    border-radius: 2px;
    line-height: 1.5;
    background-color: rgb(242, 243, 245);
  }

  .trade-search-btn {
    border: none;
    color: rgb(112, 122, 138);
    background: transparent;
  }

  .trade-search-section .form-control {
    padding: 0.215rem 0.15rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .trade-search-section .form-control:focus {
    background-color: transparent;
  }

  .progress-bar {
    position: absolute;
    right: 0px;
    z-index: 1;
    height: 23px;
  }

  .ask-bar {
    background-color: rgba(255, 0, 0, 0.158) !important;
  }

  .progress-bar-bg-color {
    position: relative;
  }

  .progress-bar-bg-color .flex-row {
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center !important;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.26px;
  }

  .progress-table-1 {
    width: 33%;
  }

  .progress-table-2 {
    width: 33%;
    text-align: center;
    color: white !important;
  }

  .progress-table-3 {
    width: 33%;
    text-align: end;
    color: white;
  }

  .orderbook-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: white;
    letter-spacing: 0.015em;
    padding-bottom: 0rem !important;
  }

  .orderbook-text-4 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.26px;
  }

  .trailing-delta-btn {
    background-color: transparent;
    font-size: 0.8rem;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
  }

  .custom-dropdown,
  .dropdown-menu {
    box-shadow: none !important;
  }

  .custom-dropdown .dropdown-item {
    color: inherit;
    font-size: 14px;
  }

  .select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(112, 122, 138);
    background-color: transparent;
  }

  .select-dropdown-btn:hover {
    border: none !important;
  }

  .limit-market-section-bottom .select-amount-button {
    background-color: transparent;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
    font-size: 0.9rem;
  }

  .limit-market-section-bottom .dropdown-item {
    padding: 0.5rem 1rem;
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-item:hover {
    background-color: rgb(234, 236, 239);
  }

  .limit-market-section-bottom .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-menu {
    border-radius: 0rem !important;
    padding: 0px !important;
    left: -20px !important;
  }

  .trade-text-15 {
    color: #02b3b4;
  }

  .trade-buy-sell-button-1 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(230, 232, 234);
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .trade-buy-sell-button-11 {
    padding: 0.665rem 0.465rem;
    background-color: #02b3b4;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .trade-buy-sell-button-111 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .total-value-price-section {
    /* position: relative;
    top: -30px; */
    font-size: 11px;
    color: #000;
  }

  .percentage-line-section {
    height: 5px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }

  .percentage-display-section {
    background-color: #02b3b4 !important;
    height: 32px;
    width: 32px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #aaa;
    outline: none !important;
  }

  .trade-text-14 {
    font-size: 0.8rem;
  }

  .trade-text-13 {
    font-size: 0.8rem;
    color: rgb(112, 122, 138);
  }

  /* ::placeholder {
    color: #212529 !important;
  } */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .limit-market-section-bottom .form-control {
    display: block;
    width: 100%;
    padding: 0rem 0.75rem;
    font-size: 0.8rem;
    float: right;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    text-align: right;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .limit-button-width {
    width: 40px !important;
    text-align: center;

    margin-left: 6px;
  }

  .limit-price-form {
    align-items: center;
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 0.365rem 0.465rem;
    background-color: rgba(230, 232, 234, 0.6);
  }

  .limit-price-form:hover {
    border: 1px solid #02b3b4;
  }

  .limit-price-form:focus {
    border: 1px solid #02b3b4 !important;
  }

  .trade-text-12 {
    font-size: 12px;
    color: rgb(0, 0, 0);
  }

  .trade-text-11 {
    font-size: 12px;
    color: rgb(112, 122, 138);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active {
    border-top: 2px solid #02b3b4 !important;
    border-bottom: 0px solid transparent;
    color: rgb(30, 35, 41);
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active:hover {
    border-top: 2px solid #02b3b4;
    border-bottom: 0px solid transparent;
    border-left: 1px solid rgb(238, 240, 242);
    border-right: 1px solid rgb(238, 240, 242);
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: rgba(112, 122, 138, 0.815);
    letter-spacing: 0.4px;
    width: 130px;
    /* height: 50px; */
    padding: 10px 20px;
    font-weight: 500;
  }

  .limit-market-section-bottom .nav-link:hover {
    border-color: transparent;
    outline: none;
  }

  .trading-table-left-section {
    max-height: 480px !important;
    overflow-y: auto;
  }

  .trading-table-left-recent-trade-height {
    height: 340px !important;
    overflow-y: auto;
  }

  .place-order-section-top {
    border-left: 0px !important;
  }

  /* .list-hover-tooltip {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(255, 0, 0, 0.205);
  } */

  .trading-table-total-height {
    max-height: 585px !important;
    border-bottom: 1px solid #f5f8fb;
    overflow-y: hidden;
  }

  /* .list-hover-tooltip {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(255, 0, 0, 0.205);
  } */

  #tradingviewwidget {
    width: 100% !important;
    height: 500px !important;
  }

  /* .trading-inner-right-section {
    width: 650px;
  } */

  .trading-inner-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 260px !important; */
  }

  .trading-page-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* background-color: red; */
    width: 920px !important;
    overflow: hidden;
  }

  .trade-text-10 {
    font-size: 12px;
    font-weight: 100;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trade-icon-2 {
    font-size: 14px;
    position: relative;
    top: -1px;
  }

  .trade-text-9 {
    font-size: 16px;
    margin-right: 4px;
  }

  .hide-content-only {
    visibility: hidden;
  }

  .trading-page-top-section-3 .table> :not(caption)>*>* {
    padding: 0rem 0rem;
  }

  .trading-page-top-section-3 th {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .trading-page-top-section-3 tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
  }

  .trading-page-top-section-3 th:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 th:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-2 .form-check {
    min-height: 0rem;
    margin-bottom: 0rem;
  }

  .trading-page-top-section-2 .dropdown-item {
    padding: 0rem 1rem;
  }

  .trading-page-top-section-2 .dropdown-menu {
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 8%) 0px 7px 14px,
      rgb(24 26 32 / 8%) 0px 3px 6px;
    border-radius: 4px;
    transform: translate(-10rem, 28px) !important;
    font-size: 11px;
  }

  .trading-page-top-section-2 .dropdown-toggle::after {
    margin-left: 0em;
    content: none;
  }

  .trading-page-top-section-2 {
    padding: 0px;
    color: white;
  }

  .average-icon-display-1 {
    background-color: transparent;
    color: rgb(198, 202, 208);
    border: none;
  }

  .trading-page-top-section-2 .form-select {
    display: block;
    width: 100%;
    padding: 0.075rem 1.05rem 0.075rem 0.25rem;
    background-position: right 0.25rem center;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .trading-page-top-section-2 .form-select option {
    font-size: 0.875rem;
  }

  .trading-page-top-section-2 .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.675rem;
    border-radius: 0.2rem;
    text-align: center;
  }

  .trading-page-top-section-1 {
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    /* height: 80px; */
    /* width: 920px; */
    /* background-color: red; */
    /* overflow: scroll; */
  }

  /* .trading-page-top-section-1::-webkit-scrollbar { 
    display: none;  
    } */

  .order-icon-3 {
    width: 16px;
    height: 16px;
  }

  .order-icon-2 {
    width: 16px;
    height: 16px;
  }

  .order-icon-1 {
    width: 16px;
    height: 16px;
  }

  .trading-page-section-5 {
    margin-right: 20px;
  }

  .trading-page-section-4 {
    padding-right: 15px;
  }

  .trade-text-8 {
    font-size: 11px;
    color: rgb(112, 122, 138);
    margin-bottom: 0px;
  }

  .trading-page-section-3 {
    padding-right: 15px;
    margin-right: 10px;
  }

  .trade-text-7 {
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    white-space: nowrap;
    letter-spacing: 0.24px;
    margin-bottom: 0px;
    color: rgb(243, 243, 243) !important;
  }

  .trade-text-6 {
    color: rgba(243, 243, 243, 0.4);
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 6px !important;
    letter-spacing: 0.2px;
  }

  .trading-page-section-2 {
    border-right: 1px solid rgb(238, 240, 242);
    padding-right: 15px;
    margin-right: 10px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    text-align: start;
  }

  .trade-text-5 {
    font-size: 15px;
    font-weight: 1000;
    margin-bottom: 0px;
    letter-spacing: 0.045em;
    color: rgb(243, 243, 243) !important;
  }

  .trade-text-4 {
    font-size: 16px;
    font-weight: 1000;
    margin-bottom: 2px;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: 1px;
    color: rgb(243, 243, 243);
  }

  .trading-page-section-1 {
    border-right: 1px solid #dbdde6;
    padding-right: 15px;
    margin-right: 15px;
  }

  .trading-page-top-section-1 {
    /* margin: 10px 0px; */
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
  }

  .trade-text-3 {
    margin-left: 6px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 9px;
    border-radius: 2px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(249, 244, 226);
    position: relative;
    top: -2px;
  }

  .trade-icon-1 {
    font-size: 11px;
    margin-right: 2px;
  }

  .trade-text-2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);
    text-decoration: underline;
    font-size: 9px;
  }

  .trade-text-1 {
    font-size: 0.85rem;
    font-weight: 1000;
    margin-bottom: 3px;
    margin-bottom: 3px;
  }

  .trading-page-box {
    /* border: 2px solid var(--border-color-1); */
    border: 0px solid;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .trading-page-box-left-inner-section {
    border-right: 5px solid var(--background-color-extra-black);
    width: 58% !important;
  }

  .trading-page-box-right-inner-section {
    border-right: 5px solid var(--background-color-extra-black);
    width: 42% !important;
  }

  .orderbook-table-heading-section {
    border: 0px !important;
    height: 30px !important;
    vertical-align: middle !important;
    align-items: center !important;
  }

  .spot-page-top-banner {
    padding-bottom: 100px;
  }


  .img-fluid-size {
    height: 35px;
    width: 35px;
  }

  .text-spot {
    font-size: 11px !important;
    color: white !important;
  }

  .place-order-buy-button {
    color: #fff;
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .place-order-login-button {
    border: 0px !important;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    background-color: transparent;
    color: white !important;
  }

  .place-order-buy-sell-percentage-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-percentage-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #02b3b4;
    border: 1px solid #02b3b4;
  }

  .place-order-buy-sell-percentage-tabs .nav-link {
    display: block;
    padding: 0.375rem 0.75rem;
    color: #727581;
    text-decoration: none;
    font-size: 13px !important;
    border: 1px solid #dbdde6 !important;
    width: 90% !important;
  }

  .place-order-section-top .form-control {
    /* padding: 1rem 0.75rem; */
    font-size: 0.9rem;
    font-weight: 400;
    background-color: rgba(243, 243, 243, 0.04) !important;
    color: rgb(243, 243, 243) !important;
    border: 0px !important;
    /* border-right: 0px; */
  }

  .place-order-section-top .input-group-text {
    /* color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.26px;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-left: 0px; */
    color: #FFF;
    font-size: 10px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 400;
    background: #535151 !important;
    border: 0px !important;
  }

  .select-dropdown-btn-buysell {
    border: 1px solid #dbdde6;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 35px;
    display: flex;
    padding: 0px 16px;
    font-size: 14px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    align-items: center;
    font-weight: 500;
    line-height: 21px;
    border-radius: 2px;
    justify-content: space-between;
    background-color: var(--background-color-2);
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #14b269;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link-sell.active,
  .place-order-buy-sell-tabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #ff4b5e;
  }

  .place-order-buy-sell-tabs .nav-pills {
    border: 1px solid #dbdde6;
    background-color: var(--background-color-2);
  }

  .place-order-buy-sell-tabs .nav-link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.055rem;
    border-radius: 2px;
    display: block;
    padding: 0.5rem 1rem;
    color: #727581;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .top-heading-total-height {
    height: fit-content;
    align-items: center;
    background: transparent;
    color: rgb(243, 243, 243);
    border-bottom: 0px !important;
    position: relative;
    z-index: 1000;
  }

  .orderbook-grid-tabs .nav-link {
    display: block;
    padding: 0.2rem 0.3rem;
    padding-top: 0px !important;
    margin-left: 2px;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    border: 1px solid transparent;
  }

  .orderbook-grid-tabs .nav-pills .nav-link.active,
  .orderbook-grid-tabs .nav-pills .show>.nav-link {
    border: 1px solid rgb(2, 179, 180);
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    background-color: transparent;
  }

  .fixed-top-padding-top {
    padding-top: 150px;
  }

  /*==================================================SPOTEXCHANGE-ENDS==============================================*/
  /*=============================================MARKETS-STARTS=====================================================*/

  .coin-icon-sizing-data-table {
    width: 30px;
    height: 30px;
  }

  .tablepagination-styling p {
    margin-bottom: 0px !important;
  }

  /* table {
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  table tr {
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  table th {
    
    color: var(--color-1) !important;
  }

  table td {
    
    color: var(--color-1) !important;
  }

  table thead {
    
    color: var(--color-1) !important;
  } */

  /* .MuiTablePagination-root {
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .MuiSvgIcon-root {
    color: var(--color-1) !important;
  }

  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
    color: var(--color-1) !important;
  }

  .css-1q1u3t4-MuiTableRow-root.Mui-selected {
    background-color: transparent !important;
  } */

  /*=============================================MARKETS-ENDS=======================================================*/

  /*==========================================P2P-ACCORDION-STARTS=================================================*/

  .color-border-styling-1 {
    border-left: 4px solid red;
  }

  .color-border-styling-2 {
    border-left: 4px solid #f5791f;
  }

  .color-border-styling-3 {
    border-left: 4px solid #f0b90b;
  }

  .color-border-styling-4 {
    border-left: 4px solid #ff09ad;
  }

  .color-border-styling-5 {
    border-left: 4px solid #000000;
  }

  .color-border-styling-6 {
    border-left: 4px solid #0582ff;
  }

  .accordion-flush .accordion-item,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    box-shadow: none !important;
    outline: none !important;
    padding-top: 1.6em !important;
    padding-bottom: 1.6em !important;
    font-size: 20px;
    font-weight: 500;
    color: white !important;
    background-color: transparent !important;
  }

  .accordion-flush .accordion-button {
    box-shadow: none !important;
    outline: none !important;
    padding-top: 1.6em !important;
    padding-bottom: 1.6em !important;
    font-size: 20px;
    font-weight: 500;
    color: white !important;
    background-color: transparent !important;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    color: white;
    background-color: gray !important;
    box-shadow: none !important;
  }

  /*==========================================P2P-ACCORDION-ENDS===================================================*/

  .mobile-image-size-styling {
    width: 350px;
    height: 350px;
  }

  .button-1-css {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .button-2-css {
    border: 2px solid #02b3b4 !important;
    padding-left: 2em !important;
    color: var(--color-1);
    padding-right: 2em !important;
  }

  .button-3-css {
    background: #0092928c;
    border: 1px solid #ffffff;
    border-radius: 1em;
    padding-left: 1em !important;
    padding-right: 1em !important;
    color: #ffffff;
    font-size: 18px;
  }

  .button-1-css-register {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 6em !important;
    padding-right: 6em !important;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1.2em;
  }

  .button-1-css:hover {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .button-2-css:hover {
    border: 2px solid #02b3b4 !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .button-3-css:hover {
    background: #0092928c;
    border: 1px solid #ffffff;
    border-radius: 1em;
    padding-left: 1em !important;
    padding-right: 1em !important;
    color: #ffffff;
    font-size: 18px;
  }

  .button-1-css-register:hover {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 6em !important;
    padding-right: 6em !important;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1.2em;
  }

  /*==============================================P2P-START===========================================*/

  /*==============================================P2P-SUB-SECTIONS-START====================================*/

  /*======================POST-NEW-AD-STARTS========================*/

  .bg-gray {
    background-color: hsla(0, 0%, 50%, 0.205);
    border-radius: 20px;
  }

  .btn-next {
    height: 38px;

    background: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%);
    background: linear-gradient(93.19deg, #08f8bc 0.77%, #1b34d4 100%);
    border-radius: 10px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 700;
    color: white !important;
  }

  .btn-next:hover {
    height: 38px;
    background: linear-gradient(93.19deg, #08f8bc 0.77%, #1b34d4 100%);
  }

  /* .p2p-main-page-css .form-control {
    border: none !important;
    border-radius: none !important;
    background-color: var(--background-color-2) !important;
  } */

  .css-tab-col .nav-pills .nav-link.active,
  .css-tab-col .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #02b3b4 !important;
  }

  /* ======================ADD-PAYMENT-METHOD-START================== */

  .add-payment-method-heading-styling {
    font-size: 24px;
    padding: 20px 0px;
    font-weight: 550;
  }

  .add-payment-method-text-1 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .btn-disabled-color:disabled {
    background: linear-gradient(93.19deg, #008362 0.77%, #001181 100%);
  }

  .add-payment-method-text-2 {
    font-size: 11px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    color: rgb(177, 177, 177);
  }

  .add-payment-method-top-banner .form-control-payment-method {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .add-payment-method-cancel-button {
    background-color: #eaecef;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .add-payment-method-confirm-button {
    background-color: #02b3b4;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .payment-qrcode-optional-image {
    width: 70px;
  }

  .payment-qrcode-optional-button {
    border: 1px solid rgb(177, 177, 177);
    font-size: 13px;
    padding: 2px 30px;
    color: gray;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  /* ==========================ADD-PAYMENT-METHOD-END==================== */

  /* =============================P2P-USER-CENTER-START===================== */
  .p2p-trade-hero-section-two-nav-bg .dropdown-toggle::after {
    display: none;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  .noresponse-image-styling {
    width: 90px;
  }

  .profile-images-letter-styling {
    background-color: #000000 !important;
    border-radius: 1em !important;
    color: #ffffff;
    padding: 3px;
    font-size: 1.3em !important;
  }

  .scrollspy-payments-row-bg-styling {
    /* background: var(--background-color-2); */
  }

  .font-size-style {
    font-size: 17px;
    font-weight: 600;
  }

  .p2puser-center-button {
    background-color: #02b3b4;
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2puser-center-scrollspy-styling-active {
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollsp-border {
    border-bottom: 1px solid #dee2e6;
  }

  .p2puser-center-scrollspy-button {
    border: none;
    box-shadow: none !important;
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    letter-spacing: 0.08em;
    padding: 20px 20px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .p2puser-center-scrollspy-styling .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #000000;
    font-size: 16px !important;
    font-weight: 500;
  }

  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-item.show .nav-link,
  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-link.active {
    color: #495057;
    background-color: var(--background-color-2);
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollspy-styling .dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .p2p-user-scrollspy-button {
    color: var(--color-1);
    background-color: var(--background-color-2);
    font-size: 14px;
  }

  .p2p-user-center-scrollspy-tops-heading {
    /* margin: 0px 0px 16px; */
    min-width: 0px;
    font-weight: 500 !important;
    font-size: 16px !important;
    /* line-height: 24px; */
  }

  .border-line-styling {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    width: 4px;
    height: 14px;
    border-radius: 4px;
    background-color: rgb(95, 37, 159) !important;
  }

  .scrollspy-tops-heading-paragraph {
    /* margin: 0px 0px 8px; */
    min-width: 0px;
    max-width: 100%;
    font-size: 14px !important;
    /* line-height: 24px;

padding-left: 8px;
padding-right: 8px; */
  }

  #scrollspy-section-1-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    /* background: var(--background-color-1); */
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-2-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    background: var(--background-color-1);
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-3-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    background: var(--background-color-1);
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  .p2p-usercenter-progressbar {
    position: relative !important;
    right: 0px;
    z-index: 1;
    height: 9px;
    width: 100px;
    background-color: #02b3b4;
  }

  .p2p-usercenter-progressbar-top {
    height: 9px;
    width: 100px;
  }

  /* =============================P2P-USER-CENTER-END======================= */

  /* ==============================ORDER-P2P-TRADE-START==================== */

  /* ==============================DATE-PICKER-START======================== */
  .datepicker-css-styling {
    border: none !important;
    width: 100px !important;
    outline: none !important;
    font-size: 0.9rem;
  }

  .datepicker-css-styling input {
    border: none !important;
    box-shadow: none !important;
  }

  /* ==============================DATE-PICKER-END========================== */

  .order-p2p-trade-dropdowns.select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    background-color: transparent;
  }

  .order-p2p-reset-content-title {
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    color: #02b3b4;
  }

  .order-p2p-trade-download-icon {
    font-size: 35px;
    background-color: rgb(214, 214, 214) !important;
    cursor: pointer;
  }

  /* ==============================ORDER-P2P-TRADE-END====================== */

  /*==============================================P2P-SUB-SECTIONS-END======================================*/

  .hero-top-section {
    background: rgba(0, 0, 0, 0.87);
    background-image: url("./images/glow-effects-p2p.png"),
      url("./images/grid-image-bg.png");
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 100% 100%;
  }

  .hero-top-section-text-2 {
    font-size: 16px;
    width: 50%;
    text-align: center;
    margin: auto;
  }

  .nav-tabs-buy-sell-section {
    border: 1px solid #e6e8ea;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell-section .nav-tabs .nav-link:hover {
    border-color: #e9ecef00 #e9ecef00 #dee2e600;
    isolation: isolate;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .green-tab-active {
    background-color: #02b3b4 !important;
    color: var(--color-1) !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .red-tab-active {
    background-color: rgb(203, 13, 13) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #02b3b4;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs-buy-sell .nav-tabs {
    border: 0px solid #0582ff !important;
  }

  .nav-tabs-buy-sell .nav-link {
    display: block;
    padding: 0.3rem 0rem !important;
    color: var(--color-1) !important;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-pills-buy-sell .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
  }

  .nav-pills-buy-sell .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: #02b3b4 !important;
    border-bottom: 2px solid #02b3b4;
  }

  .nav-pills-buy-sell .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--color-1) !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .enter-amount-section .btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: #02b3b4;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section .btn:hover {
    color: #02b3b4;
  }

  .enter-amount-text-section {
    border-right: 1px solid #eaecef;
  }

  .enter-amount-text-1 {
    font-size: 15px;
    color: #aeb4bc;
  }

  .enter-amount-section .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section {
    background-color: #f5f5f5;
    border: 1px solid #02b3b4;
    padding: 0.175rem 0rem;
    border-radius: 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #cccdcf !important;
    font-size: 14px;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  input:focus {
    box-shadow: none !important;
  }

  .enter-amount-heading {
    font-size: 11px;
    color: #76808f;
  }

  .enter-amount-text-2 {
    font-size: 11px;
    color: #76808f;
    margin-left: -10px;
  }

  .f-dropdown {
    --max-scroll: 3;
    position: relative;
    z-index: 10;
  }

  .f-dropdown select {
    display: none;
  }

  .f-dropdown>span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #f0bb0b50 !important;
    background: #f5f5f5;
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .f-dropdown>span>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .f-dropdown>span img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown>span:before,
  .f-dropdown>span:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 1px;
    top: 50%;
    right: 12px;
    background: #000;
    transition: all 0.3s ease;
  }

  .f-dropdown>span:before {
    margin-right: 4px;
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown>span:after {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);
  }

  .f-dropdown ul li {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .f-dropdown ul li a {
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }

  .f-dropdown ul li a img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown ul li a:hover {
    color: #000 !important;
  }

  .f-dropdown ul li.active a {
    color: #000;
    background: gray;
  }

  .f-dropdown ul li.active a:before,
  .f-dropdown ul li.active a:after {
    --scale: 0.6;
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    position: absolute;
    right: 12px;
    top: 50%;
    opacity: 0;
    background: #fff;
    transition: all 0.2s ease;
  }

  .f-dropdown ul li.active a:before {
    transform: rotate(45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:after {
    transform: rotate(-45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:hover:before,
  .f-dropdown ul li.active a:hover:after {
    --scale: 0.9;
    opacity: 1;
  }

  .f-dropdown ul li:first-child a {
    border-radius: 3px 3px 0 0;
  }

  .f-dropdown ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  .f-dropdown.disabled {
    opacity: 0.7;
  }

  .f-dropdown.disabled>span {
    cursor: not-allowed;
  }

  .f-dropdown.filled>span {
    color: #000;
  }

  .f-dropdown.open {
    z-index: 15;
  }

  .f-dropdown.open>span {
    border-color: #aaa;
  }

  .f-dropdown.open>span:before,
  .f-dropdown.open>span:after {
    background: #000;
  }

  .f-dropdown.open>span:before {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown.open>span:after {
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown.open ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 12px);
    transition: opacity 0.3s ease, visibility 0.3s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  }

  /* --------------------------- */
  .f-group {
    max-width: 250px;
    margin: 0 auto;
    text-align: left;
  }

  .f-group select {
    width: 100%;
  }

  .f-control {
    font-size: 14px;
    line-height: normal;
    color: #000;
    display: inline-block;
    background-color: #f5f5f5;
    border: #ccc 1px solid;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  /* ==================================P2P-TRADE-TABLE-START======================== */

  .p2p-trade-table {
    border: none !important;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .p2p-trade-table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  .p2p-trade-table .p2p-trade-table-tr {
    background-color: var(--background-color-1);

    /* border-bottom: 1px solid #e1e5e9; */
    padding: 0.35em;
    border-radius: 3px;
  }

  /* .p2p-trade-table .p2p-trade-table-thead .p2p-trade-table-tr:first-child {
    border-bottom: 1px solid #e1e5e9;
  } */
  .p2p-trade-table .p2p-trade-table-th,
  .p2p-trade-table .p2p-trade-table-td {
    padding: 1.5em;
    color: var(--color-1) !important;
    font-size: 14px;
  }

  .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
    font-size: 14px;
  }

  .p2p-trade-table .p2p-trade-table-th {
    font-size: 11px;
    color: var(--color-1) !important;
    font-weight: 400;
  }

  .p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice {
    background-color: #02b3b4;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice-sell {
    background-color: rgba(241, 55, 55);
    color: #ffffff !important;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  /* 
tbody tr:nth-child(even) {
	background-color: #eee;
}

tbody tr:nth-child(odd) {
	background-color: #fff;
} */

  @media screen and (max-width: 600px) {
    .p2p-trade-table {
      border: 0;
    }

    .p2p-trade-table caption {
      font-size: 1.3em;
    }

    .p2p-trade-table .p2p-trade-table-thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      position: absolute;
      width: 1px;
      padding: 0;
    }

    .p2p-trade-table .p2p-trade-table-tr {
      border-bottom: 3px solid #e1e5e9;
      display: block;
      margin-bottom: 0.625em;
    }

    .p2p-trade-table .p2p-trade-table-th,
    .p2p-trade-table .p2p-trade-table-td {
      padding: 0.625em;
    }

    .p2p-trade-table .p2p-trade-table-td {
      border-bottom: 1px solid #e1e5e9;
      display: block;
      font-size: 0.8em;
      text-align: right;
      color: var(--color-1) !important;
    }

    .p2p-trade-table .p2p-trade-table-td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-1) !important;
    }

    .p2p-trade-table .p2p-trade-table-td:last-child {
      border-bottom: 0;
    }

    .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
      font-size: 0.8em;
    }
  }

  .table-lth-bg {
    color: #02b3b4;
    background-color: #02b4b42f !important;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 10px;
  }

  .table-trade-bg {
    color: #02b3b4;
    background-color: #02b4b42f;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 10px;
  }

  .table-data-1 {
    font-size: 14px;
    color: var(--color-1);
  }

  .table-data-2 {
    font-size: 12px;
    color: var(--color-1) !important;
  }

  .table-data-3 {
    font-size: 20px;
    color: var(--color-1) !important;
  }

  .table-data-4 {
    font-size: 11px;
    color: var(--color-1) !important;
  }

  .table-data-5 {
    font-size: 12px;
    color: var(--color-1) !important;
  }

  .table-data-6 {
    margin: 0px 4px 0px 0px;
    color: var(--color-1) !important;
    font-size: 11px;
  }

  .table-data-7 {
    color: #02b3b4;
    background-color: #02b4b42f;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 11px;
  }

  .table-data-1 img {
    width: 15px;
    height: 15px;
    padding-bottom: 2px;
    border-radius: 50%;
    margin-right: 4px;
  }

  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:before {
    bottom: 0.5em;
  }

  /* ==================================P2P-TRADE-TABLE-END======================== */
  .p2p-second-nav-content-title {
    font-size: 13px;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  /*==============================================P2P-END=============================================*/

  .mainbanner-card-3-styling {
    background: #01c6c7 0% 0% no-repeat padding-box;
    border: 2px solid #01c6c7;
    border-radius: 42px;
    opacity: 0.85;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 4em 4em 4em 4em;
  }

  .mainbanner-card-2-styling {
    background: #01c6c7 0% 0% no-repeat padding-box;
    border: 2px solid #01c6c7;
    border-radius: 42px;
    opacity: 0.85;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 7em 7em 4em 9em;
  }

  .mainbanner-icon-size-1 {
    width: 80px;
    height: 80px;
  }

  .mainbanner-apps-image {
    width: 150px;
    height: 50px;
  }

  .mainbanner-icon-size-2 {
    width: 40px;
    height: 40px;
  }

  .mainbanner-table-section-styling table> :not(caption)>*>* {
    border: none !important;
  }

  .gird-table-bg {
    background-image: url("./images/grid-image-opacity-bg.png");
    background-repeat: no-repeat;
    /* background-position: cover; */
    background-size: 100% 100%;
  }

  .gird-table-bg table {
    background-color: transparent !important;
  }

  .gird-table-bg tr {
    background-color: transparent !important;
  }

  .card-bg-styling-mainbanner {
    background-color: #02b4b42d;
    /* background-image: url("./images/grid-image-opacity-2-bg.png");
    background-repeat: no-repeat; */
    /* background-position: cover; */
    background-size: 100% 100%;
    border-radius: 20px;
  }

  .mainbanner-table-section-styling table td,
  .mainbanner-table-section-styling table th {
    padding-bottom: 2em !important;
  }

  .mainbanner-table-section-styling table th {
    color: #02b3b4;
  }

  .coin-icon-sizing {
    width: 80px;
    height: 40px;
  }

  /*============================================SWIPER==========================================*/

  .swiper {
    width: 1000px !important;
    height: 220px;
  }

  .swiper-slide {
    /* height: 60% !important; */
    width: 31% !important;
    margin-left: 21px;
  }

  .swiper-pagination-bullet-active {
    background: #02b3b4 !important;
  }

  .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width,
        var(--swiper-pagination-bullet-size, 30px)) !important;
    height: var(--swiper-pagination-bullet-height,
        var(--swiper-pagination-bullet-size, 5px)) !important;
    display: inline-block;
    border-radius: 1% !important;
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  }

  /*============================================SWIPER==========================================*/

  .grid-container-styling {
    background-image: url("./images/grid-image-bg.png");
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 100% 65%;
  }

  .logo-image-sizing {
    width: 150px;
    height: 70px;
  }

  .footer-logo-sizing {
    width: 90px;
    height: 30px;
  }
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
  .fs-20 {
    font-size: 16px;
  }

  .reduceSpaceFooter {
    margin-top: auto;
  }

  .mobile-view-banner-image-css {
    width: 300px;
    height: 300px;
  }

  /* #google_translate_element select:nth-child(1) {
    border: 1px solid #005c5c !important;
    background-color: #005c5c !important;
    color: #ffffff;
    padding: 5px;
    outline: none !important;
  } */

  .btn-next-1 {
    /* background: linear-gradient(89.71deg, #27C79E 3.23%, #935ADF 102.4%); */
    background: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%);
    color: #ffffff !important;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .swiper {
    width: 500px !important;
    height: 220px;
  }

  .button-2-css-table {
    border: 2px solid #02b3b4 !important;
    padding-left: 2px !important;
    color: var(--color-1);
    padding-right: 2px !important;
    font-size: 1em !important;
  }

  .font-size-2em-0-weight {
    font-size: 1.5em !important;
  }

  .font-size-2em {
    font-size: 1.5em !important;
    font-weight: 600;
  }

  .logo-image-sizing {
    width: 80px;
    height: 40px;
  }

  .footer-logo-sizing {
    width: 70px;
    height: 20px;
  }

  .button-1-css {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .mainbanner-table-section-styling table {
    width: 500px;
    /* overflow-x: scroll; */
    font-size: 0.8em;
  }

  .mainbanner-table-section-styling {
    /* width: 500px; */
    overflow-x: scroll;
  }

  .mainbanner-icon-size-1 {
    width: 40px;
    height: 40px;
  }

  /*==============================================DEPOSIT-COLUMN-TABLE-START============================*/

  .deposit-page-table-styling table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .deposit-page-table-styling table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  .deposit-page-table-styling table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
  }

  .deposit-page-table-styling table th,
  .deposit-page-table-styling table td {
    padding: 0.625em;
    text-align: center;
  }

  .deposit-page-table-styling table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
    .deposit-page-table-styling table {
      border: 0;
    }

    .deposit-page-table-styling table caption {
      font-size: 1.3em;
    }

    .deposit-page-table-styling table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .deposit-page-table-styling table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    .deposit-page-table-styling table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    .deposit-page-table-styling table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    .deposit-page-table-styling table td:last-child {
      border-bottom: 0;
    }
  }

  /*==============================================DEPOSIT-COLUMN-TABLE-END==============================*/

  .timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem;
  }

  @media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.46rem;
      position: absolute;
      left: 7.5rem;
      top: 0.3125rem;
    }

    .timeline-steps .timeline-step:not(:first-child):before {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.8125rem;
      position: absolute;
      right: 7.5rem;
      top: 0.3125rem;
    }
  }

  .timeline-steps .timeline-content {
    width: 10rem;
    text-align: center;
  }

  .timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3b82f6;
  }

  .timeline-steps .timeline-content .inner-circle:before {
    content: "";
    background-color: #3b82f6;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: 0.5;
  }

  /*==============================================DEPOSIT-COLUMN-END====================================*/

  /* ===============================BUYCRYPTO-START================================ */
  /* 
 .top-heading {
color: #ffffff !important;
 } */

  .postnewad-page-1-styling .form-control {
    border: 1px solid #ced4da !important;
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .postnewad-page-2-styling .form-control {
    border: 1px solid #ced4da !important;
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .postnewad-page-3-styling .form-control {
    border: 1px solid #ced4da !important;
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .button-select-option-1 {
    background-color: #02b3b4 !important;
    color: #ffffff !important;
  }

  .buycrypto-page-top-section .form-select {
    border: none !important;
  }

  .buycrypto-nav-pills .buycrypto-nav-link.active,
  .buycrypto-nav-pills .show>.buycrypto-nav-link {
    background-color: #02b3b4;
    color: #ffffff !important;
  }

  .buy-crypto-page-card-bg-styling {
    --tw-bg-opacity: 1;
    background-color: var(--background-color-1);

    border-radius: 2em;
  }

  .buycrypto-select-bg-styling {
    background-color: var(--background-color-2);
    color: var(--color-1);
    box-shadow: none;
    border: none;
    border-radius: 0.4em;
  }

  .buycrypto-select-bg-styling:focus {
    background-color: var(--background-color-2);
    color: var(--color-1);
    border-color: none;
    outline: 0;
    box-shadow: none;
    border-radius: 0.4em;
  }

  /* ===============================BUYCRYPTO-END================================ */

  .form-check-input {
    border: 2px solid #14b269 !important;
  }

  /*==================================================SPOTEXCHANGE-ENDS==============================================*/
  /*=============================================MARKETS-STARTS=====================================================*/

  .coin-icon-sizing-data-table {
    width: 30px;
    height: 30px;
  }

  .tablepagination-styling p {
    margin-bottom: 0px !important;
  }

  /* table {
    color: white !important;
  }

  table tr {
    color: white !important;
  }

  table th {
    color: var(--color-1) !important;
  }

  table td {
    color: var(--color-1) !important;
  }

  table thead {
    color: var(--color-1) !important;
  } */

  /* .MuiTablePagination-root {
    background-color: var(--background-color-1) !important;
    color: var(--color-1) !important;
  }

  .MuiSvgIcon-root {
    color: var(--color-1) !important;
  }

  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
    color: var(--color-1) !important;
  }

  .css-1q1u3t4-MuiTableRow-root.Mui-selected {
    background-color: transparent !important;
  } */

  /*=============================================MARKETS-ENDS=======================================================*/

  /*==========================================P2P-ACCORDION-STARTS=================================================*/

  .color-border-styling-1 {
    border-left: 4px solid red;
  }

  .color-border-styling-2 {
    border-left: 4px solid #f5791f;
  }

  .color-border-styling-3 {
    border-left: 4px solid #f0b90b;
  }

  .color-border-styling-4 {
    border-left: 4px solid #ff09ad;
  }

  .color-border-styling-5 {
    border-left: 4px solid #000000;
  }

  .color-border-styling-6 {
    border-left: 4px solid #0582ff;
  }

  .accordion-flush .accordion-item,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    box-shadow: none !important;
    outline: none !important;
    padding-top: 1.6em !important;
    padding-bottom: 1.6em !important;
    font-size: 20px;
    font-weight: 500;
    color: white !important;
    background-color: transparent !important;
  }

  .accordion-flush .accordion-button {
    box-shadow: none !important;
    outline: none !important;
    padding-top: 1.6em !important;
    padding-bottom: 1.6em !important;
    font-size: 20px;
    font-weight: 500;
    color: white !important;
    background-color: transparent !important;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: gray !important;
    box-shadow: none !important;
  }

  /*==========================================P2P-ACCORDION-ENDS===================================================*/

  .mobile-image-size-styling {
    width: 200px;
    height: 400px;
  }

  .button-2-css {
    border: 2px solid #02b3b4 !important;
    padding-left: 2em !important;
    color: var(--color-1);
    padding-right: 1em !important;
  }

  .button-3-css {
    background: #0092928c;
    border: 1px solid #ffffff;
    border-radius: 1em;
    padding-left: 1em !important;
    padding-right: 1em !important;
    color: #ffffff;
    font-size: 18px;
  }

  .button-1-css-register {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 6em !important;
    padding-right: 6em !important;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1.2em;
  }

  .button-1-css:hover {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .button-2-css:hover {
    border: 2px solid #02b3b4 !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .button-3-css:hover {
    background: #0092928c;
    border: 1px solid #ffffff;
    border-radius: 1em;
    padding-left: 1em !important;
    padding-right: 1em !important;
    color: #ffffff;
    font-size: 18px;
  }

  .button-1-css-register:hover {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 6em !important;
    padding-right: 6em !important;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1.2em;
  }

  /*==============================================P2P-START===========================================*/

  /*==============================================P2P-SUB-SECTIONS-START====================================*/

  /*======================POST-NEW-AD-STARTS========================*/

  .bg-gray {
    background-color: hsla(0, 0%, 50%, 0.205);
    border-radius: 20px;
  }

  .btn-next {
    height: 38px;
    background: linear-gradient(93.19deg, #10EDB6 0.77%, #4C62F7 100%);
    border-radius: 10px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 700;
    color: white !important;
  }

  .btn-next:hover {
    height: 38px;
    background: linear-gradient(93.19deg, #08f8bc 0.77%, #1b34d4 100%);
  }

  /* .p2p-main-page-css .form-control {
    border: none !important;
    border-radius: none !important;
    background-color: var(--background-color-2) !important;
  } */

  .css-tab-col .nav-pills .nav-link.active,
  .css-tab-col .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #02b3b4 !important;
  }

  /* ======================ADD-PAYMENT-METHOD-START================== */

  .add-payment-method-heading-styling {
    font-size: 24px;
    padding: 20px 0px;
    font-weight: 550;
  }

  .add-payment-method-text-1 {
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  .add-payment-method-text-2 {
    font-size: 11px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    color: rgb(177, 177, 177);
  }

  .add-payment-method-top-banner .form-control-payment-method {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .add-payment-method-cancel-button {
    background-color: #eaecef;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .add-payment-method-confirm-button {
    background-color: #02b3b4;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .payment-qrcode-optional-image {
    width: 70px;
  }

  .payment-qrcode-optional-button {
    border: 1px solid rgb(177, 177, 177);
    font-size: 13px;
    padding: 2px 30px;
    color: gray;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  }

  /* ==========================ADD-PAYMENT-METHOD-END==================== */

  /* =============================P2P-USER-CENTER-START===================== */
  .p2p-trade-hero-section-two-nav-bg .dropdown-toggle::after {
    display: none;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  .noresponse-image-styling {
    width: 90px;
  }

  .profile-images-letter-styling {
    background-color: #000000 !important;
    border-radius: 1em !important;
    color: #ffffff;
    padding: 3px;
    font-size: 1.3em !important;
  }

  .scrollspy-payments-row-bg-styling {
    /* background: var(--background-color-2); */
  }

  .font-size-style {
    font-size: 17px;
    font-weight: 600;
  }

  .p2puser-center-button {
    background-color: #02b3b4;
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2puser-center-scrollspy-styling-active {
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollsp-border {
    border-bottom: 1px solid #dee2e6;
  }

  .p2puser-center-scrollspy-button {
    border: none;
    box-shadow: none !important;
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    letter-spacing: 0.08em;
    padding: 20px 20px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .p2puser-center-scrollspy-styling .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #000000;
    font-size: 16px !important;
    font-weight: 500;
  }

  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-item.show .nav-link,
  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-link.active {
    color: #495057;
    background-color: var(--background-color-2);
    border-bottom: 3px solid #02b3b4 !important;
  }

  .p2puser-center-scrollspy-styling .dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .p2p-user-scrollspy-button {
    color: var(--color-1);
    background-color: var(--background-color-2);
    font-size: 14px;
  }

  .p2p-user-center-scrollspy-tops-heading {
    /* margin: 0px 0px 16px; */
    min-width: 0px;
    font-weight: 500 !important;
    font-size: 16px !important;
    /* line-height: 24px; */
  }

  .border-line-styling {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    width: 4px;
    height: 14px;
    border-radius: 4px;
    background-color: rgb(95, 37, 159) !important;
  }

  .scrollspy-tops-heading-paragraph {
    /* margin: 0px 0px 8px; */
    min-width: 0px;
    max-width: 100%;
    font-size: 14px !important;
    /* line-height: 24px;

padding-left: 8px;
padding-right: 8px; */
  }

  #scrollspy-section-1-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    /* background: var(--background-color-1); */
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-2-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    background: var(--background-color-1);
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-3-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    background: var(--background-color-1);
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  .p2p-usercenter-progressbar {
    position: relative !important;
    right: 0px;
    z-index: 1;
    height: 9px;
    width: 100px;
    background-color: #02b3b4;
  }

  .p2p-usercenter-progressbar-top {
    height: 9px;
    width: 100px;
  }

  /* =============================P2P-USER-CENTER-END======================= */

  /* ==============================ORDER-P2P-TRADE-START==================== */

  /* ==============================DATE-PICKER-START======================== */
  .datepicker-css-styling {
    border: none !important;
    width: 100px !important;
    outline: none !important;
    font-size: 0.9rem;
  }

  .datepicker-css-styling input {
    border: none !important;
    box-shadow: none !important;
  }

  /* ==============================DATE-PICKER-END========================== */

  .order-p2p-trade-dropdowns.select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    background-color: transparent;
  }

  .order-p2p-reset-content-title {
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    color: #02b3b4;
  }

  .order-p2p-trade-download-icon {
    font-size: 35px;
    background-color: rgb(214, 214, 214) !important;
    cursor: pointer;
  }

  /* ==============================ORDER-P2P-TRADE-END====================== */

  /*==============================================P2P-SUB-SECTIONS-END======================================*/

  .hero-top-section {
    background: rgba(0, 0, 0, 0.87);
    background-image: url("./images/glow-effects-p2p.png"),
      url("./images/grid-image-bg.png");
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 100% 100%;
  }

  .hero-top-section-text-2 {
    font-size: 16px;
    width: 50%;
    text-align: center;
    margin: auto;
  }

  .nav-tabs-buy-sell-section {
    border: 1px solid #e6e8ea;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell-section .nav-tabs .nav-link:hover {
    border-color: #e9ecef00 #e9ecef00 #dee2e600;
    isolation: isolate;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .green-tab-active {
    background-color: #02b3b4 !important;
    color: var(--color-1) !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .red-tab-active {
    background-color: rgb(203, 13, 13) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #02b3b4;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs-buy-sell .nav-tabs {
    border: 0px solid #0582ff !important;
  }

  .nav-tabs-buy-sell .nav-link {
    display: block;
    padding: 0.3rem 0rem !important;
    color: var(--color-1) !important;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-pills-buy-sell .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
  }

  .nav-pills-buy-sell .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: #02b3b4 !important;
    border-bottom: 2px solid #02b3b4;
  }

  .nav-pills-buy-sell .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--color-1) !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .enter-amount-section .btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: #02b3b4;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section .btn:hover {
    color: #02b3b4;
  }

  .enter-amount-text-section {
    border-right: 1px solid #eaecef;
  }

  .enter-amount-text-1 {
    font-size: 15px;
    color: #aeb4bc;
  }

  .enter-amount-section .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section {
    background-color: #f5f5f5;
    border: 1px solid #02b3b4;
    padding: 0.175rem 0rem;
    border-radius: 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #cccdcf !important;
    font-size: 14px;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  input:focus {
    box-shadow: none !important;
  }

  .enter-amount-heading {
    font-size: 11px;
    color: #76808f;
  }

  .enter-amount-text-2 {
    font-size: 11px;
    color: #76808f;
    margin-left: -10px;
  }

  .f-dropdown {
    --max-scroll: 3;
    position: relative;
    z-index: 10;
  }

  .f-dropdown select {
    display: none;
  }

  .f-dropdown>span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #f0bb0b50 !important;
    background: #f5f5f5;
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .f-dropdown>span>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .f-dropdown>span img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown>span:before,
  .f-dropdown>span:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 1px;
    top: 50%;
    right: 12px;
    background: #000;
    transition: all 0.3s ease;
  }

  .f-dropdown>span:before {
    margin-right: 4px;
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown>span:after {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);
  }

  .f-dropdown ul li {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .f-dropdown ul li a {
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }

  .f-dropdown ul li a img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown ul li a:hover {
    color: #000 !important;
  }

  .f-dropdown ul li.active a {
    color: #000;
    background: gray;
  }

  .f-dropdown ul li.active a:before,
  .f-dropdown ul li.active a:after {
    --scale: 0.6;
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    position: absolute;
    right: 12px;
    top: 50%;
    opacity: 0;
    background: #fff;
    transition: all 0.2s ease;
  }

  .f-dropdown ul li.active a:before {
    transform: rotate(45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:after {
    transform: rotate(-45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:hover:before,
  .f-dropdown ul li.active a:hover:after {
    --scale: 0.9;
    opacity: 1;
  }

  .f-dropdown ul li:first-child a {
    border-radius: 3px 3px 0 0;
  }

  .f-dropdown ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  .f-dropdown.disabled {
    opacity: 0.7;
  }

  .f-dropdown.disabled>span {
    cursor: not-allowed;
  }

  .f-dropdown.filled>span {
    color: #000;
  }

  .f-dropdown.open {
    z-index: 15;
  }

  .f-dropdown.open>span {
    border-color: #aaa;
  }

  .f-dropdown.open>span:before,
  .f-dropdown.open>span:after {
    background: #000;
  }

  .f-dropdown.open>span:before {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown.open>span:after {
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown.open ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 12px);
    transition: opacity 0.3s ease, visibility 0.3s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  }

  /* --------------------------- */
  .f-group {
    max-width: 250px;
    margin: 0 auto;
    text-align: left;
  }

  .f-group select {
    width: 100%;
  }

  .f-control {
    font-size: 14px;
    line-height: normal;
    color: #000;
    display: inline-block;
    background-color: #f5f5f5;
    border: #ccc 1px solid;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  /* ==================================P2P-TRADE-TABLE-START======================== */

  .p2p-trade-table {
    border: none !important;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .p2p-trade-table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  .p2p-trade-table .p2p-trade-table-tr {
    background-color: var(--background-color-1);

    /* border-bottom: 1px solid #e1e5e9; */
    padding: 0.35em;
    border-radius: 3px;
  }

  /* .p2p-trade-table .p2p-trade-table-thead .p2p-trade-table-tr:first-child {
    border-bottom: 1px solid #e1e5e9;
  } */
  .p2p-trade-table .p2p-trade-table-th,
  .p2p-trade-table .p2p-trade-table-td {
    padding: 1.5em;
    color: var(--color-1) !important;
    font-size: 14px;
  }

  .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
    font-size: 14px;
  }

  .p2p-trade-table .p2p-trade-table-th {
    font-size: 11px;
    color: var(--color-1) !important;
    font-weight: 400;
  }

  .p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice {
    background-color: #02b3b4;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice-sell {
    background-color: rgba(241, 55, 55);
    color: #ffffff !important;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  /* 
tbody tr:nth-child(even) {
	background-color: #eee;
}

tbody tr:nth-child(odd) {
	background-color: #fff;
} */

  @media screen and (max-width: 600px) {
    .p2p-trade-table {
      border: 0;
    }

    .p2p-trade-table caption {
      font-size: 1.3em;
    }

    .p2p-trade-table .p2p-trade-table-thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      position: absolute;
      width: 1px;
      padding: 0;
    }

    .p2p-trade-table .p2p-trade-table-tr {
      border-bottom: 3px solid #e1e5e9;
      display: block;
      margin-bottom: 0.625em;
    }

    .p2p-trade-table .p2p-trade-table-th,
    .p2p-trade-table .p2p-trade-table-td {
      padding: 0.625em;
    }

    .p2p-trade-table .p2p-trade-table-td {
      border-bottom: 1px solid #e1e5e9;
      display: block;
      font-size: 0.8em;
      text-align: right;
      color: var(--color-1) !important;
    }

    .p2p-trade-table .p2p-trade-table-td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-1) !important;
    }

    .p2p-trade-table .p2p-trade-table-td:last-child {
      border-bottom: 0;
    }

    .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
      font-size: 0.8em;
    }
  }

  .table-lth-bg {
    color: #02b3b4;
    background-color: #02b4b42f !important;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 10px;
  }

  .table-trade-bg {
    color: #02b3b4;
    background-color: #02b4b42f;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 10px;
  }

  .table-data-1 {
    font-size: 14px;
    color: var(--color-1);
  }

  .table-data-2 {
    font-size: 12px;
    color: var(--color-1) !important;
  }

  .table-data-3 {
    font-size: 20px;
    color: var(--color-1) !important;
  }

  .table-data-4 {
    font-size: 11px;
    color: var(--color-1) !important;
  }

  .table-data-5 {
    font-size: 12px;
    color: var(--color-1) !important;
  }

  .table-data-6 {
    margin: 0px 4px 0px 0px;
    color: var(--color-1) !important;
    font-size: 11px;
  }

  .table-data-7 {
    color: #02b3b4;
    background-color: #02b4b42f;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 11px;
  }

  .table-data-1 img {
    width: 15px;
    height: 15px;
    padding-bottom: 2px;
    border-radius: 50%;
    margin-right: 4px;
  }

  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:before,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:after,
  .p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:before {
    bottom: 0.5em;
  }

  /* ==================================P2P-TRADE-TABLE-END======================== */
  .p2p-second-nav-content-title {
    font-size: 13px;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  /*==============================================P2P-END=============================================*/

  .mainbanner-card-3-styling {
    background: #01c6c7 0% 0% no-repeat padding-box;
    border: 2px solid #01c6c7;
    border-radius: 12px;
    opacity: 0.85;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 1em;
    /* padding: 4em 4em 4em 4em; */
  }

  .mainbanner-card-2-styling {
    background: #01c6c7 0% 0% no-repeat padding-box;
    border: 2px solid #01c6c7;
    border-radius: 12px;
    opacity: 0.85;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 1em;
    /* padding: 7em 7em 4em 9em; */
  }

  .mainbanner-apps-image {
    width: 150px;
    height: 50px;
  }

  .mainbanner-icon-size-2 {
    width: 40px;
    height: 40px;
  }

  .mainbanner-table-section-styling table> :not(caption)>*>* {
    border: none !important;
  }

  .gird-table-bg {
    background-image: url("./images/grid-image-opacity-bg.png");
    background-repeat: no-repeat;
    /* background-position: cover; */
    background-size: 100% 100%;
  }

  .gird-table-bg table {
    background-color: transparent !important;
  }

  .gird-table-bg tr {
    background-color: transparent !important;
  }

  .card-bg-styling-mainbanner {
    background-color: #02b4b42d;
    /* background-image: url("./images/grid-image-opacity-2-bg.png");
    background-repeat: no-repeat; */
    /* background-position: cover; */
    background-size: 100% 100%;
    border-radius: 20px;
  }

  .mainbanner-table-section-styling table td,
  .mainbanner-table-section-styling table th {
    padding-bottom: 2em !important;
  }

  .mainbanner-table-section-styling table th {
    color: #02b3b4;
  }

  .coin-icon-sizing {
    width: 80px;
    height: 40px;
  }

  /*============================================SWIPER==========================================*/

  /* .swiper {
    width: 1000px !important;
    height: 220px;
  }
  .swiper-slide {
    width: 31% !important;
    margin-left: 21px;
  }
  .swiper-pagination-bullet-active {
    background: #02b3b4 !important;
  }

  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 30px)
    ) !important;
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 5px)
    ) !important;
    display: inline-block;
    border-radius: 1% !important;
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  } */

  /*============================================SWIPER==========================================*/

  .grid-container-styling {
    background-image: url("./images/grid-image-bg.png");
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 100% 40%;
  }

  .mainbanner-gif-size {
    width: 40%;
    height: 40%;
  }

  .logo-image-sizing {
    width: 150px;
    height: 70px;
  }

  .footer-logo-sizing {
    width: 70px;
    height: 30px;
  }
}

/* ==================================P2P-TRADE-TABLE-START======================== */

.p2p-trade-table {
  border: none !important;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.p2p-trade-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.p2p-trade-table .p2p-trade-table-tr {
  background-color: #fff;
  border-bottom: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}

.p2p-trade-table .p2p-trade-table-thead .p2p-trade-table-tr:first-child {
  border-bottom: 1px solid #e1e5e9;
}

.p2p-trade-table .p2p-trade-table-th,
.p2p-trade-table .p2p-trade-table-td {
  padding-top: 1em;
  padding-bottom: 1rem;
  color: #9da9b9;
  font-size: 14px;
}

.p2p-trade-table .p2p-trade-table-td:nth-child(4) {
  font-size: 14px;
}

.p2p-trade-table .p2p-trade-table-th {
  font-size: 11px;
  color: #76808f;
  font-weight: 400;
}

.p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice {
  background-color: #02b3b4;
  color: #fff !important;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
}

.p2p-trade-table .p2p-trade-table-tbody .p2p-trade-table-tr .p2p-trade-table-td .btn-invoice-sell {
  background-color: rgba(241, 55, 55);
  color: #fff;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
}

/* table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  background-color: var(--background-color-2);
  padding-top: 1em;
  padding-bottom: 1rem;
} */

.table-head-sticky {
  overflow: auto;
  height: auto;
}

.table-head-sticky thead th {
  position: sticky;
  top: 0;
}

/* 
tbody tr:nth-child(even) {
	background-color: #eee;
}

tbody tr:nth-child(odd) {
	background-color: #fff;
} */

@media screen and (max-width: 600px) {
  .p2p-trade-table {
    border: 0;
  }

  .p2p-trade-table caption {
    font-size: 1.3em;
  }

  .p2p-trade-table .p2p-trade-table-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  .p2p-trade-table .p2p-trade-table-tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }

  .p2p-trade-table .p2p-trade-table-th,
  .p2p-trade-table .p2p-trade-table-td {
    padding: 0.625em;
  }

  .p2p-trade-table .p2p-trade-table-td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }

  .p2p-trade-table .p2p-trade-table-td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }

  .p2p-trade-table .p2p-trade-table-td:last-child {
    border-bottom: 0;
  }

  .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
    font-size: 0.8em;
  }
}

/* .table-lth-bg {
  color: rgb(201, 148, 0);
  background-color: rgb(254, 246, 216);
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 10px;
} */
.table-trade-bg {
  color: rgb(3, 166, 109);
  background-color: rgb(230, 255, 241);
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 10px;
}

.table-data-1 {
  font-size: 14px;
  color: var(--color-1);
}

.table-data-2 {
  font-size: 12px;
  color: rgb(30, 35, 41);
}

.table-data-3 {
  font-size: 20px;
  color: rgb(11, 14, 17);
}

.table-data-4 {
  font-size: 11px;
  color: rgb(11, 14, 17);
}

.table-data-5 {
  font-size: 12px;
  color: rgb(33, 40, 51);
}

.table-data-6 {
  margin: 0px 4px 0px 0px;
  color: rgb(112, 122, 138);
  font-size: 11px;
}

/* .table-data-7 {
  color: rgb(201, 148, 0);
  background-color: rgb(254, 246, 216);
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
} */
.table-data-1 img {
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
  border-radius: 50%;
  margin-right: 4px;
}

.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:before {
  bottom: 0.5em;
}

/* ==================================P2P-TRADE-TABLE-END======================== */
/* ==================================P2P-ORDER-TABLE-START====================== */

.a-tag-link-styling {
  color: royalblue;
  text-decoration: underline;
  box-shadow: none;
}

.a-tag-link-styling:hover {
  color: royalblue;
  text-decoration: none;
}

.order-p2p-trade-table-coin-icon {
  width: 20px;
  height: 20px;
}

.order-p2p-trade-table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-wrap: break-word;
}

.order-p2p-trade-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.order-p2p-trade-table .order-p2p-trade-tr {
  background-color: transparent;
  padding: 0.35em;
  overflow-wrap: break-word;
}

.order-p2p-trade-table .order-p2p-trade-th,
.order-p2p-trade-table .order-p2p-trade-td {
  text-align: center;
}

.order-p2p-trade-table .order-p2p-trade-td {
  font-size: 12px;
  padding: 2em 2em 2em 0.5em;
  border-bottom: 1px solid rgb(214, 214, 214);
  /* background-color: #eeeeee; */
}

.order-number-copy-data-css {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-p2p-trade-table .order-p2p-trade-th {
  font-size: 11px;
  color: #252525;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 2em;
  /* border-bottom: 1px solid grey; */
  background-color: #02b3b4 !important;
}

.order-p2p-trade-table thead {
  background-color: #02b3b4 !important;
}

.order-p2p-trade-table thead th:first-child {
  border-top-left-radius: 2em;
}

.order-p2p-trade-table thead th:last-child {
  border-top-right-radius: 2em;
}

@media screen and (max-width: 600px) {
  .order-p2p-trade-table {
    border: 0;
  }

  .order-p2p-trade-table caption {
    font-size: 1.3em;
  }

  .order-p2p-trade-table .order-p2p-trade-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .order-p2p-trade-table .order-p2p-trade-tr {
    padding: 2px;
  }

  .order-p2p-trade-table .order-p2p-trade-td {
    padding: 1em 1em 1em 0.1em;
  }

  .order-p2p-trade-table .order-p2p-trade-tr {
    border-bottom: 3px solid #ddd;
    display: block;
    /* margin-bottom: .625em; */
  }

  .order-p2p-trade-table .order-p2p-trade-td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .order-p2p-trade-table .order-p2p-trade-td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .order-p2p-trade-table .order-p2p-trade-td:last-child {
    border-bottom: 0;
  }
}

/* ==================================P2P-ORDER-TABLE-END======================== */

/* ==================================P2P-USER-CENTER-BLOCKED-USER-TABLE-START=================================== */

.p2p-user-center-blocked-user-table table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table caption {
  /* font-size: 1.5em; */
  margin: 0.5em 0 0.75em;
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table tr {
  /* border: 1px solid #ddd; */
  /* padding: 5px !important; */
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table th,
.p2p-user-center-blocked-user-table table td {
  /* text-align: center; */
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table th {
  padding: 13px !important;
  background-color: #f8f8f8;
  font-size: 12px !important;
  color: rgb(112, 122, 138) !important;
  font-weight: normal !important;
}

.p2p-user-center-blocked-user-table table td {
  padding: 22px 22px 22px 0 !important;
}

.p2p-user-center-blocked-user-table table th {
  /* font-size: .85em; */
  /* letter-spacing: .1em; */
  /* text-transform: uppercase; */
  align-items: center !important;
}

@media screen and (max-width: 600px) {
  .p2p-user-center-blocked-user-table table {
    border: 0;
  }

  .p2p-user-center-blocked-user-table table caption {
    font-size: 1.3em;
  }

  .p2p-user-center-blocked-user-table table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .p2p-user-center-blocked-user-table table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .p2p-user-center-blocked-user-table table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right !important;
  }

  .counterparty-text-styling {
    text-align: right !important;
    justify-content: right !important;
  }

  .p2p-user-center-blocked-user-table table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    /* font-weight: bold; */
    text-transform: uppercase;
  }

  .p2p-user-center-blocked-user-table table td:last-child {
    border-bottom: 0;
  }
}

/* ==================================P2P-USER-CENTER-BLOCKED-USER-TABLE-END=================================== */
#google_translate_element select:nth-child(2) {
  display: none;
}

#google_translate_element span {
  display: none;
}

#google_translate_element .skiptranslate:nth-child(2) {
  display: none;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.goog-te-gadget {
  font-size: 0px !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe {
  display: none;
}

#google_translate_element select:nth-child(1) {
  /* height: 40px; */
  width: 105px;
  background: transparent;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/public/assets/User.png);
  background-position: right center;
  background-repeat: no-repeat; */
  /* background-color: #2e72c0 !important; */
  font-size: 1.1rem;
  outline: none;
  color: #DADADA;
  border: none;
  /* padding-right: 15px !important; */
  /* border-radius: 4px; */
}

#google_translate_element option {
  background: #535151 !important;
  font-size: 16px !important;
  color: white;
  font-weight: bold;
}

/* Home translat  */
#google_translate_element_home select:nth-child(2) {
  display: none;
}

#google_translate_element_home span {
  display: none;
}

#google_translate_element_home .skiptranslate:nth-child(2) {
  display: none;
}

#google_translate_element_home select:nth-child(1) {
  width: 105px;
  background: transparent;
  font-size: 1.1rem;
  outline: none;
  color: black;
  border: none;
}

#google_translate_element_home option {
  background: white !important;
  font-size: 16px !important;
  color: black;
}

/* .goog-te-gadget span {
color: red !important;
} */

/* #google_translate_element select option:hover {
  background-color: transparent !important;
  color: #7eb6ff !important;
}

#google_translate_element select option:active {
  background-color: transparent !important;
  color: #7eb6ff !important;
}

.goog-te-combo option:hover {
  background-color: transparent !important;
  color: #7eb6ff !important;
} */


/*====================================================UNITIC-EXCHANGE-CSS-END=====================================*/
table> :not(:first-child) {
  border-top: none !important;
}

.bg-dark {
  background-color: #000000;
}

.dashboard-top-banner-section .tab-content {
  padding-top: 50px;
}

.dashboard-navbar-styling-css #navbar-img {
  display: none;
}

#navbar-img-dashboard {
  width: 150px;
  height: 50px;
  margin-bottom: 3em;
}

.theme-light {
  --color-primary: #0060df;
  --color-secondary: #fbfbfe;
  --color-accent: #fd6f53;
  --font-color: #000000;
}

.theme-dark {
  --color-primary: #17ed90;
  --color-secondary: #2a2c2d;
  --color-accent: #12cdea;
  --font-color: #ffffff;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML slider */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #02b3b4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 0px;
  bottom: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0px 15px #2020203d;
  background: white url("https://i.ibb.co/FxzBYR9/night.png");
  background-repeat: no-repeat;
  background-position: center;
}

input:checked+.slider {
  background-color: #02b3b4;
}

input:focus+.slider {
  box-shadow: 0 0 1px #02b3b4;
}

input:checked+.slider:before {
  height: 25px;
  width: 25px;
  right: 0px;
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
  background: white url("https://i.ibb.co/7JfqXxB/sunny.png");
  background-repeat: no-repeat;
  background-position: center;
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 30px;
}

/*======================MEDIUM-LARGE-SCREEN-RESPONSIVE=======================*/

@media (min-width: 1366px) and (max-width: 1766px) {
  .button-1-css {
    background: #02b3b4 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #ffffff !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    font-size: 14px !important;
  }

  .button-3-css {
    background: #0092928c;
    border: 1px solid #ffffff;
    border-radius: 0.5em !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    color: #ffffff;
    font-size: 14px !important;
  }

  .very-small-text {
    font-size: 14px !important;
  }

  .navbar-nav .nav-item {
    margin-left: 0.8em;
  }

  .text-overflow-ellipsis {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #navbar-img-dashboard {
    width: 130px !important;
    height: 40px !important;
    margin-bottom: 3em;
  }

  #navbar-img {
    width: 130px;
    height: 40px;
  }

  .dashboard-navbar-styling-css .navbar {
    margin-left: 180px;
    font-size: 1em;
  }

  .navbar-nav {
    font-size: 0.9em;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    line-height: 1.5;
    font-size: 9px !important;
    font-weight: 600;
    letter-spacing: 0.26px;
  }

  .trading-page-order-table-details-section tbody td {
    font-size: 9px !important;
    color: white;
    background-color: transparent;
  }

  .dashboard-spot-trade-history-section td {
    font-weight: 400;
    align-items: center;
    font-size: 9px !important;
    background-color: transparent;
    border: 0px;
    padding: 4px 4px;
    letter-spacing: 0.28px;
    vertical-align: middle;
    border-bottom: 1px solid #dbdde6;
  }

  #content {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll;
  }

  #content::-webkit-scrollbar {
    display: none;
  }

  .bars-loading-loader {
    margin-top: 20% !important;
  }

  .p2p-user-center-blocked-user-table-sticky thead {
    display: table;
    width: 100%;
  }

  .p2p-user-center-blocked-user-table-sticky .text-muted:nth-child(2) {
    width: 20% !important;
  }

  .p2p-user-center-blocked-user-table-sticky tbody {
    display: block;
    max-height: 380px;
    overflow-y: scroll;
  }

  .p2p-user-center-blocked-user-table-sticky th,
  .p2p-user-center-blocked-user-table-sticky td {
    width: auto;
    padding: 5px;
    word-break: break-all;
  }

  .p2p-user-center-blocked-user-table-sticky tr {
    display: table;
    width: 100%;
    box-sizing: border-box;
  }
}

.dashboard-navbar-styling-css .nav-link {
  padding: 3px !important;
}

.nav-link {
  padding: 0px;
}

.sroll-effects-stylings-css {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  background: rgb(18, 18, 18) !important;
  color: rgb(243, 243, 243);
  border: 0px !important;
}

.sroll-effects-stylings-css-fake {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  /* background: rgb(18, 18, 18) !important; */
  color: rgb(243, 243, 243);
  border: 0px !important;
}

@media only screen and (max-width: 991px) {
  .sroll-effects-stylings-css-fake {
    display: none !important;
  }

  .sroll-effects-stylings-css {
    display: flex !important;
    justify-content: flex-start;
    align-items: start;
    /* overflow: hidden; */
    flex-wrap: wrap;
    gap: 15px;
    background: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
    border: 0px !important;

    /* display: flex !important;
    justify-content: flex-start;
    align-items: start;
    background: rgb(18, 18, 18) !important;
    color: rgb(243, 243, 243);
    border: 0px !important; */
  }
}

.table-over-flow-market-trends {
  overflow: scroll !important;
}

/* ===========================TABLE-USING-UL-LI=============================== */

.table-using-ul-li-css .list ul:nth-child(odd) {
  background-color: rgb(250, 250, 250) !important;
}

.table-using-ul-li-css .list ul:nth-child(even) {
  background-color: rgb(250, 250, 250) !important;
}

/* big */
@media screen and (min-width: 600px) {
  .table-using-ul-li-css .list {
    display: table;
    margin: 1em;
  }

  .table-using-ul-li-css .list ul {
    display: table-row;
    border-right: solid 1px #ccc !important;
  }

  .table-using-ul-li-css .list ul:first-child li {
    background-color: rgb(250, 250, 250);
    color: #000000 !important;
    padding: 4px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    letter-spacing: 0.28px;
  }

  .table-using-ul-li-css .list ul>li {
    display: table-cell;
    padding: 0.5em 1em;
    border-bottom: solid 1px #eee;
    padding: 14px 8px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.32px;
  }
}

/* small */
@media screen and (max-width: 599px) {
  .table-using-ul-li-css .list ul {
    border: solid 1px #ccc;
    display: block;
    list-style: none;
    margin: 1em;
    padding: 0.5em 1em;
  }

  .table-using-ul-li-css .list ul:first-child {
    display: none;
  }

  .table-using-ul-li-css .list ul>li {
    display: block;
    padding: 0.25em 0;
  }

  .table-using-ul-li-css .list ul:nth-child(odd)>li+li {
    border-top: solid 1px #ccc;
  }

  .table-using-ul-li-css .list ul:nth-child(even)>li+li {
    border-top: solid 1px #eee;
  }

  .table-using-ul-li-css .list ul>li:before {
    color: #000;
    content: attr(data-label);
    display: inline-block;
    font-size: 75%;
    font-weight: bold;
    text-transform: capitalize;
    vertical-align: top;
    width: 50%;
  }
}

/* tiny */
@media screen and (max-width: 349px) {
  .table-using-ul-li-css .list ul>li:before {
    display: block;
  }
}

.icon-size-forul-li-img {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

/* ------------Insta Trade CSS Start--------  */
.tab-trade {
  width: 400px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #535151;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.btn-false {
  width: 170px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 36px;
  background: transparent;
  font-weight: bold;
  border: none;
  color: white;
}

.btn-trade {
  width: 170px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 36px;
  background: #10EDB6;
  font-weight: bold;
  border: none;
}

.btn-trade-sell {
  width: 170px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 36px;
  color: white;
  /* background: #10EDB6; */
  font-weight: bold;
  border: none;
}

.buy-box {
  width: 457px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 26px;
  background: #535151;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.sell-box {
  width: 457px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 26px;
  background: #535151;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.sell-cpy {
  flex-shrink: 0;
  border-radius: 31px;
  background: #dc3545;
  color: white;
  font-size: 14px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  /* font-weight: 600; */
  border: none;
}

.sell-cpy:hover {
  background: #bb2d3b;
}

.sell-cpy:disabled {
  background: #bb2d3b;
  cursor: not-allowed;
}

.borderRadiusIssue {
  border-radius: 69px !important;
}

.trade-input {
  border-radius: 69px;
  background: #7B7B7B !important;
  color: white !important;
  outline: none !important;
  border: none !important;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  height: 3rem !important;
}

.trade-btn-input {
  border-radius: 69px;
  background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%) !important;
  color: white !important;
  outline: none !important;
  border: none !important;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  height: 3rem !important;
  font-weight: bold !important;
}

.trade-input::after {
  color: white !important;
}


.custom2-select-wrapper {
  position: relative;
}

.custom2-select-arrow {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid white;
  /* Change the color to your desired color */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
}


.trade-input::placeholder {
  color: white
}

.btn-buy {
  width: auto;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #10EDB6;
  color: #000;
  font-size: 18px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  font-weight: 600;
  border: none;
}

.btn-buy:hover {
  background: #0cc999;
}

.btn-sell {
  width: auto;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dc3545;
  color: white;
  font-size: 18px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
  /* font-weight: 600; */
  border: none;
}

.btn-sell:hover {
  background: #bb2d3b;
}

.btn-sell:disabled {
  background: #bb2d3b;
  cursor: not-allowed;
}

.text-deposit {
  background: linear-gradient(92.43deg, #c3ccff 0.35%, #60ffe4 98.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .tab-trade {
    width: 300px;
  }

  .btn-trade {
    width: 125px;
  }

  .btn-false {
    width: 125px;
  }

  .buy-box {
    width: 90%;
  }

  .sell-box {
    width: 90%;
  }
}

/* ------------Insta Trade CSS End--------  */

/* Signal  */
.paddingBox {
  padding-left: 8px;
  padding-right: 8px;
}

@media only screen and (min-width:900px) and (max-width:1010px) {
  .paddingBox {
    padding-left: 4px;
    padding-right: 4px;
  }
}

/* Signal End  */

/* ---------------Futures Trade Start-------- */

.first-panel-futures {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.first-div-futures {
  width: 80%;
}

.second-div-futures {
  width: 20%;
}

.setFontRoboto {
  font-family: 'Roboto', sans-serif !important;
}

.futures-trade-top-section {
  font-family: 'Roboto', sans-serif !important;
  /* min-height: 30px; */
  /* padding-top: 5px;
  padding-bottom: 5px; */
  border-bottom: 1px solid rgba(200, 200, 200, 0.15);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* overflow: hidden; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.futures-trade-top-section::-webkit-scrollbar {
  display: none !important;
}

.futures-first-part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.futures-first-part p {
  font-family: 'Roboto', sans-serif !important;
  color: #ffff;
  font-weight: 400;
  font-size: 0.875rem;
  white-space: nowrap;
}

.color-transition {
  transition: color 0.3s ease;
  /* Adjust transition properties as needed */
}

.futures-danger {
  color: rgb(255, 75, 94) !important;
}

.futures-success {
  color: #10EDB6 !important;
}

.futures-warning {
  color: #FF9D4D !important;
}

.handleFuturesSearchBar input {
  background: #535151;
  width: 100%;
  height: 35px;
  padding: 5px;
  border-radius: 5px;
  color: #C8C8C8;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  font-size: 0.75rem;
}

.handleFuturesSearchBar input::placeholder {
  color: #C8C8C8;
}

.handleFuturesOption {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 5px;
}

.handleFuturesOption p {
  color: #C8C8C8;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  font-size: 0.875rem;
  cursor: pointer;
}

.handleFuturesOptionSecond {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow-x: auto;
  background-image: url(/public/assets/rightArrow.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto;
  white-space: nowrap;
}

.handleFuturesOptionSecond p {
  color: #C8C8C8;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  font-size: 0.75rem;
  white-space: nowrap;
}

.handleFuturesOptionSecond::-webkit-scrollbar {
  display: none !important;
}

.handleFuturesOptionThird {
  margin-top: 8px;
}

.rate-part {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.rate-part p:nth-child(1) {
  color: #C8C8C8;
  font-size: 0.625rem;
}

.rate-part p:nth-child(2) {
  color: #FFFFFF;
  font-size: 0.75rem;
}

.option-part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-bottom: 0px !important;
}

.option-part div h6 {
  color: #FFF;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s ease-in-out color;
}

.futures-secondary {
  color: #C8C8C8 !important;
}

.marquee-container {
  overflow: hidden;
}

.marquee-content {
  white-space: nowrap;
  animation: move 10s infinite linear;
}

.marquee-content:hover {
  animation-play-state: paused;
}

@keyframes move {
  0% {
    transform: translate(100%);
  }

  100% {
    transform: translate(-100%);
  }
}

.futures-trading-table-total-height {
  /* max-height: 45vh !important; */
  border-bottom: 1px solid #f5f8fb;
  /* overflow-y: auto; */
}

.futures-trading-table-left-recent-trade-height {
  /* height: 370px; */
  overflow-y: auto;
}

.futures-trading-table-left-recent-trade-height::-webkit-scrollbar {
  display: none !important;
}

.handleFuturesHeight {
  height: 100vh;
}

.heightTradeForm {
  min-height: fit-content;
  height: 76%;
}

.top-button-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  overflow: hidden;
}

.top-button-spot div:nth-child(1) button {
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  background: rgb(20, 178, 105);
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.top-button-spot div:nth-child(1) button:hover {
  background: #0dc899;
}

.top-button-spot div:nth-child(2) button:hover {
  background: #c4113e;
}

.top-button-spot div:nth-child(2) button {
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  background: #FF0141;
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.percentage-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  overflow: hidden;
}

.percentage-button button {
  width: 20%;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #535151;
  background: #535151;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: rgba(200, 200, 200, 1);
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.775rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.percentage-button button:hover {
  background: rgb(103, 98, 98);
}

.top-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  overflow: hidden;
}

.top-button button {
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #000;
  background: #535151;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: rgba(200, 200, 200, 1);
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.top-button button:hover {
  background: rgb(103, 98, 98);
}

.menu-option-part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #535151;
  /* position: relative; */
}

.text-active {
  border-bottom: 1px solid #fff;
  color: #fff !important;
}

.menu-option-part p {
  color: #C8C8C8;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.menu-option-part-order {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  transition: border-bottom 0.3s ease-in-out;
  border-bottom: 1px solid #535151;
  /* position: relative; */
}

.text-active-order {
  border-bottom: 1px solid #fff;
  color: #fff !important;
}

.menu-option-part-order p {
  transition: color 0.3s ease-in-out;
  color: #C8C8C8;
  font-size: 0.87rem;
  font-weight: 1000;
  margin-bottom: 0px;
  letter-spacing: 0.045em;
  /* font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */
  cursor: pointer;
}

.menu-option-part-order-p {
  transition: color 0.3s ease-in-out !important;
  color: #C8C8C8;
  font-size: 0.87rem !important;
  font-weight: 1000 !important;
  margin-bottom: 0px !important;
  letter-spacing: 0.045em !important;
  /* font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */
  cursor: pointer !important;
}

.dropdownOfTrade {
  height: fit-content !important;
  flex-shrink: 0 !important;
  background-color: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  min-width: 95px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: none !important;
  outline: none !important;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
}

.dropdownOfTrade p {
  line-height: 1.7rem !important;
}

.dropdownOfTrade p:hover {
  color: white;
}

.inputOfTrade {
  height: 35px !important;
  flex-shrink: 0 !important;
  background-color: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
}

.inputOfTrade:disabled {
  background-color: #989494bd !important;
  cursor: not-allowed;
}

.sideBtn {
  background-color: #535151 !important;
  background: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
  transition: 0.2s ease-in-out background;
}

.selectOfTrade button {
  background-color: #535151 !important;
  background: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
  transition: 0.2s ease-in-out background;
}

.selectOfTrade div {
  background-color: #535151 !important;
  background: #535151 !important;
  color: #FFFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  min-width: 45px !important;
}

.selectOfTrade div a {
  color: #FFFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: center;
  padding: 0.25rem 0.45rem;

}

.selectOfTradeTpSl button {
  background-color: #FF0141 !important;
  background: #FF0141 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
  transition: 0.2s ease-in-out background;
}

.selectOfTradeTpSl button:hover {
  background: #c4113e !important;
}

.selectOfTradeTpSl button:disabled {
  background: #ef5363 !important;
  cursor: not-allowed !important;
}

.selectOfTradeTpSl div {
  background-color: #535151 !important;
  background: #535151 !important;
  color: #FFFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  min-width: 45px !important;
}

.selectOfTradeTpSl div a {
  color: #FFFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: center;
  padding: 0.25rem 0.45rem;

}

.selectOfTradeForm button {
  background-color: transparent !important;
  background: transparent !important;
  color: #C8C8C8 !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
  transition: 0.2s ease-in-out background;
}

.selectOfTradeForm div {
  background-color: #535151 !important;
  background: #535151 !important;
  color: #FFFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.selectOfTradeForm div a {
  color: #FFFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.handleTextBS p {
  color: #C8C8C8;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.handleTextBS p span {
  color: #FFF;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lastPartButton div div {
  width: 47%;
}

.lastPartButton div div:nth-child(1) button {
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  background: rgb(20, 178, 105);
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.lastPartButton div div:nth-child(1) button:hover {
  background: #0dc899;
}

.lastPartButton div div:nth-child(2) button:hover {
  background: #c4113e;
}

.lastPartButton div div:nth-child(2) button {
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  background: #FF0141;
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.handleCostMaxText p {
  color: #C8C8C8;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
  /* white-space: nowrap; */
}

.handleCSSMarginRatio h6 {
  color: #FFF;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.handleCSSMarginRatio button {
  width: 90%;
  border-radius: 4px;
  border: 1px solid #000;
  background: #535151;
  font-family: 'Roboto', sans-serif !important;
  color: #FFF;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.handleCSSMarginRatio button:hover {
  background: #706e6e;
}

.handleCSSMarginRatio div p {
  color: #FFF;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}

.handleCoinInfoTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto', sans-serif !important;
  /* min-height: 30px; */
  /* padding-top: 5px;
  padding-bottom: 5px; */
  border-bottom: 1px solid rgba(200, 200, 200, 0.15);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* overflow: hidden; */
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: auto;
  gap: 10px;
}

.handleCoinInfoTop::-webkit-scrollbar {
  display: none !important;
}

.handleCoinInfoTop div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.938rem;
}

.handleCoinInfoTop div p {
  color: #c7c7c7;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.2s ease-in-out color;
}

.handleCoinInfoLeft p:nth-child(1) {
  color: #c7c7c7;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
}

.handleCoinInfoLeft p:nth-child(2) {
  color: #ffffff;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
}

.handleFundingHistoryLeft p:nth-child(1) {
  color: #c7c7c7;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.75rem;
  font-weight: 400;
}

.handleFundingHistoryLeft p:nth-child(2) {
  color: #C8C8C8;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.75rem;
  font-weight: 400;
  leading-trim: both;
  text-edge: cap;
  line-height: normal;
}

.handleCoinInfoRight {
  position: relative;
  top: -30px;
}

.handleCoinInfoRight p:nth-child(1) {
  color: #ffffff;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
}

.handleCoinInfoRight p:nth-child(2) {
  color: #c7c7c7;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
}

.handleTradingRulesRight {
  color: #c7c7c7;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
}

.genText {
  color: #ffffff;
  font-family: 'Roboto', sans-serif !important;
  /* font-size: 0.875rem; */
  font-weight: 400;
}

.handleModifyFirstProps div p {
  color: #FFF;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.handleModifyPropsColor p {
  color: #FFF;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.handlePropsColor div p:nth-child(1) {
  color: #C8C8C8;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.handlePropsColor div p:nth-child(2) {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.handlePropsColorV2 div:nth-child(1) p {
  color: #C8C8C8;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.handlePropsColorV2 div:nth-child(2) p {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: end;
}

.btn-confirm {
  border-radius: 5px;
  background: rgb(20, 178, 105);
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 30%;
  height: 40px;
  flex-shrink: 0;
  border: 0;
  transition: 0.3s ease-in-out background;
}

.btn-cancel {
  border-radius: 5px;
  background: #535151;
  color: #FFF;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 30%;
  height: 40px;
  flex-shrink: 0;
  border: 0;
  transition: 0.3s ease-in-out background;
}

.btn-confirm:hover {
  background: #08c495;
}

.btn-confirm:disabled {
  background: rgba(20, 178, 104, 0.641) !important;
  cursor: not-allowed !important;
}

.btn-cancel:hover {
  background: rgb(103, 98, 98);
}

.btn-cancel-v2 {
  border-radius: 5px;
  color: #FFF;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 30%;
  height: 40px;
  flex-shrink: 0;
  border: 0;
  transition: 0.3s ease-in-out background;
  background: #FF0141;
}

.btn-cancel-v2:hover {
  background: #c4113e;
}

.inputOfModify {
  height: 35px !important;
  flex-shrink: 0 !important;
  background-color: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
}

.lastBtn {
  background-color: #535151 !important;
  background: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
  transition: 0.2s ease-in-out background;
}

.handleMultifyButton {
  border-radius: 6px;
  border: 1px solid #C8C8C8;
  border-radius: 8px;
  background: #2E2C2C;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.handleMultifyButton button {
  border-radius: 6px;
  background: #535151;
  display: inline-flex;
  padding: 6px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #C8C8C8;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  box-shadow: none;
  transition: transform .2s;
}

.handleMultifyButton button:hover {
  transform: scale(1.2);
}

.dialog-top-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  overflow: hidden;
}

.dialog-top-button button {
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px;
  background: #535151;
  box-shadow: 0;
  color: rgba(200, 200, 200, 1);
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.dialog-top-button button:hover {
  background: rgb(103, 98, 98);
}

.dialog-bottom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  flex-wrap: wrap;
}

.dialog-bottom-button button {
  min-width: 30%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px;
  box-shadow: 0px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.2s ease-in-out background;
}

.inputOfTP {
  height: 35px !important;
  flex-shrink: 0 !important;
  background-color: #535151 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: none !important;
  outline: none !important;
}

.textTP {
  color: #C8C8C8 !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.TPTopProps {
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px; */
}

.TPTopProps div p:nth-child(1) {
  color: #C8C8C8;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3px;
  /* line-height: normal; */
}

.TPTopProps div p:nth-child(2) {
  color: #FFF;
  font-family: Roboto;
  font-size: 0.775rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}

@media only screen and (max-width: 560px) {
  .handleMultifyButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
    padding-left: 7px;
  }
}

@media only screen and (max-width: 520px) {
  .handleFuturesOptionSecond {
    padding-bottom: 10px;
  }

  .handleModifyFirstProps div p {
    font-size: 0.775rem;
  }
}

@media only screen and (max-width: 991px) {
  .first-panel-futures {
    flex-direction: column;
  }

  .first-div-futures {
    width: 100%;
  }

  .second-div-futures {
    width: 100%;
  }

  .handleFuturesHeight {
    height: auto;
  }

  .futures-trading-table-total-height {
    /* max-height: 40vh !important; */
    border-bottom: 1px solid #f5f8fb;
    /* overflow-y: auto; */
  }

  .futures-trading-table-left-recent-trade-height {
    /* max-height: 20vh !important; */
    overflow-y: auto;
  }

  .heightTradeForm {
    height: auto;
  }
}

.fundTransPopUp {
  background-color: #535151;
  color: white;
  border: none;
  font-size: 14px;
  padding: 3px;
  border-radius: 5px;
  outline: none;
}

@media only screen and (min-width:900px) {
  .fundTransPopUp {
    font-size: 16px;
  }
}

.confirm-btn-transfer {
  background-color: #21CA50;
  width: 100%;
  color: #000;
  border-radius: 50px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  border: none;
}

.confirm-btn-transfer:hover {
  background-color: #20a546;
}

.confirm-btn-transfer:disabled {
  background-color: #21ca519b;
}

.invalidValue {
  border: 1px solid #FF0141 !important;
}

/* Futures Trade End  */

/* Typographi wise CSS Start  */
.tabFontSize {
  font-size: 18px !important;
}

@media only screen and (min-width:1200px) {
  .headingSize {
    font-size: 55px;
  }
}

@media only screen and (min-width:900px) and (max-width:1199px) {
  .headingSize {
    font-size: 45px;
  }
}

@media only screen and (max-width:899px) {
  .headingSize {
    font-size: 40px;
  }

}

@media only screen and (max-width:599px) {
  .tabFontSize {
    font-size: 12px !important;
  }
}

/* Typographi wise CSS End  */

/* Blog Start */
.cardFonts {
  font-family: "Lexend", sans-serif;
}

.blogSingleCard {
  width: 75%;
}

.singleHeadTitle {
  font-family: "Lexend", sans-serif;
  /* color: #454798; */
  margin-bottom: 16px;
  padding-top: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px
}

@media only screen and (max-width: 991px) {
  .blogSingleCard {
    width: 100%;
  }

  .singleHeadTitle {
    font-family: "Lexend", sans-serif;
    /* color: #454798; */
    margin-bottom: 16px;
    padding-top: 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px
  }
}

@media only screen and (max-width: 767px) {
  .mx-auto-custom-sm {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Blog End */

/* market extra  start */
.bg-market {
  background-image: url(/public/assets/marketHmBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: linear-gradient(180deg, #FFFFFF 0%, #EDEFEF 100%) !important;
  background-blend-mode: overlay;
}

.designBox {
  padding: 30px 30px;
  border: 30px solid transparent;
  border-image: url(/public/Find_Your_Next_Trading_BG.png) 30 round;
  border-radius: 30px;
}

@media only screen and (max-width:650px) {
  .designBox {
    border: 3px solid rgba(56, 186, 86, 1);
    border-radius: 40px;
    padding: 30px 30px;
    border-image: none;
  }
}

/* market end  */

/* Transfer Modal  start */
.btn-transfer {
  padding: 0px 10px;
  background: linear-gradient(92.43deg, #4C62F7 0.35%, #10EDB6 98.55%);
  border-radius: 8px;
  color: white;
  border: none;
  transition: 0.3s background ease-in-out;
}

.btn-transfer:hover {
  background: linear-gradient(92.43deg, #3548c4 0.35%, #09c193 98.55%);
}

.btn-transfer:disabled {
  background: linear-gradient(92.43deg, #3548c4 0.35%, #09c193 98.55%);
}

/* Transfer Modal  end */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.banner-container {
    height: 91vh;
    overflow: hidden;
    background-image: url(/public/assets/banner/candle.webp);
    background-size: calc(200vh - 250px);
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bannerHeading {
    font-family: "Raleway", sans-serif;
    font-size: 45px;
    color: #000;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.bannerSubHeading {
    color: #287d00;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.banner-main {
    height: calc(80vh - 200px);
    overflow: hidden;
    background-image: url(/public/assets/banner/banner.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btns-handle-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.paddingSpace {
    padding-top: 15px;
    padding-bottom: 15px;
}

@media only screen and (max-width:1535px) {
    .bannerHeading {
        font-size: 40px;
    }

    .bannerSubHeading {
        font-size: 16px;
    }

}

@media only screen and (min-width:1100px) and (max-width:1193px) {
    .banner-container {
        background-size: calc(200vh - 320px);
    }

}
@media only screen and (min-width:900px) and (max-width:1100px) {
    .banner-container {
        background-size: calc(200vh - 450px);
    }

}

@media only screen and (max-width:899px) {
    .bannerHeading {
        font-size: 35px;
    }

    .banner-container {
        height: 84vh;
        background-image: none;
    }

    .banner-main {
        background-image: url(/public/assets/banner/banner-sm.webp) !important;
        height: calc(80vh - 180px);
    }
}

@media only screen and (max-width:650px) {
    .bannerHeading {
        font-size: 30px;
    }

    .bannerSubHeading {
        font-size: 14px;
    }

    .banner-main {
        height: calc(80vh - 170px);
    }
}

@media only screen and (max-width:500px) {
    .banner-container {
        margin-top: -10px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .paddingSpace {
        padding-top: 0px;
        padding-bottom: 15px;
    }

    .banner-main {
        height: calc(80vh - 230px);
    }
}

@media only screen and (max-width:450px) {
    .banner-main {
        height: calc(80vh - 260px);
    }

    .btns-handle-banner img {
        width: 150px;
    }
}

@media only screen and (max-width:400px) {
    .banner-main {
        height: calc(80vh - 300px);
    }
}

@media only screen and (max-width:350px) {
    .banner-main {
        height: calc(80vh - 340px);
    }

    .bannerHeading {
        font-size: 28px;
    }

    .bannerSubHeading {
        font-size: 12px;
    }
}
.headingEndTitle {
    font-size: 20px;
    font-weight: bold;
}

.code-container {
    /* background: rgb(25, 23, 28); */
    overflow: hidden;
    padding-bottom: 10px;
    /* Adding some bottom padding to ensure space for scrollbar */
}

/* Update code block styles */
.code-block-v2 {
    white-space: pre-wrap !important;
    overflow-x: auto;
    padding: 10px;
    word-wrap: break-word !important;
    max-width: 100%;
}

.code-block {
    /* background-color: rgb(25, 23, 28) !important; */
    border-radius: 5px;
    overflow-x: auto;
    /* Enable horizontal scrolling for long lines */
    white-space: pre-wrap;
    /* Preserve whitespace and line breaks */
    font-family: 'Courier New', monospace;
    /* Use a monospaced font for code */
    line-height: 1.5;
    padding: 10px;
    border: none;
}

.code-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(25, 23, 28) !important;
    border: none;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow-x: auto;
}

.content-code {
    background-color: rgb(73, 69, 69);
    padding: 3px 5px;
    border-radius: 3px;
    color: #e6db74;
}

.code-header button {
    padding: 0px;
    background-color: transparent;
    color: #c2c1c1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.code-header button:hover {
    color: #fff;
}

@media only screen and (max-width:600px) {
    .getGap {
        gap: 10px
    }
}
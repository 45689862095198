.status-div {
    background: #535151;
    border-radius: 16px;
    width: 100%;
    height: 151px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 15px; */
    padding-left: 25px;
    padding-right: 25px;
}

.scrolling-issue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 24px; */
    width: 100%;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.scrolling-issue::-webkit-scrollbar {
    display: none;
    /* Set width of the scrollbar */
}

.first-head {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    color: #C8C8C8;


}

.second-tail {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    /* identical to box height */
    margin-bottom: 0;
    text-align: center;
    color: #FFFFFF;

}

.title-market {

    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    /* identical to box height */


    color: #FFFFFF;
}

.subtitle-market {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    color: #FFFFFF;
}

.box-portfolio {
    width: 460px !important;
    height: 208px !important;
    background: #535151 !important;
    border-radius: 17px !important;
    overflow: hidden !important;
    /* letter-spacing: 0.12rem !important; */
}

.portfolio-title-header {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    /* identical to box height */


    color: #FFFFFF;
}

.portfolio-subtitle-header {
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height */


    color: #FFFFFF;
}


.withdraw-flat-box {
    width: 40%;
    min-height: 100px;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    background: #535151;
}

.width-text {
    width: 55%;
}

.box-flat-text {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 16px;
    background: #535151;
}

.box-flat-text p {
    color: #FFF;
    font-size: 18px;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 500;
    padding: 8px;
}

.withdraw-box {
    width: 50%;
    min-height: 100px;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    background: #535151;
}

.payment-withdraw {
    border-radius: 10px;
    background: #7B7B7B !important;
    color: white !important;
    outline: none;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    height: 2.5rem;
}

.payment-withdraw::placeholder {
    color: white;
}

.custom2-select-wrapper {
    position: relative;
}

.custom2-select-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid white;
    /* Change the color to your desired color */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    pointer-events: none;
}


.custom-select {
    width: 100%;
}

.custom-select .custom-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #7B7B7B !important;
    color: white !important;
    outline: none;
    border: none;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    padding-top: 5px;
    padding-bottom: 5px;
}

.custom-select .custom-option-image {
    width: 30px;
    /* Adjust the size of the image */
    margin-right: 10px;
    margin-left: 10px;
    /* Adjust the spacing between the image and label */
}

.custom-select__control {
    background-color: #7B7B7B;
    color: white;
    border-color: black;
}

.custom-select__single-value {
    color: white;
}

.custom-select__indicator {
    color: white;
}

.custom-select__indicator svg {
    fill: white;
}

.custom-select__menu {
    background-color: #7B7B7B;
    color: white;
}

.custom-select__menu-list {
    padding: 0;
}

.custom-select__option--is-selected {
    background-color: #7B7B7B;
}


@media only screen and (max-width: 1000px) {
    .scrolling-issue {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        padding-left: 15px;
        padding-right: 15px;
        width: auto;
    }

    .scrolling-issue div {
        width: auto;
        padding-left: 10x;
        padding-right: 10x;
    }
}


@media only screen and (max-width: 650px) {
    .box-portfolio {
        width: 100% !important;
        height: auto !important;
    }

    .withdraw-box {
        width: 100%;
    }

    .controlFont {
        font-size: 16px;
    }

    .withdraw-flat-box {
        width: 100%;
    }

    .width-text {
        width: 100%;
    }
}

@media only screen and (min-width: 371px) and (max-width: 420px) {
    .portfolio-title-header {
        font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 33px;
        /* identical to box height */


        color: #FFFFFF;
    }

    .portfolio-subtitle-header {
        font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        /* identical to box height */


        color: #FFFFFF;
    }
}

@media only screen and (max-width: 370px) {
    .portfolio-title-header {
        font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 33px;
        /* identical to box height */


        color: #FFFFFF;
    }

    .portfolio-subtitle-header {
        font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        /* identical to box height */

        color: #FFFFFF;
    }
}

@media only screen and (min-width: 1200px) {
    .box-portfolio {
        width: 500px !important;
    }
}
.searchGlassFutures {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
    border-radius: 0 30px 30px 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: none;
    padding-left: 0px;
    height: 35px;
}

.text-gray {
    /* hover color  */
    color: rgb(197, 197, 197);
}
.searchBtnFutures {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
    border-radius: 30px 0 0 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: none;
    color: white;
    height: 35px;
    padding-left: 12px;
    /* font-size: 14px; */
}

.searchDropdownFutures {
    line-height: 1.5rem;
    position: absolute;
    top: 120%;
    left: 15%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    background-color: transparent;
    display: none !important;
    color: rgb(243, 243, 243);
    font-style: normal;
    z-index: 999;
    padding: 10px;
    /* font-size: 14px; */
    font-weight: bold;
}

.onFocModeFutures {
    min-height: 30px;
    width: 70%;
    background-color: rgb(66, 65, 65);
    display: block !important;
}

.hoverModeFutures:hover {
    color: #7eb6ff !important;
    font-style: normal;
}
.box-referral-status {
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 118px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-referral-status div h4 {
    color: white;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    /* font-weight: bold; */
    margin-bottom: 0;
}

.box-referral-status div p small {
    color: #C8C8C8;
    font-size: small;
    font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
    font-weight: 600;
}
.custom-active-tab li .nav-link.active{
    background-color: transparent !important;
  }
@media only screen and (max-width: 440px) {
    .box-referral-status div h4 {
        color: white;
        font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
        font-weight: bold;
        font-size: 20px;
    }
}
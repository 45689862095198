:root .light-new {
  --color-1: #000000;
  --border-color-1: #bbbbbb;
  --background-color-1: rgb(250, 250, 250);
  --background-color-2: #ffffff;
  --background-dashboard: #ebebeb;
}

:root .dark-new {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #14161a;
  --background-color-2: #303236;
  --background-dashboard: #14161a;
}

:root .blue-whale {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #0c495c;
  --background-color-2: #29677a;
}

:root .blue-dark {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #36424d;
  --background-color-2: #45505a;
}

:root .brown {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #373737;
  --background-color-2: #686868;
}

:root .dark-black {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #1d1d1d;
  --background-color-2: #111010;
}



/* .css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
} */

.payment-qrcode-optional-button {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}


/* .css-1mxz8qt-MuiPaper-root {
  background-color: var(--background-color-1) !important;
}

.css-11w94w9-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-10dfkli-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-177gid-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-1azl6jz-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-jtlhu6-MuiTablePagination-root {
  color: var(--color-1) !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  color: var(--color-1) !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--color-1) !important;
}

.css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--color-1) !important;
} */

.referral-page-top-banner-section .nav-tabs .nav-link {
  color: var(--color-1) !important;
  margin-left: 2em;
}

.bg-card {
  background-color: var(--background-color-1) !important;
}

.bg-card-inner {
  background-color: var(--background-color-2) !important;
  border-radius: 20px;
}

.bg-Dark-New {
  color: var(--color-1) !important;
}

.enter-amount-section {
  background-color: var(--background-color-1) !important;
}

.f-control {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.p2p-trade-table .p2p-trade-table-tr {
  background-color: var(--background-color-1) !important;
}

.p2p-trade-table .p2p-trade-table-th {
  color: var(--color-1) !important;
}

.faq-section {
  margin-top: 40px;
  background-color: var(--background-color-1) !important;
  padding: 5vh 0 5vh;
}

.p2p-trade-hero-section-two-nav-bg {
  background-color: var(--background-color-1) !important;
}

.p2p-user-center-blocked-user-table table th {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

#scrollspy-section-3-para {
  /* background-color: var(--background-color-2) !important; */
  border: 1px solid var(--border-color-1);
}

#scrollspy-section-2-para {
  /* background-color: var(--background-color-2) !important; */
  border: 1px solid var(--border-color-1);
}

#scrollspy-section-1-para {
  /* background-color: var(--background-color-2) !important; */
  border: 1px solid var(--border-color-1);
}

.select-dropdown-btn {
  /* color: var(--color-1) !important; */
}

.password-text-33 {
  font-weight: 500;
  font-size: 15px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
}

.password-text-44 {
  font-weight: 800;
  font-size: 18px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
}

.password-text-55 {
  font-weight: 800;
  font-size: 22px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
}

.password-text-66 {
  font-weight: 600;
  font-size: 17px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
}

.password-text-77 {
  font-weight: 500;
  font-size: 16px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
}

/* .btn-light:hover {
  background-color: #02b3b4;
} */

/* 
.card {
  border: 1px solid var(--border-color-1);
} */

.device-management-table td {
  font-weight: 400;
  font-size: 15px;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei";
}

.nav-tabs-buy-sell .nav-link {
  color: var(--color-1) !important;
}

.nav-pills-buy-sell .nav-pills .nav-link {
  color: var(--color-1) !important;
}

.p2p-second-nav-content-title {
  color: var(--color-1) !important;
}

.p2p-second-nav-icons {
  color: #02b3b4 !important;
}

.dropdown-item {
  color: var(--color-1);
}

.dropdown-item:hover {
  background-color: var(--background-color-2) !important;
  color: var(--color-1) !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #02b3b4 !important;
  border-color: #02b3b4 !important;
}

.page-link {
  color: #000 !important;
  box-shadow: none !important;
}

.select-dropdown-btn-buysell {
  background-color: rgba(243, 243, 243, 0.04) !important;
  color: rgb(243, 243, 243) !important;
  border: 0px !important;
}

.place-order-buy-sell-tabs .nav-pills {
  background-color: rgba(243, 243, 243, 0.04) !important;
  border: 0px !important;
}

.add-payment-method-cancel-button {
  background-color: var(--background-color-1) !important;
}

.postAd-top-section .form-control {
  background-color: var(--background-color-2) !important;
  color: var(--color-1) !important;
  border: 1px solid var(--border-color-1);
}

.pan-input-text {
  color: var(--color-1) !important;
}

.dropbtn1 {
  background-color: transparent !important;
}

.dropdown-content1 {
  /* background-color: var(--background-color-1) !important; */
}

.paired-trade-dropdown-table th {
  /* color: var(--color-1) !important; */
}

.place-order-section-top .form-check-input {
  background-color: #b5fff1 !important;
}

.banking-border-bottom {
  border-bottom: 1px solid var(--border-color-1);
}

.dark-new .inner-third-section {
  background-color: #14161a !important;
}

.dark-new .top-banner-eight-section .card {
  background-color: #14161a !important;
}

.dark-new .benefits-text-1 {
  color: #fff;
}

/* .phone-number-verification-change-top-banner .form-control {
  color: var(--color-1) !important;
} */

/* .PhoneInputInput {
  color: var(--color-1) !important;
  background-color: var(--background-color-1) !important;
} */

/* .form-control {
  color: var(--color-1) !important;
} */

.dark-new .height-container .btn-light {
  background-color: #14161a !important;
}

.dark-new .btn-light.active,
.dark-new .btn-light:active {
  /* color: rgb(255, 255, 255) !important;
  background-color: #02b3b4 !important; */
  border-color: #f9fafb;
  box-shadow: none !important;
}

.css-xc836r-control {
  border-color: var(--border-color-1) !important;
}

.dark-new .css-1dimb5e-singleValue {
  color: var(--color-1) !important;
}

.dark-new .css-1nmdiq5-menu {
  background-color: #c7c7c791 !important;
}

.dark-new .status_dropdown .dropdown-item:hover {
  color: #fff !important;
}

.dark-new .status_dropdown .dropdown-item:hover {
  color: #000 !important;
}

.login-tabs-color {
  background-color: var(--background-color-2);
}

.nav-register-button {
  color: var(--color-1) !important;
  border: 1px solid var(--border-color-1) !important;
}

/* input {
  color: var(--color-1) !important;
} */


fieldset {
  border: 1px solid var(--border-color-1) !important;
  color: var(--color-1) !important;
}

/* label {
  color: var(--color-1) !important;
} */

/* .phone-number-verification-change-top-banner .form-control {
  color: var(--color-1) !important;
} */


/* table {
  background-color: var(--background-color-1);
  color: var(--color-1) !important;
}

table tr {
  background-color: var(--background-color-1);
  color: var(--color-1) !important;
}

table th {
  color: var(--color-1) !important;
}

table td {
  color: var(--color-1) !important;
}

table thead {
  color: var(--color-1) !important;
}

.MuiTablePagination-root {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.MuiSvgIcon-root {
  color: var(--color-1) !important;
}

.form-control {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
} */

.dropdown-content {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.dark-new .rmsc.dark {
  --rmsc-main: #4285f4;
  --rmsc-hover: #0e0c0a;
  --rmsc-selected: #1d1915;
  --rmsc-border: #333333;
  --rmsc-gray: #555555;
  --rmsc-bg: #000000;
  color: #fff;
}

.rmsc .dropdown-container {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
  border: 1px solid var(--border-color-1) !important;
}

.datepicker-css-styling {
  color: var(--color-1) !important;
}

.modal-content {
  background-color: #2d2c2c !important;
  color: white !important;
}


/* .form-select {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
} */